import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { ArrowRight, CheckCircle2, User, Mail, Phone, MapPin, Twitter, Link as LinkIcon, DollarSign, Clock } from 'lucide-react';

interface ContractorApplicationFormProps {
  position: string;
}

export function ContractorApplicationForm({ position }: ContractorApplicationFormProps) {
  const [state, handleSubmit] = useForm("meoavvpz");

  if (state.succeeded) {
    return (
      <div className="flex flex-col items-center justify-center p-12 text-center">
        <div className="relative">
          <div className="absolute inset-0 bg-gradient-to-r from-green-500 to-emerald-500 blur-2xl opacity-20 animate-pulse" />
          <CheckCircle2 className="relative w-20 h-20 text-green-500 mb-6 animate-in fade-in slide-in-from-bottom-4 duration-500" />
        </div>
        <h3 className="text-3xl font-bold text-white mb-4 animate-in fade-in slide-in-from-bottom-4 duration-500 delay-150">
          Application Submitted!
        </h3>
        <p className="text-gray-400 text-lg max-w-md animate-in fade-in slide-in-from-bottom-4 duration-500 delay-300">
          Thank you for your interest in the {position} position. We'll review your application and get back to you soon.
        </p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-8 bg-gray-900/40 backdrop-blur-sm p-8 rounded-2xl border border-white/20">
      <input type="hidden" name="position" value={position} />
      
      {/* Personal Information Section */}
      <div className="space-y-6">
        <h3 className="text-xl font-semibold text-white border-b border-white/20 pb-2">Personal Information</h3>
        <div className="grid md:grid-cols-2 gap-6">
          <div className="space-y-2">
            <label htmlFor="name" className="block text-sm font-medium text-gray-200">
              Full Name
            </label>
            <div className="relative group">
              <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-lg blur opacity-0 group-hover:opacity-30 group-focus-within:opacity-30 transition-opacity duration-300" />
              <div className="relative flex items-center">
                <User className="absolute left-3 w-5 h-5 text-indigo-300 group-focus-within:text-indigo-200 transition-colors duration-300" />
                <input
                  id="name"
                  type="text"
                  name="name"
                  required
                  className="w-full pl-10 pr-4 py-2.5 bg-gray-800/90 border border-white/20 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:border-transparent transition-all duration-300"
                  placeholder="John Doe"
                />
              </div>
            </div>
            <ValidationError prefix="Name" field="name" errors={state.errors} className="text-red-400 text-sm mt-1" />
          </div>

          <div className="space-y-2">
            <label htmlFor="email" className="block text-sm font-medium text-gray-200">
              Email Address
            </label>
            <div className="relative group">
              <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-lg blur opacity-0 group-hover:opacity-30 group-focus-within:opacity-30 transition-opacity duration-300" />
              <div className="relative flex items-center">
                <Mail className="absolute left-3 w-5 h-5 text-indigo-300 group-focus-within:text-indigo-200 transition-colors duration-300" />
                <input
                  id="email"
                  type="email"
                  name="email"
                  required
                  className="w-full pl-10 pr-4 py-2.5 bg-gray-800/90 border border-white/20 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:border-transparent transition-all duration-300"
                  placeholder="john@example.com"
                />
              </div>
            </div>
            <ValidationError prefix="Email" field="email" errors={state.errors} className="text-red-400 text-sm mt-1" />
          </div>

          <div className="space-y-2">
            <label htmlFor="phone" className="block text-sm font-medium text-gray-200">
              Phone Number
            </label>
            <div className="relative group">
              <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-lg blur opacity-0 group-hover:opacity-30 group-focus-within:opacity-30 transition-opacity duration-300" />
              <div className="relative flex items-center">
                <Phone className="absolute left-3 w-5 h-5 text-indigo-300 group-focus-within:text-indigo-200 transition-colors duration-300" />
                <input
                  id="phone"
                  type="tel"
                  name="phone"
                  required
                  className="w-full pl-10 pr-4 py-2.5 bg-gray-800/90 border border-white/20 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:border-transparent transition-all duration-300"
                  placeholder="+1 (555) 123-4567"
                />
              </div>
            </div>
            <ValidationError prefix="Phone" field="phone" errors={state.errors} className="text-red-400 text-sm mt-1" />
          </div>

          <div className="space-y-2">
            <label htmlFor="location" className="block text-sm font-medium text-gray-200">
              Location
            </label>
            <div className="relative group">
              <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-lg blur opacity-0 group-hover:opacity-30 group-focus-within:opacity-30 transition-opacity duration-300" />
              <div className="relative flex items-center">
                <MapPin className="absolute left-3 w-5 h-5 text-indigo-300 group-focus-within:text-indigo-200 transition-colors duration-300" />
                <input
                  id="location"
                  type="text"
                  name="location"
                  required
                  className="w-full pl-10 pr-4 py-2.5 bg-gray-800/90 border border-white/20 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:border-transparent transition-all duration-300"
                  placeholder="City, Country"
                />
              </div>
            </div>
            <ValidationError prefix="Location" field="location" errors={state.errors} className="text-red-400 text-sm mt-1" />
          </div>
        </div>
      </div>

      {/* Professional Information Section */}
      <div className="space-y-6">
        <h3 className="text-xl font-semibold text-white border-b border-white/20 pb-2">Professional Information</h3>
        <div className="space-y-6">
          <div className="space-y-2">
            <label htmlFor="xHandle" className="block text-sm font-medium text-gray-200">
              X (Twitter) Handle
            </label>
            <div className="relative group">
              <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-lg blur opacity-0 group-hover:opacity-30 group-focus-within:opacity-30 transition-opacity duration-300" />
              <div className="relative flex items-center">
                <Twitter className="absolute left-3 w-5 h-5 text-indigo-300 group-focus-within:text-indigo-200 transition-colors duration-300" />
                <input
                  id="xHandle"
                  type="text"
                  name="xHandle"
                  className="w-full pl-10 pr-4 py-2.5 bg-gray-800/90 border border-white/20 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:border-transparent transition-all duration-300"
                  placeholder="@username"
                />
              </div>
            </div>
            <ValidationError prefix="X Handle" field="xHandle" errors={state.errors} className="text-red-400 text-sm mt-1" />
          </div>

          <div className="space-y-2">
            <label htmlFor="portfolio" className="block text-sm font-medium text-gray-200">
              Portfolio Link
            </label>
            <div className="relative group">
              <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-lg blur opacity-0 group-hover:opacity-30 group-focus-within:opacity-30 transition-opacity duration-300" />
              <div className="relative flex items-center">
                <LinkIcon className="absolute left-3 w-5 h-5 text-indigo-300 group-focus-within:text-indigo-200 transition-colors duration-300" />
                <input
                  id="portfolio"
                  type="url"
                  name="portfolio"
                  required
                  className="w-full pl-10 pr-4 py-2.5 bg-gray-800/90 border border-white/20 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:border-transparent transition-all duration-300"
                  placeholder="Link to your portfolio, website, or Google Drive with your best work"
                />
              </div>
            </div>
            <p className="text-sm text-gray-500 mt-1">Share a link to your portfolio, website, or a Google Drive folder containing your best work</p>
            <ValidationError prefix="Portfolio" field="portfolio" errors={state.errors} className="text-red-400 text-sm mt-1" />
          </div>

          <div className="grid md:grid-cols-2 gap-6">
            <div className="space-y-2">
              <label htmlFor="rate" className="block text-sm font-medium text-gray-200">
                Project Rate (USD)
              </label>
              <div className="relative group">
                <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-lg blur opacity-0 group-hover:opacity-30 group-focus-within:opacity-30 transition-opacity duration-300" />
                <div className="relative flex items-center">
                  <DollarSign className="absolute left-3 w-5 h-5 text-indigo-300 group-focus-within:text-indigo-200 transition-colors duration-300" />
                  <input
                    id="rate"
                    type="number"
                    name="rate"
                    min="0"
                    required
                    className="w-full pl-10 pr-4 py-2.5 bg-gray-800/90 border border-white/20 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:border-transparent transition-all duration-300"
                    placeholder="500"
                  />
                </div>
              </div>
              <p className="text-sm text-gray-500">Your typical rate per project/deliverable</p>
              <ValidationError prefix="Rate" field="rate" errors={state.errors} className="text-red-400 text-sm mt-1" />
            </div>

            <div className="space-y-2">
              <label htmlFor="turnaround" className="block text-sm font-medium text-gray-200">
                Average Turnaround Time
              </label>
              <div className="relative group">
                <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-lg blur opacity-0 group-hover:opacity-30 group-focus-within:opacity-30 transition-opacity duration-300" />
                <div className="relative flex items-center">
                  <Clock className="absolute left-3 w-5 h-5 text-indigo-300 group-focus-within:text-indigo-200 transition-colors duration-300" />
                  <input
                    id="turnaround"
                    type="text"
                    name="turnaround"
                    required
                    className="w-full pl-10 pr-4 py-2.5 bg-gray-800/90 border border-white/20 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:border-transparent transition-all duration-300"
                    placeholder="2-3 days"
                  />
                </div>
              </div>
              <p className="text-sm text-gray-500">Typical time to complete a project</p>
              <ValidationError prefix="Turnaround" field="turnaround" errors={state.errors} className="text-red-400 text-sm mt-1" />
            </div>
          </div>
        </div>
      </div>

      {/* Additional Information Section */}
      <div className="space-y-6">
        <h3 className="text-xl font-semibold text-white border-b border-white/20 pb-2">Additional Information</h3>
        <div className="space-y-2">
          <label htmlFor="message" className="block text-sm font-medium text-gray-200">
            Why are you interested in this position?
          </label>
          <div className="relative group">
            <textarea
              id="message"
              name="message"
              required
              rows={4}
              className="relative w-full p-4 bg-gray-800/90 border border-white/20 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:border-transparent transition-all duration-300 z-10"
              placeholder="Tell us about your experience and why you'd be a great fit..."
            />
            <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-lg blur opacity-0 group-hover:opacity-30 group-focus-within:opacity-30 transition-opacity duration-300 -z-10" />
          </div>
          <ValidationError prefix="Message" field="message" errors={state.errors} className="text-red-400 text-sm mt-1" />
        </div>
      </div>

      <button
        type="submit"
        disabled={state.submitting}
        className="group relative w-full"
      >
        <div className="absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-xl blur-xl opacity-30 group-hover:opacity-50 transition-opacity duration-500" />
        <div className="relative flex items-center justify-center gap-2 px-8 py-4 bg-gradient-to-r from-indigo-500/30 to-purple-500/30 rounded-xl border border-white/20 font-medium text-white hover:border-white/30 transition-all duration-500">
          <span className="text-lg">{state.submitting ? 'Submitting...' : 'Submit Application'}</span>
          <div className="w-8 h-8 rounded-full bg-white/20 flex items-center justify-center group-hover:bg-white/30 transition-colors duration-500">
            <ArrowRight className="w-5 h-5 group-hover:translate-x-0.5 transition-transform duration-300" />
          </div>
        </div>
      </button>
    </form>
  );
} 