import { Link } from 'react-router-dom';

export default function ApplicationSuccessPage() {
  return (
    <div className="min-h-screen bg-black text-white">
      <div className="relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-purple-900/30 via-indigo-900/20 to-black z-0">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_right,rgba(67,56,202,0.3),transparent_50%)]" />
        </div>
        <div className="absolute inset-0">
          {[...Array(50)].map((_, i) => (
            <div
              key={i}
              className="star"
              style={{
                width: Math.random() * 2 + 1 + 'px',
                height: Math.random() * 2 + 1 + 'px',
                left: Math.random() * 100 + '%',
                top: Math.random() * 100 + '%',
                animationDelay: Math.random() * 3 + 's'
              }}
            />
          ))}
        </div>
        <div className="relative z-10 container mx-auto px-6 py-24 text-center">
          <h1 className="text-5xl md:text-7xl font-bold mb-8">
            Application{' '}
            <span className="relative inline-block">
              <span className="absolute inset-0 bg-gradient-to-r from-indigo-400 to-blue-400 blur-2xl opacity-30" />
              <span className="relative gradient-text">Submitted!</span>
            </span>
          </h1>
          <p className="text-xl text-gray-300 mb-12">
            Thank you for applying to our Elite Creator Program. We'll review your application and get back to you soon.
          </p>
          <Link
            to="/"
            className="inline-flex items-center justify-center bg-gradient-to-r from-indigo-500 to-blue-500 hover:from-indigo-600 hover:to-blue-600 px-8 py-4 rounded-xl text-lg font-medium transition-all shadow-lg shadow-indigo-500/25 hover:shadow-indigo-500/40"
          >
            Return Home
          </Link>
        </div>
      </div>
    </div>
  );
}