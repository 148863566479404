import { 
  Crown, Diamond, Rocket, Target, 
  BarChart as ChartBar, ArrowRight, 
  Zap, Star, Shield, Trophy, 
  Sparkles, DollarSign, Briefcase,
  Headphones, ShoppingBag, Users,
  Building2, Scale, Plane,
  GraduationCap, Wallet, HandshakeIcon,
  BarChart3, ScrollText, Gift,
  CheckCircle2, TrendingUp, Award,
  ChevronDown, Youtube, Loader2, X,
  Search, FileEdit, Clock, CalendarDays,
  Calendar, Heart, Laptop, Gamepad,
  Film, HelpCircle, Globe, PieChart,
  LineChart, Percent, ArrowUpRight,
  Lightbulb, Settings, Share2, CoinsIcon,
  LayoutGrid, Boxes, Network, Shuffle,
  Waypoints, Gauge, BrainCircuit,
  Play, MonitorPlay, Clapperboard,
  Camera, Code, Wand2
} from 'lucide-react';
import { Link } from 'react-router-dom';
import { useState } from 'react';

export default function HomePage() {
  const [activeService, setActiveService] = useState(0);

  const divisions = [
    {
      icon: <Youtube className="w-8 h-8" />,
      title: "Creators",
      tagline: "Creator Management",
      description: "Full-service talent management and monetization for a select roster of YouTube creators.",
      link: "/creators",
      features: [
        "Brand Partnerships",
        "Channel Growth",
        "Revenue Expansion",
        "Business Development"
      ]
    },
    {
      icon: <Briefcase className="w-8 h-8" />,
      title: "Investments",
      tagline: "Strategic Growth Capital",
      description: "We fund and scale YouTube businesses through strategic investments and acquisitions.",
      link: "/investments",
      features: [
        "Channel Acquisitions",
        "Growth Capital",
        "Operational Support",
        "Exit Planning"
      ]
    },
    {
      icon: <Clapperboard className="w-8 h-8" />,
      title: "Studio",
      tagline: "Content Production",
      description: "End-to-end video production and post-production services for creators and brands.",
      link: "/studio",
      features: [
        "Scriptwriting",
        "Video Editing",
        "Animation",
        "Audio Production",
      ]
    },
    {
      icon: <Code className="w-8 h-8" />,
      title: "Technology",
      tagline: "Creator Productivity Suite",
      description: "AI-powered tools and analytics to optimize content and accelerate channel growth.",
      link: "/technology",
      features: [
        "Business Management",
        "Analytics Suite",
        "Automation Tools",
        "Team Collaboration"
      ]
    }
  ];

  const achievements = [
    {
      icon: <Trophy className="w-6 h-6" />,
      title: "Industry Leader",
      description: "Recognized as the fastest-growing creator ecosystem"
    },
    {
      icon: <Users className="w-6 h-6" />,
      title: "Creator Network",
      description: "100+ elite creators with combined 500M+ subscribers"
    },
    {
      icon: <TrendingUp className="w-6 h-6" />,
      title: "Proven Growth",
      description: "Average 5x revenue growth for partnered channels"
    },
    {
      icon: <Star className="w-6 h-6" />,
      title: "Premium Partners",
      description: "Exclusive relationships with top global brands"
    }
  ];

  return (
    <div className="relative">
      {/* Main Background */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-black/95">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_15%_-15%,#4338ca,transparent_45%)]" />
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_10%_-5%,#6366f1,transparent_50%)]" />
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_5%_5%,#818cf8,transparent_55%)]" />
        </div>
        <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-15" />
        {/* Animated Stars */}
        {[...Array(75)].map((_, i) => (
          <div
            key={i}
            className={`absolute ${i % 3 === 0 ? 'w-1 h-1' : 'w-0.5 h-0.5'} bg-white rounded-full animate-twinkle`}
            style={{
              left: Math.random() * 100 + '%',
              top: Math.random() * 100 + '%',
              animationDelay: Math.random() * 8 + 's',
              opacity: Math.random() * 0.7 + 0.3
            }}
          />
        ))}
        {/* Floating Orbs */}
        <div className="absolute -top-32 left-0 w-[45rem] h-[45rem] bg-indigo-500/15 rounded-full blur-3xl animate-float" />
        <div className="absolute -top-48 left-24 w-[35rem] h-[35rem] bg-purple-500/15 rounded-full blur-3xl animate-float animation-delay-2000" />
      </div>

      {/* Content */}
      <div className="relative">
        {/* Hero Section */}
        <section className="relative min-h-screen flex flex-col justify-center py-24">
          {/* Hero Background Elements */}
          <div className="absolute inset-0">
            <div className="absolute inset-0">
              <div className="absolute inset-0 bg-gradient-to-b from-black/95 via-black/80 to-transparent" />
            </div>
          </div>

          {/* Hero Content */}
          <div className="relative container mx-auto px-6">
            {/* Badge */}
            <div className="text-center mb-8">
              <div className="inline-block">
                <div className="relative px-6 py-2 bg-indigo-500/10 backdrop-blur-xl rounded-full border border-indigo-400/20 flex items-center gap-2">
                  <Crown className="w-4 h-4 text-indigo-300" />
                  <span className="text-sm font-medium bg-clip-text text-transparent bg-gradient-to-r from-indigo-300 to-purple-300">
                    The Future of Creator Business
                  </span>
                </div>
              </div>
            </div>

            {/* Hero Content */}
            <div className="max-w-4xl mx-auto text-center">
              <h1 className="text-5xl md:text-7xl font-bold mb-8 leading-tight">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-indigo-100 via-white to-indigo-200">
                  A Full-Stack{' '}
                </span>
                <span className="relative inline-block">
                  <span className="absolute -inset-2 bg-gradient-to-r from-indigo-400 to-purple-400 blur-2xl opacity-30" />
                  <span className="relative bg-clip-text text-transparent bg-gradient-to-r from-indigo-300 to-purple-300">
                    Creator Ecosystem
                  </span>
                </span>
              </h1>
              
              <p className="text-xl text-indigo-100/80 max-w-2xl mx-auto mb-12 leading-relaxed">
                A fully integrated suite of talent management, strategic capital, production services, and technology to help creators build and scale world-class media businesses.
              </p>

              {/* Scroll Indicator */}
              <div className="flex flex-col items-center gap-3 mt-16">
                <span className="text-sm font-medium text-indigo-200/70">
                  Scroll to Learn More
                </span>
                <div className="animate-subtle-bounce">
                  <ChevronDown className="w-6 h-6 text-indigo-300/70" />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Divisions Section */}
        <section className="relative py-32">
          <div className="container mx-auto px-6">
            <div className="text-center mb-24">
              <h2 className="text-4xl md:text-5xl font-bold mb-6">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
                  What{' '}
                </span>
                <span className="relative inline-block">
                  <span className="absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 blur-2xl opacity-20" />
                  <span className="relative bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
                    We Do
                  </span>
                </span>
              </h2>
              <p className="text-lg text-gray-400 max-w-2xl mx-auto">
                Everything creators need to build, grow, and scale successful media businesses
              </p>
            </div>

            <div className="grid lg:grid-cols-2 gap-8">
              {divisions.map((division, index) => (
                <Link 
                  key={index}
                  to={division.link}
                  className="relative group"
                >
                  <div className="absolute -inset-px bg-gradient-to-b from-indigo-500/20 to-purple-500/20 rounded-xl blur-sm transition-all group-hover:blur-md" />
                  <div className="relative p-8 bg-black/50 backdrop-blur-sm rounded-xl border border-white/10 hover:border-white/20 transition-colors">
                    <div className="flex items-start gap-6">
                      <div className="flex-shrink-0">
                        <div className="w-16 h-16 rounded-xl bg-gradient-to-br from-indigo-500/10 to-purple-500/10 border border-white/10 flex items-center justify-center">
                          {division.icon}
                        </div>
                      </div>
                      <div className="flex-1">
                        <div className="flex items-center gap-2 mb-2">
                          <h3 className="text-2xl font-bold">{division.title}</h3>
                          <div className="text-sm text-indigo-400 font-medium">
                            {division.tagline}
                          </div>
                        </div>
                        <p className="text-gray-400 mb-6">{division.description}</p>
                        <div className="grid grid-cols-2 gap-3">
                          {division.features.map((feature, featureIndex) => (
                            <div key={featureIndex} className="flex items-center gap-2 text-sm text-gray-400">
                              <CheckCircle2 className="w-4 h-4 text-indigo-400 flex-shrink-0" />
                              <span>{feature}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </section>

        {/* Hiring Section */}
        <section className="relative py-32">
          <div className="container mx-auto px-6">
            <div className="relative max-w-4xl mx-auto">
              <div className="absolute -inset-4 bg-gradient-to-r from-indigo-500/30 to-purple-500/30 rounded-3xl blur-2xl" />
              <div className="absolute inset-0 bg-gradient-to-r from-indigo-500/10 to-purple-500/10 rounded-2xl transform rotate-1" />
              <div className="absolute inset-0 bg-gradient-to-r from-purple-500/10 to-indigo-500/10 rounded-2xl transform -rotate-1" />
              <div className="relative bg-black/40 backdrop-blur-xl rounded-2xl p-12 border border-white/10">
                <div className="relative z-10 text-center">
                  <div className="inline-flex items-center gap-2 px-4 py-2 bg-indigo-500/10 rounded-full border border-indigo-500/20 mb-8">
                    <Sparkles className="w-4 h-4 text-indigo-400" />
                    <span className="text-sm font-medium text-indigo-400">Join Our Team</span>
                  </div>
                  
                  <h2 className="text-4xl md:text-5xl font-bold mb-6">
                    <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
                      We're{' '}
                    </span>
                    <span className="relative inline-block">
                      <span className="absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 blur-2xl opacity-20" />
                      <span className="relative bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
                        Hiring
                      </span>
                    </span>
                  </h2>

                  <p className="text-lg text-gray-400 max-w-2xl mx-auto mb-12">
                    Join us in building the future of creator media. We're looking for exceptional talent across engineering, production, and business development.
                  </p>

                  <div className="grid md:grid-cols-3 gap-6 mb-12">
                    {[
                      {
                        icon: <Code className="w-5 h-5" />,
                        title: "Engineering",
                        roles: ["Full-Stack Engineers", "ML Engineers", "Data Scientists"]
                      },
                      {
                        icon: <Clapperboard className="w-5 h-5" />,
                        title: "Production",
                        roles: ["Video Editors", "Motion Designers", "Creative Directors"]
                      },
                      {
                        icon: <Briefcase className="w-5 h-5" />,
                        title: "Business",
                        roles: ["Partner Success", "Strategy", "Operations"]
                      }
                    ].map((department, index) => (
                      <div key={index} className="relative group">
                        <div className="absolute -inset-px bg-gradient-to-b from-indigo-500/20 to-purple-500/20 rounded-xl blur-sm" />
                        <div className="relative p-6 bg-black/30 backdrop-blur-sm rounded-xl border border-white/10">
                          <div className="flex items-center gap-3 mb-4">
                            <div className="w-10 h-10 rounded-lg bg-gradient-to-br from-indigo-500/10 to-purple-500/10 border border-white/10 flex items-center justify-center">
                              {department.icon}
                            </div>
                            <h3 className="text-lg font-bold">{department.title}</h3>
                          </div>
                          <ul className="space-y-2">
                            {department.roles.map((role, roleIndex) => (
                              <li key={roleIndex} className="text-sm text-gray-400 flex items-center gap-2">
                                <div className="w-1 h-1 bg-indigo-400 rounded-full" />
                                {role}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>

                  <Link 
                    to="/careers" 
                    className="group relative inline-flex items-center gap-2"
                  >
                    <div className="absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-xl blur-xl opacity-20 group-hover:opacity-40 transition-opacity duration-500" />
                    <div className="relative inline-flex items-center gap-2 px-8 py-4 bg-gradient-to-r from-indigo-500/10 to-purple-500/10 rounded-xl border border-white/10 font-medium text-white hover:border-white/20 transition-colors duration-500">
                      <span>View Open Positions</span>
                      <ArrowRight className="w-5 h-5" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}