import { ArrowLeft } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import { ContractorApplicationForm } from '../components/ContractorApplicationForm';

export default function ContractorApplicationPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const position = searchParams.get('position') || 'Contract Position';

  return (
    <div className="relative pt-24 sm:pt-28 md:pt-32">
      {/* Background with same aesthetic */}
      <div className="absolute inset-0 -z-10">
        <div className="absolute inset-0 bg-black">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_60%_-20%,#4338ca,transparent_50%)]" />
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_150%,#6366f1,transparent_50%)]" />
        </div>
        <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-10" />
      </div>

      <div className="container mx-auto px-4 sm:px-6 py-16 sm:py-20 md:py-24">
        {/* Back Button */}
        <Link
          to="/careers"
          className="group relative inline-flex items-center gap-2 mb-8"
        >
          <div className="absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-xl blur-xl opacity-0 group-hover:opacity-20 transition-opacity duration-500" />
          <div className="relative inline-flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-indigo-500/10 to-purple-500/10 rounded-xl border border-white/10 font-medium text-white hover:border-white/20 transition-colors duration-500">
            <ArrowLeft className="w-4 h-4" />
            <span>Back to Careers</span>
          </div>
        </Link>

        {/* Page Content */}
        <div className="max-w-3xl mx-auto">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-6">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
              Apply for{' '}
            </span>
            <span className="relative inline-block">
              <span className="absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 blur-2xl opacity-20" />
              <span className="relative bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
                {position}
              </span>
            </span>
          </h1>

          <p className="text-gray-400 mb-8 text-lg">
            Please fill out the form below to apply for this contract position. We'll review your application and get back to you as soon as possible.
          </p>

          <div className="bg-black/40 backdrop-blur-xl rounded-3xl p-6 sm:p-8 md:p-12 border border-white/10 mb-16">
            <ContractorApplicationForm position={position} />
          </div>
        </div>
      </div>
    </div>
  );
} 