import { Scale, FileText, Shield, AlertCircle, CheckCircle2, Handshake, ArrowRight } from 'lucide-react';

export default function TermsPage() {
  return (
    <div className="relative overflow-hidden">
      {/* Hero Section */}
      <section className="relative py-24">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-black">
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_60%_-20%,#4338ca,transparent_25%)]" />
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_150%,#6366f1,transparent_25%)]" />
          </div>
          <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-5" />
        </div>

        <div className="relative container mx-auto px-6">
          {/* Badge */}
          <div className="mb-8">
            <div className="relative inline-block">
              <div className="absolute -inset-1 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-full blur opacity-30" />
              <div className="relative px-6 py-2 bg-black/50 backdrop-blur-xl rounded-full border border-white/10 flex items-center gap-2">
                <Scale className="w-4 h-4 text-indigo-400" />
                <span className="text-sm font-medium bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
                  Terms of Service
                </span>
              </div>
            </div>
          </div>

          <h1 className="text-4xl md:text-5xl font-bold mb-6">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
              Terms of Service
            </span>
          </h1>
          
          <p className="text-lg text-gray-400 max-w-2xl mb-8">
            Last updated: March 2024
          </p>
        </div>
      </section>

      {/* Content Section */}
      <section className="relative py-16">
        <div className="container mx-auto px-6">
          <div className="prose prose-invert max-w-4xl mx-auto">
            <div className="space-y-16">
              {/* Agreement */}
              <div>
                <h2 className="text-2xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-white to-white/70">
                  Agreement to Terms
                </h2>
                <div className="space-y-4 text-gray-400">
                  <p>
                    By accessing or using Galactic Media's services, you agree to be bound by these Terms of Service and all applicable laws and regulations. If you do not agree with any of these terms, you are prohibited from using or accessing our services.
                  </p>
                </div>
              </div>

              {/* Key Terms */}
              <div>
                <h2 className="text-2xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-white to-white/70">
                  Key Terms
                </h2>
                <div className="grid gap-8">
                  {[
                    {
                      icon: <Handshake className="w-6 h-6" />,
                      title: "Service Agreement",
                      content: "We provide content production, talent management, and business development services for YouTube creators."
                    },
                    {
                      icon: <CheckCircle2 className="w-6 h-6" />,
                      title: "Eligibility",
                      content: "You must be at least 18 years old and have the authority to enter into this agreement."
                    },
                    {
                      icon: <AlertCircle className="w-6 h-6" />,
                      title: "Compliance",
                      content: "You agree to comply with all applicable laws and platform policies when using our services."
                    }
                  ].map((item, index) => (
                    <div key={index} className="group relative">
                      <div className="absolute -inset-px rounded-3xl bg-gradient-to-b from-indigo-500/50 via-indigo-500/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
                      <div className="relative bg-black/40 backdrop-blur-xl rounded-3xl p-8 border border-white/10">
                        <div className="flex gap-4 items-start">
                          <div className="w-12 h-12 rounded-xl bg-indigo-500/10 flex items-center justify-center flex-shrink-0">
                            <div className="text-indigo-400">
                              {item.icon}
                            </div>
                          </div>
                          <div>
                            <h3 className="text-xl font-semibold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-white to-white/70">
                              {item.title}
                            </h3>
                            <p className="text-gray-400">
                              {item.content}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Service Terms */}
              <div>
                <h2 className="text-2xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-white to-white/70">
                  Service Terms
                </h2>
                <div className="space-y-4 text-gray-400">
                  <h3 className="text-xl font-semibold">Content Production Services</h3>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>We retain the right to refuse service for content that violates our policies</li>
                    <li>Turnaround times are estimates and may vary based on project complexity</li>
                    <li>Revisions are limited as specified in your service agreement</li>
                    <li>Final deliverables will be provided upon full payment</li>
                  </ul>

                  <h3 className="text-xl font-semibold mt-8">Talent Management</h3>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>Management agreements are subject to separate terms</li>
                    <li>Commission rates are as specified in your management agreement</li>
                    <li>Exclusivity terms must be honored as per agreement</li>
                  </ul>

                  <h3 className="text-xl font-semibold mt-8">Business Development</h3>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>Success of business strategies is not guaranteed</li>
                    <li>Implementation timeline may vary based on market conditions</li>
                    <li>Additional services may require separate agreements</li>
                  </ul>
                </div>
              </div>

              {/* Payment Terms */}
              <div>
                <h2 className="text-2xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-white to-white/70">
                  Payment Terms
                </h2>
                <div className="space-y-4 text-gray-400">
                  <p>
                    Payment terms vary by service type and agreement. All fees are non-refundable unless otherwise specified in your service agreement.
                  </p>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>Subscription fees are billed monthly or annually</li>
                    <li>Late payments may result in service suspension</li>
                    <li>Currency conversion fees are your responsibility</li>
                    <li>Taxes are not included in listed prices</li>
                  </ul>
                </div>
              </div>

              {/* Intellectual Property */}
              <div>
                <h2 className="text-2xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-white to-white/70">
                  Intellectual Property
                </h2>
                <div className="space-y-4 text-gray-400">
                  <p>
                    You retain rights to your original content. However, you grant us necessary licenses to provide our services. We retain rights to our proprietary tools, processes, and methodologies.
                  </p>
                </div>
              </div>

              {/* Termination */}
              <div>
                <h2 className="text-2xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-white to-white/70">
                  Termination
                </h2>
                <div className="space-y-4 text-gray-400">
                  <p>
                    We may terminate or suspend your access to our services immediately, without prior notice or liability, for any reason, including breach of these Terms.
                  </p>
                </div>
              </div>

              {/* Contact */}
              <div>
                <h2 className="text-2xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-white to-white/70">
                  Contact Us
                </h2>
                <div className="space-y-4 text-gray-400">
                  <p>
                    If you have any questions about these Terms, please contact us at:
                  </p>
                  <p>
                    Email: legal@galacticmedia.tv
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}