const YOUTUBE_API_BASE = 'https://www.googleapis.com/youtube/v3';

export async function getChannelData(channelUrl: string) {
  try {
    // Extract channel ID or handle from URL
    const channelId = await extractChannelIdentifier(channelUrl);
    if (!channelId) {
      throw new Error('Invalid YouTube channel URL');
    }
    
    // Get channel details
    const channelResponse = await fetch(
      `${YOUTUBE_API_BASE}/channels?` + new URLSearchParams({
        id: channelId,
        part: 'snippet,statistics,contentDetails',
        key: import.meta.env.VITE_YOUTUBE_API_KEY
      })
    );
    const channelData = await channelResponse.json();
    
    if (!channelData.items?.length) {
      throw new Error('Channel not found');
    }

    const channel = channelData.items[0];

    // Get recent videos
    const videosResponse = await fetch(
      `${YOUTUBE_API_BASE}/search?` + new URLSearchParams({
        channelId: channel.id,
        part: 'snippet',
        order: 'date',
        type: 'video',
        maxResults: '50',  // Increased to get more videos since some might be shorts
        publishedAfter: new Date(Date.now() - 365 * 24 * 60 * 60 * 1000).toISOString(), // Last year only
        key: import.meta.env.VITE_YOUTUBE_API_KEY
      })
    );
    const videosData = await videosResponse.json();
    
    console.log('Raw videos response:', videosData);
    
    // Get detailed video information to check for shorts
    const videoIds = videosData.items?.map((item: any) => item.id.videoId) || [];
    const videoDetailsResponse = await fetch(
      `${YOUTUBE_API_BASE}/videos?` + new URLSearchParams({
        id: videoIds.join(','),
        part: 'contentDetails,snippet,statistics',
        key: import.meta.env.VITE_YOUTUBE_API_KEY
      })
    );
    const videoDetails = await videoDetailsResponse.json();
    
    console.log('Video details response:', videoDetails);

    // Process videos and separate shorts from regular videos
    interface VideoDetails {
      publishedAt: string;
      isShort: boolean;
      duration: string;
      viewCount: number;
    }

    const recentVideos = videoDetails.items?.map((item: any) => {
      const isShort = item.contentDetails.duration === 'PT60S' || // 60 seconds or less
                     item.contentDetails.duration.match(/^PT[0-5][0-9]S$/); // matches PT00S to PT59S
      
      // Log the raw item data to debug
      console.log('Processing video:', {
        id: item.id,
        title: item.snippet.title,
        publishedAt: item.snippet.publishedAt,
        duration: item.contentDetails.duration,
        isShort
      });
      
      return {
        publishedAt: item.snippet.publishedAt,
        isShort,
        duration: item.contentDetails.duration,
        viewCount: parseInt(item.statistics?.viewCount || '0', 10)
      };
    }) || [];

    // Log the processed videos to debug
    console.log('Processed videos:', recentVideos);

    // Filter out shorts for upload frequency calculation
    const regularVideos = recentVideos.filter((video: VideoDetails) => !video.isShort);

    // Get the channel's username from custom URL
    const username = channel.snippet?.customUrl?.replace(/^@/, '') || 
                    channel.snippet?.title?.toLowerCase().replace(/\s+/g, '') || 
                    channelId;

    return {
      id: channel.id,
      username,
      title: channel.snippet?.title || '',
      description: channel.snippet?.description || '',
      thumbnails: channel.snippet?.thumbnails,
      statistics: {
        subscribers: parseInt(channel.statistics?.subscriberCount || '0'),
        totalViews: parseInt(channel.statistics?.viewCount || '0'),
        videoCount: parseInt(channel.statistics?.videoCount || '0'),
        averageViews: Math.round(parseInt(channel.statistics?.viewCount || '0') / parseInt(channel.statistics?.videoCount || '1'))
      },
      joinDate: channel.snippet?.publishedAt || '',
      country: channel.snippet?.country || '',
      recentVideos: regularVideos  // Only pass regular videos for frequency calculation
    };
  } catch (error) {
    console.error('Error fetching channel data:', error);
    throw error;
  }
}

async function extractChannelIdentifier(input: string): Promise<string | null> {
  try {
    // If input is a URL, parse it
    if (input.includes('youtube.com') || input.includes('youtu.be')) {
      const urlObj = new URL(input);
      const pathname = urlObj.pathname;

      // Handle different URL formats
      if (pathname.includes('/channel/')) {
        return pathname.split('/channel/')[1].split('/')[0];
      } else if (pathname.includes('/c/')) {
        const customUrl = pathname.split('/c/')[1].split('/')[0];
        return await resolveCustomUrl(customUrl);
      } else if (pathname.includes('/user/')) {
        const username = pathname.split('/user/')[1].split('/')[0];
        return await resolveUsername(username);
      } else if (pathname.includes('/@')) {
        const handle = pathname.split('/@')[1].split('/')[0];
        return await resolveHandle(handle);
      }
    } else {
      // Try to resolve direct input as username, custom URL, or handle
      const identifier = input.startsWith('@') ? input.slice(1) : input;
      return await resolveAnyIdentifier(identifier);
    }

    return null;
  } catch {
    return null;
  }
}

async function resolveCustomUrl(customUrl: string): Promise<string | null> {
  try {
    const response = await fetch(
      `${YOUTUBE_API_BASE}/channels?` + new URLSearchParams({
        forUsername: customUrl,
        part: 'id',
        key: import.meta.env.VITE_YOUTUBE_API_KEY
      })
    );
    const data = await response.json();
    return data.items?.[0]?.id || null;
  } catch {
    return null;
  }
}

async function resolveUsername(username: string): Promise<string | null> {
  try {
    const response = await fetch(
      `${YOUTUBE_API_BASE}/channels?` + new URLSearchParams({
        forUsername: username,
        part: 'id',
        key: import.meta.env.VITE_YOUTUBE_API_KEY
      })
    );
    const data = await response.json();
    return data.items?.[0]?.id || null;
  } catch {
    return null;
  }
}

async function resolveHandle(handle: string): Promise<string | null> {
  try {
    const response = await fetch(
      `${YOUTUBE_API_BASE}/channels?` + new URLSearchParams({
        forHandle: handle,
        part: 'id',
        key: import.meta.env.VITE_YOUTUBE_API_KEY
      })
    );
    const data = await response.json();
    return data.items?.[0]?.id || null;
  } catch {
    return null;
  }
}

async function resolveAnyIdentifier(identifier: string): Promise<string | null> {
  // Try each method in sequence until one works
  const methods = [resolveHandle, resolveUsername, resolveCustomUrl];
  
  for (const method of methods) {
    const result = await method(identifier);
    if (result) return result;
  }
  
  return null;
}