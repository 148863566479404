import { Users, Globe, Award, Zap, ArrowRight, Sparkles, Building2, Youtube, Briefcase, Code, MapPin, ChevronRight, ExternalLink, Clapperboard } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import Globe3D from '../components/Globe';

// Custom X Logo component
function XLogo({ className = "w-4 h-4" }) {
  return (
    <svg viewBox="0 0 24 24" aria-hidden="true" className={className}>
      <path
        fill="currentColor"
        d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
      />
    </svg>
  );
}

// Description component to handle YouTube links
function Description({ name, channelHandle }: { name: string; channelHandle?: string }) {
  if (!channelHandle) {
    return <span>{name}</span>;
  }
  return (
    <a
      href={`https://youtube.com/@${channelHandle}`}
      target="_blank"
      rel="noopener noreferrer"
      className="text-indigo-400 hover:text-indigo-300 transition-colors"
    >
      {name}
    </a>
  );
}

export default function AboutPage() {
  const [activeTab, setActiveTab] = useState('talent');

  return (
    <div className="relative">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-black">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_60%_-20%,#4338ca,transparent_50%)]" />
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_150%,#6366f1,transparent_50%)]" />
        </div>
        <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-10" />
        {/* Enhanced Star Field */}
        {[...Array(100)].map((_, i) => (
          <div
            key={i}
            className="absolute w-0.5 h-0.5 bg-white rounded-full animate-twinkle"
            style={{
              left: Math.random() * 100 + '%',
              top: Math.random() * 100 + '%',
              animationDelay: Math.random() * 8 + 's',
              opacity: Math.random() * 0.7 + 0.3,
              transform: `scale(${Math.random() * 1.5 + 0.5})`
            }}
          />
        ))}
        <div className="absolute top-1/4 left-1/4 w-96 h-96 bg-indigo-500/20 rounded-full blur-3xl animate-float" />
        <div className="absolute bottom-1/4 right-1/4 w-96 h-96 bg-purple-500/20 rounded-full blur-3xl animate-float animation-delay-2000" />
      </div>

      {/* Hero Section */}
      <section className="relative pt-24 sm:pt-32 md:pt-40 pb-16 sm:pb-20 md:pb-24">
        <div className="container mx-auto px-4 sm:px-6">
          <div className="max-w-5xl mx-auto">
            <div className="text-center">
              <h1 className="text-4xl sm:text-5xl md:text-7xl font-bold mb-6 sm:mb-8 leading-tight">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
                  About Galactic
                </span>
              </h1>
              <p className="text-base sm:text-lg md:text-xl text-gray-400 max-w-3xl mx-auto leading-relaxed">
                Galactic Media integrates talent management, content production, strategic investments, and technology to redefine what's possible in the YouTube ecosystem.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Mission Section */}
      <section className="relative py-24 sm:py-28 md:py-32">
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-indigo-500/5 to-indigo-500/10" />
        <div className="container mx-auto px-4 sm:px-6 relative">
          <div className="max-w-5xl mx-auto">
            <div className="text-center mb-12 sm:mb-16">
              <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4 sm:mb-6">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
                  Our Mission
                </span>
              </h2>
            </div>

            <div className="grid md:grid-cols-2 gap-8 sm:gap-12 md:gap-16 items-center">
              <div className="relative">
                <div className="absolute -inset-px rounded-2xl sm:rounded-3xl bg-gradient-to-b from-indigo-500/50 via-indigo-500/5 to-transparent" />
                <div className="relative bg-black/40 backdrop-blur-xl rounded-2xl sm:rounded-3xl p-6 sm:p-8 border border-white/10">
                  <div className="prose prose-invert max-w-none">
                    <p className="text-base sm:text-lg md:text-xl text-gray-300 leading-relaxed mb-4 sm:mb-6">
                      Galactic Media is a media, talent, and investment company built to scale YouTube businesses. We work at the intersection of talent management, content production, acquisitions, and technology, creating a structured approach to growing digital media assets.
                    </p>
                    <p className="text-sm sm:text-base text-gray-400 leading-relaxed mb-4 sm:mb-6">
                      Stationed in Atlanta, GA, with satellites in Tokyo and London, we operate galacitcally (and globally), helping creators, brands, and investors navigate the evolving YouTube landscape.
                    </p>
                    <p className="text-sm sm:text-base text-gray-400 leading-relaxed">
                      Founded by Christian Mullican (YouTube business operator and investor), alongside John Frazer (founder of MagnatesMedia) and Jordan Kouzmanoff (founder of SideQuest Animated History and Business Casual) as strategic advisors, Galactic brings together expertise in content, finance, media investment, and creator business strategy.
                    </p>
                  </div>
                </div>
              </div>

              <div className="space-y-4 sm:space-y-6 md:space-y-8">
                {[
                  {
                    title: "Creator First",
                    description: "Everything we build and every decision we make starts with the creator's success in mind.",
                    icon: Users
                  },
                  {
                    title: "Innovation Driven",
                    description: "We're constantly pushing boundaries and developing new solutions to evolve with the digital landscape.",
                    icon: Zap
                  },
                  {
                    title: "Long-term Focus",
                    description: "We build for sustainable growth and lasting impact in the creator economy.",
                    icon: Award
                  }
                ].map((value, index) => (
                  <div key={index} className="group relative">
                    <div className="absolute -inset-px rounded-xl sm:rounded-2xl bg-gradient-to-b from-indigo-500/50 via-indigo-500/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
                    <div className="relative bg-black/40 backdrop-blur-xl rounded-xl sm:rounded-2xl p-4 sm:p-6 border border-white/10">
                      <div className="flex items-center gap-3 sm:gap-4">
                        <div className="flex-shrink-0 w-10 sm:w-12 h-10 sm:h-12 rounded-lg sm:rounded-xl bg-gradient-to-br from-indigo-500/10 to-purple-500/10 border border-white/10 flex items-center justify-center">
                          <value.icon className="w-5 sm:w-6 h-5 sm:h-6 text-indigo-400" />
                        </div>
                        <div>
                          <h3 className="text-lg sm:text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white to-white/70 mb-1 sm:mb-2">
                            {value.title}
                          </h3>
                          <p className="text-sm sm:text-base text-gray-400">
                            {value.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Divisions Section */}
      <section className="relative py-24 sm:py-28 md:py-32">
        <div className="absolute inset-0 bg-gradient-to-b from-indigo-500/10 via-indigo-500/5 to-transparent" />
        <div className="container mx-auto px-4 sm:px-6 relative">
          <div className="text-center mb-16 sm:mb-20 md:mb-24">
            <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4 sm:mb-6">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
                Our Divisions
              </span>
            </h2>
            <p className="text-base sm:text-lg text-gray-400 max-w-2xl mx-auto">
              Four specialized divisions working together to drive innovation in digital media
            </p>
          </div>

          <div className="grid sm:grid-cols-2 gap-8 sm:gap-12 md:gap-16">
            {[
              {
                title: "Talent Management",
                icon: Users,
                description: "Supporting creators at every stage of their journey with brand partnerships, monetization strategies, and business development.",
                metrics: ["Brand Partnerships", "Revenue Growth", "Career Development"]
              },
              {
                title: "Production House",
                icon: Youtube,
                description: "Delivering high-quality video content with comprehensive channel optimization and growth strategies.",
                metrics: ["Content Creation", "Channel Growth", "Audience Building"]
              },
              {
                title: "Investment Group",
                icon: Briefcase,
                description: "Acquiring and scaling high-potential YouTube channels through strategic investment and optimization.",
                metrics: ["Channel Acquisition", "Asset Growth", "Portfolio Management"]
              },
              {
                title: "Technology",
                icon: Code,
                description: "Building cutting-edge tools and platforms to help creator businesses operate efficiently and scale effectively.",
                metrics: ["Analytics Platform", "Growth Tools", "Business Suite"]
              }
            ].map((division, index) => (
              <div key={index} className={`relative group ${
                index % 2 === 0 ? 'sm:translate-y-16' : ''
              }`}>
                <div className="absolute -inset-px bg-gradient-to-b from-indigo-500/20 to-purple-500/20 rounded-xl sm:rounded-2xl blur-sm transition-all group-hover:blur-md" />
                <div className="relative p-6 sm:p-8 bg-black/50 backdrop-blur-sm rounded-xl sm:rounded-2xl border border-white/10 hover:border-white/20 transition-colors">
                  <div className="absolute -top-6 sm:-top-8 left-1/2 transform -translate-x-1/2">
                    <div className="relative">
                      <div className="absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-xl blur-xl opacity-50" />
                      <div className="relative w-12 sm:w-16 h-12 sm:h-16 rounded-xl bg-black flex items-center justify-center border border-white/10">
                        <division.icon className="w-5 sm:w-6 h-5 sm:h-6" />
                      </div>
                    </div>
                  </div>
                  <div className="pt-6 sm:pt-8">
                    <h3 className="text-xl sm:text-2xl font-bold text-center mb-3 sm:mb-4">{division.title}</h3>
                    <p className="text-sm sm:text-base text-gray-400 text-center mb-4 sm:mb-6">{division.description}</p>
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-3">
                      {division.metrics.map((metric, metricIndex) => (
                        <div key={metricIndex} className="relative group">
                          <div className="absolute -inset-px bg-gradient-to-b from-indigo-500/10 to-purple-500/10 rounded-lg blur-sm" />
                          <div className="relative p-2 sm:p-3 bg-black/30 rounded-lg border border-white/5 text-center">
                            <div className="text-xs sm:text-sm font-medium text-indigo-400">{metric}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Leadership Section */}
      <section className="relative py-24 sm:py-28 md:py-32">
        <div className="container mx-auto px-4 sm:px-6">
          <div className="max-w-5xl mx-auto">
            <div className="text-center mb-12 sm:mb-16">
              <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4 sm:mb-6">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
                  Leadership Team
                </span>
              </h2>
              <p className="text-base sm:text-lg md:text-xl text-gray-400 max-w-3xl mx-auto">
                Industry veterans with deep expertise in content, business strategy, and finance
              </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 sm:gap-8">
              {[
                {
                  name: "Christian Mullican",
                  role: "Founder & CEO",
                  description: "YouTube business veteran with a track record of acquiring, scaling, and exiting branded YouTube businesses and operating some of the top educational channels on YouTube.",
                  image: "/images/cm.png",
                  twitter: "cpmullican"
                },
                {
                  name: "John Frazer",
                  role: "Founding Partner & Advisor",
                  description: <>Founder of <Description name="MagnatesMedia" channelHandle="MagnatesMedia" />, bringing expertise in content strategy and audience development. John serves as a founding partner and strategic advisor to Galactic Media.</>,
                  image: "/images/jf.png",
                  twitter: "magnatesmedia",
                  youtube: "@MagnatesMedia"
                },
                {
                  name: "Jordan Kouzmanoff",
                  role: "Founding Partner & Advisor",
                  description: <>Founder of <Description name="SideQuest" channelHandle="SideQuestYT" />, a popular animated history channel on YouTube and original founder of Business Casual. Jordan serves as a founding partner and strategic advisor to Galactic Media.</>,
                  image: "/images/jordan-k.png",
                  twitter: "j_kouzmanoff",
                  youtube: "@SideQuestYT"
                }
              ].map((member, index) => (
                <div key={index} className="group relative">
                  <div className="absolute -inset-px rounded-3xl bg-gradient-to-b from-indigo-500/50 via-indigo-500/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
                  <div className="relative h-full bg-black/40 backdrop-blur-xl rounded-3xl overflow-hidden border border-white/10">
                    <div className="aspect-square relative overflow-hidden">
                      <img 
                        src={member.image}
                        alt={member.name}
                        className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
                    </div>
                    <div className="p-6">
                      <div className="flex items-center justify-between mb-1">
                        <h3 className="text-lg sm:text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white to-white/70">
                          {member.name}
                        </h3>
                        <a
                          href={`https://x.com/${member.twitter}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="p-2 rounded-xl bg-white/5 hover:bg-white/10 transition-colors duration-300 group-hover:bg-white/10"
                        >
                          <XLogo className="w-4 h-4 text-white/70 group-hover:text-white transition-colors duration-300" />
                        </a>
                      </div>
                      <div className="text-indigo-400 font-medium mb-4">
                        {member.role}
                      </div>
                      <p className="text-sm sm:text-base text-gray-400 group-hover:text-gray-300 transition-colors duration-500">
                        {member.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Second row of leadership team */}
            <div className="flex flex-col sm:flex-row justify-center mt-6 sm:mt-8 md:mt-16 gap-6 sm:gap-12 md:gap-24">
              {[
                {
                  name: "Michael Murawski",
                  role: "Head of Strategy",
                  description: "With a background in economics and finance, Michael supports across operations and investment strategy at Galactic Media.",
                  image: "/images/mm.png",
                  twitter: "mimuraYT"
                },
                {
                  name: "Federico Bolaños",
                  role: "Head of Production",
                  description: "Seasoned writer and producer with a track record of developing high-performing entertainment and education content for some of YouTube's most influential channels.",
                  image: "/images/federico-b.png",
                  twitter: "FedeBolanos"
                }
              ].map((member, index) => (
                <div key={index} className="group relative w-full sm:w-[320px]">
                  <div className="absolute -inset-px rounded-2xl sm:rounded-3xl bg-gradient-to-b from-indigo-500/50 via-indigo-500/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
                  <div className="relative h-full bg-black/40 backdrop-blur-xl rounded-2xl sm:rounded-3xl overflow-hidden border border-white/10">
                    <div className="aspect-square relative overflow-hidden">
                      <img 
                        src={member.image}
                        alt={member.name}
                        className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
                    </div>
                    <div className="p-4 sm:p-6">
                      <div className="flex items-center justify-between mb-1">
                        <h3 className="text-lg sm:text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white to-white/70">
                          {member.name}
                        </h3>
                        <a
                          href={`https://x.com/${member.twitter}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="p-1.5 sm:p-2 rounded-lg sm:rounded-xl bg-white/5 hover:bg-white/10 transition-colors duration-300 group-hover:bg-white/10"
                        >
                          <XLogo className="w-3.5 sm:w-4 h-3.5 sm:h-4 text-white/70 group-hover:text-white transition-colors duration-300" />
                        </a>
                      </div>
                      <div className="text-sm sm:text-base text-indigo-400 font-medium mb-3 sm:mb-4">
                        {member.role}
                      </div>
                      <p className="text-xs sm:text-sm text-gray-400 group-hover:text-gray-300 transition-colors duration-500">
                        {member.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Global Presence Section */}
      <section className="relative py-24 sm:py-28 md:py-32">
        <div className="container mx-auto px-4 sm:px-6">
          <div className="max-w-5xl mx-auto">
            <div className="text-center mb-12 sm:mb-16">
              <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4 sm:mb-6">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
                  Global Presence
                </span>
              </h2>
              <p className="text-base sm:text-lg md:text-xl text-gray-400 max-w-3xl mx-auto">
                Supporting creators and brands worldwide from our strategic locations
              </p>
            </div>

            {/* Interactive Map */}
            <div className="relative">
              <div className="absolute -inset-px rounded-2xl sm:rounded-3xl bg-gradient-to-b from-indigo-500/50 via-indigo-500/5 to-transparent" />
              <div className="relative bg-black/40 backdrop-blur-xl rounded-2xl sm:rounded-3xl border border-white/10 overflow-hidden">
                <div className="w-full h-[400px] sm:h-[500px] md:h-[600px]">
                  <Globe3D />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Hiring Section */}
      <section className="relative py-24 sm:py-28 md:py-32">
        <div className="container mx-auto px-4 sm:px-6">
          <div className="relative max-w-4xl mx-auto">
            <div className="absolute -inset-4 bg-gradient-to-r from-indigo-500/30 to-purple-500/30 rounded-3xl blur-2xl" />
            <div className="absolute inset-0 bg-gradient-to-r from-indigo-500/10 to-purple-500/10 rounded-2xl transform rotate-1" />
            <div className="absolute inset-0 bg-gradient-to-r from-purple-500/10 to-indigo-500/10 rounded-2xl transform -rotate-1" />
            <div className="relative bg-black/40 backdrop-blur-xl rounded-xl sm:rounded-2xl p-6 sm:p-8 md:p-12 border border-white/10">
              <div className="relative z-10 text-center">
                <div className="inline-flex items-center gap-2 px-3 sm:px-4 py-1.5 sm:py-2 bg-indigo-500/10 rounded-full border border-indigo-500/20 mb-6 sm:mb-8">
                  <Sparkles className="w-3.5 sm:w-4 h-3.5 sm:h-4 text-indigo-400" />
                  <span className="text-xs sm:text-sm font-medium text-indigo-400">Join Our Team</span>
                </div>
                
                <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4 sm:mb-6">
                  <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
                    We're <span className="relative bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">Hiring</span>
                  </span>
                </h2>

                <p className="text-base sm:text-lg text-gray-400 max-w-2xl mx-auto mb-8 sm:mb-12">
                  Join our team of innovators, creators, and strategists. We're looking for exceptional talent to help shape the future of creator media.
                </p>

                <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4 sm:gap-6 mb-8 sm:mb-12">
                  {[
                    {
                      icon: <Code className="w-4 sm:w-5 h-4 sm:h-5" />,
                      title: "Engineering",
                      roles: ["Full-Stack Engineers", "ML Engineers", "Data Scientists"]
                    },
                    {
                      icon: <Clapperboard className="w-4 sm:w-5 h-4 sm:h-5" />,
                      title: "Production",
                      roles: ["Video Editors", "Motion Designers", "Creative Directors"]
                    },
                    {
                      icon: <Briefcase className="w-4 sm:w-5 h-4 sm:h-5" />,
                      title: "Business",
                      roles: ["Partner Success", "Strategy", "Operations"]
                    }
                  ].map((department, index) => (
                    <div key={index} className="relative group">
                      <div className="absolute -inset-px bg-gradient-to-b from-indigo-500/20 to-purple-500/20 rounded-lg sm:rounded-xl blur-sm" />
                      <div className="relative p-4 sm:p-6 bg-black/30 backdrop-blur-sm rounded-lg sm:rounded-xl border border-white/10">
                        <div className="flex items-center gap-2 sm:gap-3 mb-3 sm:mb-4">
                          <div className="w-8 sm:w-10 h-8 sm:h-10 rounded-lg bg-gradient-to-br from-indigo-500/10 to-purple-500/10 border border-white/10 flex items-center justify-center">
                            {department.icon}
                          </div>
                          <h3 className="text-base sm:text-lg font-bold">{department.title}</h3>
                        </div>
                        <ul className="space-y-1.5 sm:space-y-2">
                          {department.roles.map((role, roleIndex) => (
                            <li key={roleIndex} className="text-xs sm:text-sm text-gray-400 flex items-center gap-1.5 sm:gap-2">
                              <div className="w-1 h-1 bg-indigo-400 rounded-full" />
                              {role}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>

                <Link 
                  to="/careers" 
                  className="group relative inline-flex items-center gap-2"
                >
                  <div className="absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-xl blur-xl opacity-20 group-hover:opacity-40 transition-opacity duration-500" />
                  <div className="relative inline-flex items-center gap-2 px-6 sm:px-8 py-3 sm:py-4 bg-gradient-to-r from-indigo-500/10 to-purple-500/10 rounded-xl border border-white/10 text-sm sm:text-base font-medium text-white hover:border-white/20 transition-colors duration-500">
                    <span>View Open Positions</span>
                    <ArrowRight className="w-4 sm:w-5 h-4 sm:h-5" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}