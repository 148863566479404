import { 
  TrendingUp, 
  Shield, 
  DollarSign, 
  CheckCircle2, 
  ArrowRight,
  LayoutGrid,
  Star,
  Trophy,
  Briefcase,
  ChevronDown
} from 'lucide-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function InvestmentsPage() {
  const [activeTab, setActiveTab] = useState(0);

  const scrollToServices = () => {
    const criteriaSection = document.getElementById('investment-criteria');
    if (criteriaSection) {
      criteriaSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const exitOptions = [
    {
      icon: <TrendingUp className="w-6 h-6" />,
      title: "Strategic Exit",
      description: "Full acquisition with comprehensive transition support",
      features: ["Lump sum payment", "Fair market valuation", "Smooth handover"]
    },
    {
      icon: <Shield className="w-6 h-6" />,
      title: "Majority Investment",
      description: "Significant stake while retaining partial ownership",
      features: ["Upfront payment", "Ongoing profit share", "Growth support"]
    },
    {
      icon: <DollarSign className="w-6 h-6" />,
      title: "Growth Partnership",
      description: "Strategic investment while maintaining control",
      features: ["Minority investment", "Strategic support", "Revenue sharing"]
    }
  ];

  return (
    <div className="relative">
      {/* Main Background */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-black">
          {/* Main continuous gradients */}
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_60%_0%,#4338ca,transparent_100%)] opacity-30" />
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_100%,#6366f1,transparent_100%)] opacity-30" />
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_0%_50%,#818cf8,transparent_70%)] opacity-15" />
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_100%_50%,#a855f7,transparent_70%)] opacity-15" />
        </div>
        <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-15" />
      </div>

      {/* Content */}
      <div className="relative">
        {/* Hero Section */}
        <section className="relative min-h-screen flex flex-col justify-center py-24">
          {/* Hero Background Elements */}
          <div className="absolute inset-0">
            <div className="absolute inset-0">
              <div className="absolute inset-0 bg-gradient-to-b from-black/40 via-transparent to-transparent" />
              {/* Soft glow effects */}
              <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_30%,#4338ca,transparent_70%)] opacity-20" />
              <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_80%,#6366f1,transparent_70%)] opacity-20" />
            </div>
            {/* Animated Background Elements */}
            <div className="absolute inset-0 overflow-hidden hidden sm:block">
              {/* Stars */}
              <div className="absolute inset-0">
                <div className="absolute top-[10%] left-[15%] w-1 h-1 bg-white rounded-full opacity-0 animate-[twinkle_3s_ease-in-out_infinite,fade-in_1s_ease-in-out_forwards]" />
                <div className="absolute top-[15%] right-[25%] w-1 h-1 bg-white rounded-full opacity-0 animate-[twinkle_3s_ease-in-out_infinite_0.5s,fade-in_1s_ease-in-out_forwards_0.2s]" />
                <div className="absolute top-[25%] left-[35%] w-1 h-1 bg-white rounded-full opacity-0 animate-[twinkle_3s_ease-in-out_infinite_1s,fade-in_1s_ease-in-out_forwards_0.4s]" />
                <div className="absolute top-[40%] right-[15%] w-1 h-1 bg-white rounded-full opacity-0 animate-[twinkle_3s_ease-in-out_infinite_1.5s,fade-in_1s_ease-in-out_forwards_0.6s]" />
                <div className="absolute top-[60%] left-[25%] w-1 h-1 bg-white rounded-full opacity-0 animate-[twinkle_3s_ease-in-out_infinite_2s,fade-in_1s_ease-in-out_forwards_0.8s]" />
                <div className="absolute top-[75%] right-[35%] w-1 h-1 bg-white rounded-full opacity-0 animate-[twinkle_3s_ease-in-out_infinite_2.5s,fade-in_1s_ease-in-out_forwards_1s]" />
                
                {/* Larger stars with glow */}
                <div className="absolute top-[20%] left-[45%] w-2 h-2 bg-indigo-400 rounded-full opacity-0 blur-[1px] animate-[twinkle_4s_ease-in-out_infinite_0.7s,fade-in_1s_ease-in-out_forwards_0.3s]" />
                <div className="absolute top-[50%] right-[40%] w-2 h-2 bg-purple-400 rounded-full opacity-0 blur-[1px] animate-[twinkle_4s_ease-in-out_infinite_1.7s,fade-in_1s_ease-in-out_forwards_0.7s]" />
                <div className="absolute top-[80%] left-[20%] w-2 h-2 bg-indigo-400 rounded-full opacity-0 blur-[1px] animate-[twinkle_4s_ease-in-out_infinite_2.7s,fade-in_1s_ease-in-out_forwards_1.1s]" />
              </div>

              {/* Floating Icons */}
              <div className="absolute top-[45%] right-[35%] opacity-0 animate-[float_6s_ease-in-out_infinite_1.1s,fade-in_1s_ease-in-out_forwards_0.9s]">
                <div className="w-12 h-12 rounded-xl bg-white/5 border border-white/10 backdrop-blur-sm flex items-center justify-center">
                  <DollarSign className="w-6 h-6 text-green-400/50" />
                </div>
              </div>
              <div className="absolute bottom-[40%] right-[25%] opacity-0 animate-[float_6s_ease-in-out_infinite_0.5s,fade-in_1s_ease-in-out_forwards_1.2s]">
                <div className="w-12 h-12 rounded-xl bg-white/5 border border-white/10 backdrop-blur-sm flex items-center justify-center">
                  <Star className="w-6 h-6 text-yellow-400/50" />
                </div>
              </div>
              <div className="absolute top-[35%] left-[30%] opacity-0 animate-[float_6s_ease-in-out_infinite_1.8s,fade-in_1s_ease-in-out_forwards_1.5s]">
                <div className="w-12 h-12 rounded-xl bg-white/5 border border-white/10 backdrop-blur-sm flex items-center justify-center">
                  <Trophy className="w-6 h-6 text-purple-400/50" />
                </div>
              </div>
            </div>
          </div>

          <div className="relative container mx-auto px-6">
            {/* Badge */}
            <div className="text-center mb-8">
              <div className="inline-block">
                <div className="relative px-6 py-2 bg-black/50 backdrop-blur-xl rounded-full border border-white/10 flex items-center gap-2">
                  <Briefcase className="w-4 h-4 text-indigo-400" />
                  <span className="text-sm font-medium bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
                    Galactic Investments
                  </span>
                </div>
              </div>
            </div>

            {/* Hero Content */}
            <div className="max-w-4xl mx-auto text-center">
              <h1 className="text-5xl md:text-7xl font-bold mb-8 leading-tight">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
                  We Fund and{' '}
            </span>
                <span className="relative inline-block">
              <span className="absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 blur-2xl opacity-20" />
              <span className="relative bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
                    Scale
              </span>
            </span>
                <span className="block mt-2 bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
                  YouTube Businesses.
                </span>
          </h1>

              <p className="text-xl text-gray-400 max-w-2xl mx-auto mb-12 leading-relaxed">
                Strategic investments, operational excellence, and full-stack support to scale your YouTube business beyond AdSense.
              </p>

              {/* Learn More */}
              <div 
                onClick={scrollToServices}
                className="flex flex-col items-center gap-3 mb-24 opacity-80 hover:opacity-100 transition-opacity duration-500 cursor-pointer"
              >
                <span className="text-sm font-medium text-gray-400">
                  Learn More
                </span>
                <div className="w-10 h-10 rounded-full bg-white/5 flex items-center justify-center group hover:bg-white/10 transition-all duration-500">
                  <ChevronDown className="w-6 h-6 text-gray-400 group-hover:translate-y-0.5 transition-transform duration-500" />
                </div>
              </div>
            </div>
        </div>
      </section>

        {/* Investment Criteria Section */}
        <section id="investment-criteria" className="relative py-32">
          <div className="container mx-auto px-4 sm:px-6">
            <div className="text-center mb-24">
              <h2 className="text-4xl md:text-5xl font-bold mb-6">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
                  Investment{' '}
                </span>
                <span className="relative inline-block">
                  <span className="absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 blur-2xl opacity-20" />
                  <span className="relative bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
                    Criteria
                  </span>
                </span>
              </h2>
              <p className="text-lg text-gray-400 max-w-2xl mx-auto">
                We invest in high-quality YouTube channels with proven track records and significant growth potential
              </p>
            </div>

            <div className="grid md:grid-cols-2 gap-8 sm:gap-12">
              {[
                {
                  title: "Proven Audience Growth & Market Demand",
                  icon: <TrendingUp className="w-6 h-6" />,
                  description: "We invest in channels with consistent audience growth and strong market positioning.",
                  metrics: [
                    "Minimum of 100,000+ subscribers",
                    "At least 12 months of operating history",
                    "Consistent monthly views and engagement",
                    "High-demand, evergreen niches",
                    "Demonstrated algorithm resilience"
                  ]
                },
                {
                  title: "Sustainable & Scalable Content Model",
                  icon: <LayoutGrid className="w-6 h-6" />,
                  description: "We look for channels that have efficient, repeatable content production processes that can scale.",
                  metrics: [
                    "Transferable content format",
                    "Cost-effective production",
                    "Evergreen content library",
                    "Consistent publishing workflow"
                  ]
                },
                {
                  title: "Established Revenue & Monetization",
                  icon: <DollarSign className="w-6 h-6" />,
                  description: "We acquire channels that generate consistent, diversified revenue and have clear paths for growth.",
                  metrics: [
                    "$60,000+ annual revenue minimum",
                    "Stable revenue sources",
                    "High-value CPM/RPM niches",
                    "Multiple monetization opportunities"
                  ]
                },
                {
                  title: "IP Ownership & Brand Safety",
                  icon: <Shield className="w-6 h-6" />,
                  description: "We prioritize brand-safe, monetizable content that is free of copyright risks.",
                  metrics: [
                    "Full IP ownership/rights",
                    "No copyright dependencies",
                    "Advertiser-friendly content",
                    "Strong audience trust"
                  ]
                }
              ].map((criteria, index) => (
                <div key={index} className={`relative group ${
                  index % 2 === 0 ? 'md:translate-y-12' : ''
                }`}>
                  <div className="absolute -inset-4 bg-gradient-to-b from-indigo-500/20 to-purple-500/20 rounded-3xl blur-2xl opacity-30 group-hover:opacity-40 transition-opacity duration-500" />
                  <div className="relative p-8 bg-black/50 backdrop-blur-sm rounded-2xl border border-white/10 hover:border-white/20 transition-colors">
                    <div className="absolute -top-6 left-1/2 transform -translate-x-1/2">
                      <div className="relative">
                        <div className="absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-xl blur-xl opacity-50" />
                        <div className="relative w-12 h-12 rounded-xl bg-black flex items-center justify-center border border-white/10">
                          {criteria.icon}
                        </div>
                      </div>
                    </div>
                    <div className="pt-6">
                      <h3 className="text-xl font-bold text-center mb-4">{criteria.title}</h3>
                      <p className="text-gray-400 text-center mb-6 text-sm">{criteria.description}</p>
                      <ul className="space-y-3">
                        {criteria.metrics.map((metric, i) => (
                          <li key={i} className="flex items-center gap-3 text-gray-400 group-hover:text-gray-300 transition-colors">
                            <div className="w-5 h-5 rounded-full bg-indigo-500/10 flex items-center justify-center flex-shrink-0">
                              <CheckCircle2 className="w-3 h-3 text-indigo-400" />
                            </div>
                            <span className="text-sm">{metric}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Exit Options Section */}
        <section className="relative py-32">
          <div className="container mx-auto px-6 relative">
            <div className="text-center mb-24">
              <h2 className="text-4xl md:text-5xl font-bold mb-6">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
                  Flexible{' '}
                </span>
                <span className="relative inline-block">
                  <span className="absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 blur-2xl opacity-20" />
                  <span className="relative bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
                    Deal Structures
                  </span>
                </span>
              </h2>
              <p className="text-lg text-gray-400 max-w-2xl mx-auto">
                Choose the exit strategy that aligns with your goals and timeline
              </p>
            </div>

            <div className="relative max-w-5xl mx-auto">
              <div className="grid md:grid-cols-3 gap-8">
                {exitOptions.map((option, index) => (
                  <div 
                    key={index} 
                    className={`relative group transform ${
                      index === 1 ? 'md:translate-y-12' : index === 2 ? 'md:translate-y-24' : ''
                    }`}
                  >
                    <div className="absolute -inset-px bg-gradient-to-b from-indigo-500/20 to-purple-500/20 rounded-xl blur-sm transition-all group-hover:blur-md" />
                    <div className="relative p-8 bg-black/50 backdrop-blur-sm rounded-xl border border-white/10 hover:border-white/20 transition-colors">
                      <div className="absolute -top-6 left-1/2 transform -translate-x-1/2">
                        <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-indigo-500/10 border border-indigo-500/20 text-2xl font-bold text-indigo-400">
                          {index + 1}
                        </div>
                      </div>
                      <div className="pt-6">
                        <h3 className="text-2xl font-bold text-center mb-4">{option.title}</h3>
                        <p className="text-gray-400 text-center mb-6">{option.description}</p>
                        <ul className="space-y-3">
                          {option.features.map((feature, featureIndex) => (
                            <li key={featureIndex} className="flex items-center gap-2 text-gray-400">
                              <CheckCircle2 className="w-5 h-5 text-indigo-400" />
                              <span>{feature}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="relative py-32">
          <div className="container mx-auto px-6">
            <div className="relative max-w-4xl mx-auto">
              <div className="absolute -inset-4 bg-gradient-to-r from-indigo-500/30 to-purple-500/30 rounded-3xl blur-2xl opacity-50" />
              <div className="absolute inset-0 bg-gradient-to-r from-indigo-500/10 to-purple-500/10 rounded-2xl transform rotate-1" />
              <div className="absolute inset-0 bg-gradient-to-r from-purple-500/10 to-indigo-500/10 rounded-2xl transform -rotate-1" />
              <div className="relative bg-black/40 backdrop-blur-xl rounded-2xl p-12 border border-white/10">
                <div className="relative z-10 text-center">
                  <h2 className="text-4xl md:text-5xl font-bold mb-6">
                    Ready for an Investment?{' '}
                    <span className="relative inline-block">
                      <span className="absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 blur-2xl opacity-20" />
                      <span className="relative bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">

                      </span>
                    </span>
                  </h2>
                  <p className="text-lg text-gray-400 max-w-2xl mx-auto mb-8">
                    Let's discuss how we can help you reach your channel's full potential through strategic investment and operational support.
                  </p>
                  <Link to="/contact" className="group relative inline-flex items-center gap-2">
                    <div className="absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-xl blur-xl opacity-20 group-hover:opacity-40 transition-opacity duration-500" />
                    <div className="relative inline-flex items-center gap-2 px-8 py-4 bg-gradient-to-r from-indigo-500/10 to-purple-500/10 rounded-xl border border-white/10 font-medium text-white hover:border-white/20 transition-colors duration-500">
                      <span>Contact Us</span>
                      <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform duration-300" />
                    </div>
                  </Link>
                </div>
              </div>
          </div>
        </div>
      </section>
      </div>
    </div>
  );
}