import { Rocket, Heart, Globe, Zap, ArrowRight, Building2, Users, Sparkles, ChevronDown, ChevronUp, X, Video, Mail, FileText, Brain, Trophy, Gauge, Scale, Target, Handshake, LucideIcon } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect, ReactElement } from 'react';
import { ContractorApplicationForm } from '../components/ContractorApplicationForm';

interface ValueItem {
  Icon: LucideIcon;
  title: string;
  description: string;
}

interface BenefitItem {
  Icon: LucideIcon;
  title: string;
  description: string;
}

export default function CareersPage() {
  const [expandedPosition, setExpandedPosition] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const positions = [
    // Contract Positions
    {
      title: "Scriptwriter (Documentaries)",
      department: "Content",
      location: "Remote / Atlanta, GA (Preferred)",
      type: "Contract",
      overview: "The Scriptwriter will be responsible for crafting compelling documentary scripts that engage audiences while maintaining factual accuracy and narrative flow. This role requires strong research skills, storytelling ability, and a passion for documentary filmmaking.",
      responsibilities: [
        "Research and develop documentary scripts with compelling narratives",
        "Collaborate with producers and directors to shape story arcs",
        "Write clear, engaging scripts that maintain factual accuracy",
        "Adapt scripts based on production needs and feedback",
        "Work with subject matter experts to ensure content accuracy",
        "Develop treatments and outlines for new documentary projects",
        "Stay current with documentary trends and storytelling techniques"
      ],
      qualifications: [
        "2+ years of experience in documentary scriptwriting or related field",
        "Strong research and fact-checking skills",
        "Excellent storytelling and narrative structure abilities",
        "Experience working with documentary production teams",
        "Ability to work under tight deadlines and adapt to feedback",
        "Portfolio of previous documentary writing work"
      ],
      compensation: [
        "Competitive project-based rates",
        "Flexible working hours",
        "Remote work opportunities",
        "Access to research resources and industry events"
      ]
    },
    {
      title: "2D Animator / Motion Designer",
      department: "Animation",
      location: "Remote / Atlanta, GA (Preferred)",
      type: "Contract",
      overview: "The 2D Animator/Motion Designer will create engaging animations and motion graphics for our YouTube content. This role requires strong animation skills, creativity, and the ability to work within brand guidelines while pushing creative boundaries.",
      responsibilities: [
        "Create 2D animations and motion graphics for YouTube content",
        "Develop animation styles and maintain consistency across projects",
        "Collaborate with content creators and editors to enhance storytelling",
        "Design and animate lower thirds, transitions, and visual effects",
        "Work with the art team to maintain brand consistency",
        "Optimize animations for different platforms and formats",
        "Stay current with animation trends and techniques"
      ],
      qualifications: [
        "2+ years of experience in 2D animation and motion design",
        "Proficiency in Adobe After Effects, Illustrator, and Photoshop",
        "Strong understanding of animation principles and timing",
        "Experience with character animation and motion graphics",
        "Ability to work within brand guidelines while being creative",
        "Portfolio demonstrating animation and motion design skills"
      ],
      compensation: [
        "Competitive project-based rates",
        "Flexible working hours",
        "Remote work opportunities",
        "Access to professional animation software and tools"
      ]
    },
    {
      title: "3D Animator",
      department: "Animation",
      location: "Remote / Atlanta, GA (Preferred)",
      type: "Contract",
      overview: "The 3D Animator will create high-quality 3D animations and visual effects for our content. This role requires expertise in 3D modeling, animation, and rendering, with a focus on creating engaging visual content for YouTube.",
      responsibilities: [
        "Create 3D models, animations, and visual effects",
        "Develop and maintain 3D assets and environments",
        "Collaborate with the creative team to enhance storytelling",
        "Optimize 3D assets for different platforms and formats",
        "Work with lighting and rendering to achieve desired visual style",
        "Stay current with 3D animation trends and techniques",
        "Participate in creative brainstorming sessions"
      ],
      qualifications: [
        "2+ years of experience in 3D animation and modeling",
        "Proficiency in Blender, Maya, or Cinema 4D",
        "Strong understanding of 3D animation principles",
        "Experience with texturing, lighting, and rendering",
        "Ability to work within technical constraints",
        "Portfolio demonstrating 3D animation skills"
      ],
      compensation: [
        "Competitive project-based rates",
        "Flexible working hours",
        "Remote work opportunities",
        "Access to professional 3D software and tools"
      ]
    },
    {
      title: "Video Editor",
      department: "Production",
      location: "Remote / Atlanta, GA (Preferred)",
      type: "Contract",
      overview: "The Video Editor will be responsible for editing and post-producing content for our YouTube channels. This role requires strong editing skills, creativity, and the ability to work efficiently while maintaining high production standards.",
      responsibilities: [
        "Edit and assemble raw footage into polished videos",
        "Add graphics, music, and sound effects to enhance content",
        "Color correct and grade footage for consistent look",
        "Collaborate with content creators and producers",
        "Optimize videos for different platforms and formats",
        "Maintain organized project files and assets",
        "Stay current with editing trends and techniques"
      ],
      qualifications: [
        "2+ years of experience in video editing",
        "Proficiency in Adobe Premiere Pro and After Effects",
        "Strong understanding of editing principles and storytelling",
        "Experience with color grading and audio mixing",
        "Ability to work under tight deadlines",
        "Portfolio demonstrating editing skills"
      ],
      compensation: [
        "Competitive project-based rates",
        "Flexible working hours",
        "Remote work opportunities",
        "Access to professional editing software and tools"
      ]
    },
    {
      title: "Audio Engineer / Sound Designer",
      department: "Production",
      location: "Remote / Atlanta, GA (Preferred)",
      type: "Contract",
      overview: "The Audio Engineer/Sound Designer will be responsible for creating high-quality audio content and sound design for our videos. This role requires expertise in audio production, mixing, and sound design, with a focus on creating engaging audio experiences.",
      responsibilities: [
        "Record, edit, and mix audio for video content",
        "Create and implement sound effects and music",
        "Design immersive audio experiences for content",
        "Collaborate with video editors and producers",
        "Maintain audio quality standards across all content",
        "Stay current with audio production trends and techniques",
        "Manage audio assets and libraries"
      ],
      qualifications: [
        "2+ years of experience in audio engineering and sound design",
        "Proficiency in Pro Tools, Logic Pro, or similar DAWs",
        "Strong understanding of audio principles and mixing",
        "Experience with field recording and sound design",
        "Ability to work under tight deadlines",
        "Portfolio demonstrating audio production skills"
      ],
      compensation: [
        "Competitive project-based rates",
        "Flexible working hours",
        "Remote work opportunities",
        "Access to professional audio equipment and software"
      ]
    },
    {
      title: "Thumbnail Artist",
      department: "Design",
      location: "Remote / Atlanta, GA (Preferred)",
      type: "Contract",
      overview: "The Thumbnail Artist will create eye-catching thumbnails that drive engagement and click-through rates. This role requires strong design skills, understanding of YouTube trends, and the ability to create thumbnails that stand out in a crowded space.",
      responsibilities: [
        "Design compelling thumbnails for YouTube content",
        "Create consistent thumbnail styles across channels",
        "Optimize thumbnails for different platforms and devices",
        "Collaborate with content creators and marketing team",
        "Stay current with thumbnail design trends",
        "Analyze thumbnail performance and make data-driven improvements",
        "Maintain organized design assets and templates"
      ],
      qualifications: [
        "2+ years of experience in graphic design",
        "Proficiency in Adobe Photoshop and Illustrator",
        "Strong understanding of visual hierarchy and composition",
        "Experience with YouTube thumbnail design",
        "Ability to work under tight deadlines",
        "Portfolio demonstrating thumbnail design skills"
      ],
      compensation: [
        "Competitive project-based rates",
        "Flexible working hours",
        "Remote work opportunities",
        "Access to professional design software and tools"
      ]
    },
    {
      title: "Graphic Designer",
      department: "Design",
      location: "Remote / Atlanta, GA (Preferred)",
      type: "Contract",
      overview: "The Graphic Designer will create visual assets for our content and brand. This role requires strong design skills, creativity, and the ability to work within brand guidelines while pushing creative boundaries.",
      responsibilities: [
        "Create visual assets for content and marketing",
        "Develop and maintain brand guidelines",
        "Design graphics for social media and promotional materials",
        "Collaborate with content creators and marketing team",
        "Create motion graphics and animated elements",
        "Stay current with design trends and techniques",
        "Maintain organized design assets and templates"
      ],
      qualifications: [
        "2+ years of experience in graphic design",
        "Proficiency in Adobe Creative Suite",
        "Strong understanding of design principles",
        "Experience with motion graphics and animation",
        "Ability to work within brand guidelines",
        "Portfolio demonstrating design skills"
      ],
      compensation: [
        "Competitive project-based rates",
        "Flexible working hours",
        "Remote work opportunities",
        "Access to professional design software and tools"
      ]
    },
    {
      title: "Content Strategist",
      department: "Content",
      location: "Remote / Atlanta, GA (Preferred)",
      type: "Contract",
      overview: "The Content Strategist will develop and implement content strategies that drive growth and engagement. This role requires strong analytical skills, creativity, and the ability to identify trends and opportunities in the digital space.",
      responsibilities: [
        "Develop content strategies for YouTube channels",
        "Analyze performance data and make strategic recommendations",
        "Identify content trends and opportunities",
        "Collaborate with content creators and production team",
        "Create content calendars and planning documents",
        "Stay current with platform trends and algorithm changes",
        "Optimize content for different platforms and audiences"
      ],
      qualifications: [
        "2+ years of experience in content strategy",
        "Strong analytical and research skills",
        "Understanding of YouTube algorithm and content trends",
        "Experience with content analytics tools",
        "Ability to work with cross-functional teams",
        "Portfolio demonstrating strategic thinking"
      ],
      compensation: [
        "Competitive project-based rates",
        "Flexible working hours",
        "Remote work opportunities",
        "Access to analytics tools and resources"
      ]
    },
    // Full-time Positions
    {
      title: "Talent Partnerships Manager",
      department: "Partnerships",
      location: "Remote / Atlanta, GA (Preferred)",
      type: "Full-time",
      overview: "The Talent Partnerships Manager will be responsible for securing brand sponsorships and monetization opportunities for Galactic Media's roster of YouTube creators. This role requires strong relationship-building skills, negotiation expertise, and a deep understanding of YouTube sponsorships, influencer marketing, and brand deals.",
      responsibilities: [
        "Identify and secure brand sponsorships and long-term partnership opportunities for creators",
        "Build and maintain relationships with brands, agencies, and marketing teams looking to advertise on YouTube",
        "Develop sponsorship strategies that align with creator content and audience engagement",
        "Negotiate deal terms, pricing, and deliverables to maximize creator revenue",
        "Track sponsorship performance and ensure successful execution of brand campaigns",
        "Work closely with the production and creator management teams to ensure content integration aligns with brand goals",
        "Keep up with industry trends, emerging brands, and new monetization strategies in digital media"
      ],
      qualifications: [
        "2+ years of experience in influencer marketing, talent management, brand partnerships, or sponsorship sales",
        "Strong negotiation and communication skills with experience closing deals",
        "Existing relationships with brands and agencies in the digital space is a plus",
        "Deep understanding of the YouTube ecosystem and monetization strategies",
        "Self-starter with the ability to source and close sponsorship deals independently"
      ],
      compensation: [
        "Competitive salary + commission structure based on deals closed",
        "Performance-based bonuses and revenue-sharing opportunities",
        "Flexible remote work policy with occasional travel for brand meetings and industry events"
      ]
    },
    {
      title: "Production Coordinator",
      department: "Production",
      location: "Remote / Atlanta, GA (Preferred)",
      type: "Full-time",
      overview: "The Production Coordinator will manage and optimize the content production workflow for Galactic Media's YouTube creators. This role is crucial in ensuring consistent, high-quality content output, overseeing video editing, scripting, and post-production processes.",
      responsibilities: [
        "Oversee day-to-day content production for YouTube channels under Galactic Media",
        "Manage a network of video editors, scriptwriters, and creative professionals",
        "Optimize production workflows to increase efficiency and scale output",
        "Ensure videos meet quality standards, brand guidelines, and creator expectations",
        "Stay up to date with YouTube content trends, storytelling techniques, and algorithm changes",
        "Collaborate with the Talent Partnerships Manager to integrate brand deals seamlessly",
        "Manage production budgets, vendor relationships, and content calendars"
      ],
      qualifications: [
        "2+ years of experience in video production, content management, or media operations",
        "Strong understanding of YouTube best practices, storytelling, and video editing workflows",
        "Experience managing creative teams and freelancers",
        "Ability to scale production while maintaining high content quality",
        "Proficiency in tools like Adobe Premiere, Final Cut Pro, and project management software"
      ],
      compensation: [
        "Competitive salary + performance-based incentives",
        "Access to top-tier production tools and creative resources",
        "Work with a dynamic team shaping the future of YouTube media"
      ]
    },
    {
      title: "Investments & Acquisitions Associate",
      department: "Investments",
      location: "Remote / Atlanta, GA (Preferred)",
      type: "Full-time",
      overview: "The Investments & Acquisitions Associate will play a key role in identifying, evaluating, and acquiring YouTube channels as part of Galactic Media's investment strategy. This role requires strong financial modeling, due diligence, and deal-making skills.",
      responsibilities: [
        "Identify high-potential YouTube channels for acquisition and investment",
        "Conduct financial analysis, revenue modeling, and valuation assessments for digital assets",
        "Develop investment theses for scaling and optimizing acquired channels",
        "Work closely with legal teams to structure acquisition deals",
        "Research market trends, competitors, and growth opportunities in digital media",
        "Collaborate with the Production Coordinator to optimize channel growth post-acquisition",
        "Track the financial performance of acquired channels and investment returns"
      ],
      qualifications: [
        "2+ years of experience in investment banking, private equity, venture capital, or digital acquisitions",
        "Strong analytical skills with experience in financial modeling and valuation",
        "Familiarity with YouTube analytics and monetization models is a plus",
        "Experience in deal structuring, due diligence, and contract negotiations",
        "Interest in digital media, M&A, and content business models"
      ],
      compensation: [
        "Competitive base salary + performance-based bonuses tied to acquisitions",
        "Potential for equity or revenue-sharing opportunities",
        "Exposure to a high-growth industry with significant deal flow"
      ]
    },
    {
      title: "Operations Coordinator",
      department: "Operations",
      location: "Remote / Atlanta, GA (Preferred)",
      type: "Full-time",
      overview: "The Operations Coordinator will be responsible for keeping Galactic Media running smoothly by managing internal processes, finances, hiring, and administrative operations. This role is critical in ensuring efficiency as the company scales.",
      responsibilities: [
        "Oversee day-to-day operations across the company's Talent, Production, and Investment divisions",
        "Manage hiring processes, onboarding, and team coordination",
        "Support financial planning, budgeting, and tracking company-wide expenses",
        "Implement internal tools, systems, and workflow automation for better efficiency",
        "Work with leadership to set operational goals and track performance",
        "Ensure compliance with contracts, partnerships, and legal agreements"
      ],
      qualifications: [
        "2+ years of experience in business operations, project management, or startup environments",
        "Strong organizational and problem-solving skills",
        "Ability to streamline processes and manage multiple moving parts",
        "Comfortable with financial tracking, budgeting, and basic HR functions",
        "Experience with project management tools (Asana, Notion, or Trello) is a plus"
      ],
      compensation: [
        "Competitive salary + growth-based performance incentives",
        "Opportunity to work alongside leadership in a high-growth company",
        "Flexible work environment with career growth potential"
      ]
    }
  ];

  const values = [
    {
      icon: <Brain className="w-6 h-6 text-indigo-400" />,
      title: "Ownership Mentality",
      description: "We take full responsibility for our work and outcomes.",
      points: [
        "Everyone at Galactic is expected to think like an owner, not just an employee",
        "We don't wait for permission—we solve problems, take initiative, and drive results",
        "Mediocrity isn't acceptable; we hold ourselves to the highest standard"
      ],
      gradient: "from-indigo-500/50 via-indigo-500/5 to-transparent",
      iconGradient: "from-indigo-500 to-purple-500",
      iconColor: "text-indigo-400"
    },
    {
      icon: <Trophy className="w-6 h-6 text-violet-400" />,
      title: "Results Over Credentials",
      description: "We care about what you can do, not where you went to school.",
      points: [
        "Talent, drive, and execution matter more than degrees or resumes",
        "We value real-world experience, creativity, and a strong work ethic",
        "The best ideas win—hierarchy doesn't determine who has the right answer"
      ],
      gradient: "from-violet-500/50 via-violet-500/5 to-transparent",
      iconGradient: "from-violet-500 to-purple-500",
      iconColor: "text-violet-400"
    },
    {
      icon: <Gauge className="w-6 h-6 text-blue-400" />,
      title: "Speed & Adaptability",
      description: "Move fast, iterate, and stay ahead of the curve.",
      points: [
        "The digital media landscape moves quickly—we move faster",
        "We test, learn, and adjust in real-time to maximize growth",
        "Perfectionism kills progress—we prioritize execution and iteration"
      ],
      gradient: "from-blue-500/50 via-blue-500/5 to-transparent",
      iconGradient: "from-blue-500 to-indigo-500",
      iconColor: "text-blue-400"
    },
    {
      icon: <Scale className="w-6 h-6 text-fuchsia-400" />,
      title: "Build for Scale",
      description: "Everything we do should be built to grow.",
      points: [
        "We aren't just making content—we're building scalable digital assets",
        "Systems, processes, and strategies should be repeatable and efficient",
        "We think long-term, creating businesses that last beyond trends"
      ],
      gradient: "from-fuchsia-500/50 via-fuchsia-500/5 to-transparent",
      iconGradient: "from-fuchsia-500 to-purple-500",
      iconColor: "text-fuchsia-400"
    },
    {
      icon: <Target className="w-6 h-6 text-purple-400" />,
      title: "No Bureaucracy, Just Execution",
      description: "Keep it lean, keep it efficient, get it done.",
      points: [
        "We cut through unnecessary processes and focus on getting real work done",
        "No pointless meetings, no micromanagement—just impact",
        "If something is slowing us down, we fix it or eliminate it"
      ],
      gradient: "from-purple-500/50 via-purple-500/5 to-transparent",
      iconGradient: "from-purple-500 to-indigo-500",
      iconColor: "text-purple-400"
    },
    {
      icon: <Handshake className="w-6 h-6 text-pink-400" />,
      title: "Win Together",
      description: "Collaboration makes us stronger.",
      points: [
        "We believe in fair deals and long-term relationships with creators, brands, and investors",
        "Success isn't a zero-sum game—we grow by helping others succeed",
        "We respect and support each other, but we also push each other to be great"
      ],
      gradient: "from-pink-500/50 via-pink-500/5 to-transparent",
      iconGradient: "from-pink-500 to-purple-500",
      iconColor: "text-pink-400"
    }
  ];

  const benefits = [
    {
      title: "Competitive Package",
      items: [
        "Industry-leading salary",
        "Equity options",
        "Performance bonuses",
        "401(k) matching"
      ]
    },
    {
      title: "Health & Wellness",
      items: [
        "Premium health coverage",
        "Mental health support",
        "Fitness allowance",
        "Wellness programs"
      ]
    },
    {
      title: "Growth & Development",
      items: [
        "Learning budget",
        "Conference attendance",
        "Mentorship program",
        "Career coaching"
      ]
    },
    {
      title: "Work-Life Balance",
      items: [
        "Flexible hours",
        "Remote work options",
        "Unlimited PTO",
        "Paid sabbatical"
      ]
    },
    {
      title: "Office Perks",
      items: [
        "State-of-the-art workspace",
        "Catered lunches",
        "Team events",
        "Latest tech equipment"
      ]
    },
    {
      title: "Family Benefits",
      items: [
        "Parental leave",
        "Fertility coverage",
        "Childcare support",
        "Family health insurance"
      ]
    }
  ];

  const ApplicationModal = () => {
    // Add effect to prevent background scrolling when modal is open
    useEffect(() => {
      if (isModalOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
      return () => {
        document.body.style.overflow = 'unset';
      };
    }, [isModalOpen]);

    return (
      <div className="fixed inset-0 z-50 flex items-start sm:items-center justify-center overflow-y-auto">
        {/* Backdrop */}
        <div 
          className="fixed inset-0 bg-black/80 backdrop-blur-sm"
          onClick={() => setIsModalOpen(false)}
        />
        
        {/* Modal Container */}
        <div className="relative w-full max-w-3xl my-4 sm:my-8 mx-4 sm:mx-auto">
          {/* Modal Content */}
          <div className="relative bg-black/90 rounded-3xl border border-white/10 overflow-hidden">
            {/* Gradient Overlay */}
            <div className="absolute inset-0">
              <div className="absolute inset-0 bg-gradient-to-br from-indigo-500/10 via-transparent to-purple-500/10" />
            </div>

            {/* Content */}
            <div className="relative p-4 sm:p-6 md:p-8 lg:p-12">
              {/* Close Button */}
              <button 
                onClick={() => setIsModalOpen(false)}
                className="absolute top-2 right-2 sm:top-4 sm:right-4 p-2 rounded-full bg-white/5 hover:bg-white/10 transition-colors"
              >
                <X className="w-4 h-4 sm:w-5 sm:h-5" />
              </button>

              {/* Title */}
              <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-6">
                How to Apply to{' '}
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
                  Galactic Media
                </span>
              </h2>

              {/* Introduction */}
              <div className="mb-8">
                <p className="text-xl font-bold text-white mb-4">
                  We don't do resumes. We don't do cover letters. We don't care about your degree.
                </p>
                <p className="text-gray-400 text-lg">
                  At Galactic Media, we're looking for hardworking, smart, talented, and driven people—not just a list of credentials. If that's you, here's how to apply:
                </p>
              </div>

              {/* Steps */}
              <div className="space-y-8">
                {/* Step 1 */}
                <div>
                  <h3 className="text-xl font-bold mb-4 flex items-center gap-3">
                    <span>Step 1: Record a Short Video</span>
                    <Video className="w-5 h-5 text-indigo-400" />
                  </h3>
                  <p className="text-gray-400 mb-4">
                    🎥 Create a 1-2 minute video introduction where you tell us:
                  </p>
                  <ul className="space-y-2 text-gray-400 ml-6">
                    <li className="list-disc">Who you are and what you do best.</li>
                    <li className="list-disc">Why you're excited about Galactic Media.</li>
                    <li className="list-disc">What unique skills or experiences make you a great fit.</li>
                  </ul>
                  <p className="text-gray-500 italic mt-4">
                    (You can upload your video as an unlisted YouTube video or record it on Loom—whichever is easiest for you.)
                  </p>
                </div>

                {/* Step 2 */}
                <div>
                  <h3 className="text-xl font-bold mb-4 flex items-center gap-3">
                    <span>Step 2: Write a Quick Summary</span>
                    <FileText className="w-5 h-5 text-indigo-400" />
                  </h3>
                  <p className="text-gray-400 mb-4">
                    📝 In your email, include a brief summary of your career accomplishments.
                  </p>
                  <ul className="space-y-2 text-gray-400 ml-6">
                    <li className="list-disc">Keep it short and to the point—no need for a formal resume.</li>
                    <li className="list-disc">Tell us what you've worked on, what you've built, and how you've made an impact.</li>
                  </ul>
                </div>

                {/* Step 3 */}
                <div>
                  <h3 className="text-xl font-bold mb-4 flex items-center gap-3">
                    <span>Step 3: Send It In</span>
                    <Mail className="w-5 h-5 text-indigo-400" />
                  </h3>
                  <p className="text-gray-400">
                    📧 Email your video + summary to{' '}
                    <a 
                      href="mailto:careers@galacticmedia.tv"
                      className="text-indigo-400 hover:text-indigo-300 transition-colors"
                    >
                      careers@galacticmedia.tv
                    </a>
                  </p>
                </div>
              </div>

              {/* Footer */}
              <div className="mt-8 pt-6 border-t border-white/10">
                <p className="text-gray-400">
                  That's it. If we think you're a great fit, we'll reach out. No fluff, no red tape—just real opportunities for real talent.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderValues = () => (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
      {values.map((value, index) => (
        <div key={index} className="group relative">
          <div className={`absolute -inset-px rounded-3xl bg-gradient-to-b ${value.gradient} opacity-0 group-hover:opacity-100 transition-opacity duration-500`} />
          <div className="relative h-full bg-black/40 backdrop-blur-xl rounded-3xl p-6 sm:p-8 border border-white/10">
            <div className="mb-6 flex items-center gap-4">
              <div className="relative flex-shrink-0">
                <div className={`absolute inset-0 bg-gradient-to-r ${value.iconGradient} rounded-xl blur-xl opacity-0 group-hover:opacity-20 transition-opacity duration-500`} />
                <div className="relative flex items-center justify-center w-10 sm:w-12 h-10 sm:h-12 rounded-xl bg-gradient-to-br from-indigo-500/10 to-purple-500/10 border border-white/10">
                  <div className={value.iconColor}>
                    {value.icon}
                  </div>
                </div>
              </div>
              <h3 className="text-lg sm:text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white to-white/70">
                {value.title}
              </h3>
            </div>
            <p className="text-gray-400 mb-6 text-sm sm:text-base">
              {value.description}
            </p>
            <ul className="space-y-2 sm:space-y-3">
              {value.points.map((point, i) => (
                <li key={i} className="flex items-start gap-3 text-sm sm:text-base text-gray-400 group-hover:text-gray-300 transition-colors duration-500">
                  <div className="w-5 h-5 rounded-full bg-indigo-500/10 flex items-center justify-center flex-shrink-0 mt-1">
                    <Sparkles className="w-3 h-3 text-indigo-400" />
                  </div>
                  <span>{point}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );

  const renderBenefits = () => (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
      {benefits.map((benefit, index) => (
        <div key={index} className="group relative">
          <div className="absolute -inset-px bg-gradient-to-b from-indigo-500/20 to-purple-500/20 rounded-xl blur-sm" />
          <div className="relative p-4 sm:p-6 bg-black/30 backdrop-blur-sm rounded-xl border border-white/10">
            <h3 className="text-lg sm:text-xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-white to-white/70">
              {benefit.title}
            </h3>
            <ul className="space-y-2 sm:space-y-3">
              {benefit.items.map((item, i) => (
                <li key={i} className="flex items-center gap-3 text-sm sm:text-base text-gray-400 group-hover:text-gray-300 transition-colors duration-500">
                  <div className="w-5 h-5 rounded-full bg-indigo-500/10 flex items-center justify-center flex-shrink-0">
                    <Sparkles className="w-3 h-3 text-indigo-400" />
                  </div>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="relative min-h-screen">
      {/* Background with same aesthetic */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-black">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_60%_-20%,#4338ca,transparent_50%)]" />
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_150%,#6366f1,transparent_50%)]" />
        </div>
        <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-10" />
      </div>

      {/* Hero Section */}
      <section className="relative py-16 sm:py-20 md:py-24">
        {/* Animated Background */}
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-black">
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_60%_-20%,#4338ca,transparent_25%)]" />
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_150%,#6366f1,transparent_25%)]" />
          </div>
          <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-5" />
          {/* Floating Orbs */}
          <div className="absolute -top-24 left-1/4 w-48 sm:w-64 h-48 sm:h-64 bg-indigo-500/10 rounded-full blur-3xl" />
          <div className="absolute -bottom-24 right-1/4 w-48 sm:w-64 h-48 sm:h-64 bg-purple-500/10 rounded-full blur-3xl" />
        </div>

        <div className="relative container mx-auto px-4 sm:px-6">
          {/* Badge */}
          <div className="mb-6 sm:mb-8">
            <div className="relative inline-block">
              <div className="absolute -inset-1 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-full blur opacity-30" />
              <div className="relative px-4 sm:px-6 py-1.5 sm:py-2 bg-black/50 backdrop-blur-xl rounded-full border border-white/10 flex items-center gap-2">
                <Users className="w-3.5 sm:w-4 h-3.5 sm:h-4 text-indigo-400" />
                <span className="text-xs sm:text-sm font-medium bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
                  Join Our Team
                </span>
              </div>
            </div>
          </div>

          {/* Hero Content */}
          <div className="max-w-3xl">
            <h1 className="relative text-3xl sm:text-4xl md:text-5xl font-bold mb-4 sm:mb-6">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
                Join Our{' '}
              </span>
              <span className="relative inline-block">
                <span className="absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 blur-2xl opacity-20" />
                <span className="relative bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
                  Mission
                </span>
              </span>
            </h1>
            
            <p className="text-base sm:text-lg text-gray-400 max-w-2xl mb-6 sm:mb-8 leading-relaxed">
              Build the future of digital entertainment with a team of passionate innovators.
            </p>

            <div className="flex flex-col sm:flex-row gap-3 sm:gap-4">
              <button
                onClick={() => {
                  document.getElementById('positions')?.scrollIntoView({ behavior: 'smooth' });
                }}
                className="group relative inline-flex items-center justify-center gap-2 w-full sm:w-auto"
              >
                <div className="absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-xl blur-xl opacity-20 group-hover:opacity-40 transition-opacity duration-500" />
                <div className="relative inline-flex items-center justify-center gap-2 px-6 sm:px-8 py-3 sm:py-4 bg-gradient-to-r from-indigo-500/10 to-purple-500/10 rounded-xl border border-white/10 font-medium text-white hover:border-white/20 transition-colors duration-500 w-full sm:w-auto">
                  <span>View Open Positions</span>
                  <div className="w-6 sm:w-8 h-6 sm:h-8 rounded-full bg-white/5 flex items-center justify-center group-hover:bg-white/10 transition-colors duration-500">
                    <ArrowRight className="w-3 sm:w-4 h-3 sm:h-4" />
                  </div>
                </div>
              </button>
              <Link
                to="/about"
                className="group relative inline-flex items-center justify-center gap-2 w-full sm:w-auto"
              >
                <div className="absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-xl blur-xl opacity-0 group-hover:opacity-20 transition-opacity duration-500" />
                <div className="relative inline-flex items-center justify-center gap-2 px-6 sm:px-8 py-3 sm:py-4 bg-black/20 backdrop-blur-sm rounded-xl border border-white/10 font-medium text-white hover:border-white/20 transition-colors duration-500 w-full sm:w-auto">
                  <Users className="w-3.5 sm:w-4 h-3.5 sm:h-4" />
                  <span>Meet the Team</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Values Section */}
      <section className="relative py-16 sm:py-20 md:py-24">
        <div className="container mx-auto px-4 sm:px-6">
          <div className="max-w-3xl mx-auto text-center mb-12 sm:mb-16">
            <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4 sm:mb-6">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
                Our Values
              </span>
            </h2>
            <p className="text-base sm:text-lg text-gray-400">
              The principles that guide everything we do
            </p>
          </div>

          {renderValues()}
        </div>
      </section>

      {/* Benefits Section */}
      <section className="relative py-16 sm:py-20 md:py-24 bg-black/20">
        <div className="container mx-auto px-4 sm:px-6">
          <div className="max-w-3xl mx-auto text-center mb-12 sm:mb-16">
            <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4 sm:mb-6">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
                Benefits & Perks
              </span>
            </h2>
            <p className="text-base sm:text-lg text-gray-400">
              We take care of our team so they can focus on what matters
            </p>
          </div>

          {renderBenefits()}
        </div>
      </section>

      {/* Open Positions */}
      <section id="positions" className="relative py-16 sm:py-20 md:py-24">
        <div className="container mx-auto px-4 sm:px-6">
          <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold text-center mb-8 sm:mb-12 md:mb-16">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
              Open{' '}
            </span>
            <span className="relative inline-block">
              <span className="absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 blur-2xl opacity-20" />
              <span className="relative bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
                Positions
              </span>
            </span>
          </h2>

          <div className="grid gap-4 sm:gap-6">
            {positions.map((position, index) => (
              <div key={index} className="group relative">
                <div className="absolute -inset-px rounded-2xl sm:rounded-3xl bg-gradient-to-b from-indigo-500/50 via-indigo-500/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
                <div className="relative bg-black/40 backdrop-blur-xl rounded-2xl sm:rounded-3xl p-4 sm:p-6 md:p-8 border border-white/10">
                  <div className="flex flex-col gap-4 sm:gap-6">
                    <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 sm:gap-6">
                      <div>
                        <h3 className="text-lg sm:text-xl font-bold mb-2 sm:mb-3 bg-clip-text text-transparent bg-gradient-to-r from-white to-white/70">
                          {position.title}
                        </h3>
                        <div className="flex flex-wrap gap-2 sm:gap-4">
                          <span className="flex items-center text-xs sm:text-sm text-gray-400 group-hover:text-gray-300 transition-colors duration-500">
                            <Building2 className="w-3.5 sm:w-4 h-3.5 sm:h-4 mr-1.5 sm:mr-2 text-indigo-400" />
                            {position.department}
                          </span>
                          <span className="flex items-center text-xs sm:text-sm text-gray-400 group-hover:text-gray-300 transition-colors duration-500">
                            <Globe className="w-3.5 sm:w-4 h-3.5 sm:h-4 mr-1.5 sm:mr-2 text-indigo-400" />
                            {position.location}
                          </span>
                          <span className="flex items-center text-xs sm:text-sm text-gray-400 group-hover:text-gray-300 transition-colors duration-500">
                            <Users className="w-3.5 sm:w-4 h-3.5 sm:h-4 mr-1.5 sm:mr-2 text-indigo-400" />
                            {position.type}
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col sm:flex-row items-stretch sm:items-center gap-2 sm:gap-4">
                        <button
                          onClick={() => setExpandedPosition(expandedPosition === index ? null : index)}
                          className="group relative inline-flex items-center justify-center gap-2 w-full sm:w-auto"
                        >
                          <div className="absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-xl blur-xl opacity-0 group-hover:opacity-20 transition-opacity duration-500" />
                          <div className="relative inline-flex items-center justify-center gap-2 px-4 sm:px-6 py-2 sm:py-3 bg-gradient-to-r from-indigo-500/10 to-purple-500/10 rounded-xl border border-white/10 font-medium text-white hover:border-white/20 transition-colors duration-500 w-full sm:w-auto">
                            <span>{expandedPosition === index ? 'View Less' : 'View More'}</span>
                            {expandedPosition === index ? (
                              <ChevronUp className="w-3.5 sm:w-4 h-3.5 sm:h-4" />
                            ) : (
                              <ChevronDown className="w-3.5 sm:w-4 h-3.5 sm:h-4" />
                            )}
                          </div>
                        </button>
                        <Link 
                          to={position.type === 'Full-time' ? '#' : `/contractor-application?position=${encodeURIComponent(position.title)}`}
                          onClick={(e) => {
                            if (position.type === 'Full-time') {
                              e.preventDefault();
                              setIsModalOpen(true);
                            }
                          }}
                          className="group relative inline-flex items-center justify-center gap-2 w-full sm:w-auto"
                        >
                          <div className="absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-xl blur-xl opacity-0 group-hover:opacity-20 transition-opacity duration-500" />
                          <div className="relative inline-flex items-center justify-center gap-2 px-4 sm:px-6 py-2 sm:py-3 bg-gradient-to-r from-indigo-500/10 to-purple-500/10 rounded-xl border border-white/10 font-medium text-white hover:border-white/20 transition-colors duration-500 w-full sm:w-auto">
                            <span>Apply Now</span>
                            <div className="w-5 sm:w-6 h-5 sm:h-6 rounded-full bg-white/5 flex items-center justify-center group-hover:bg-white/10 transition-colors duration-500">
                              <ArrowRight className="w-3 sm:w-3.5 h-3 sm:h-3.5" />
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>

                    {/* Expanded Content */}
                    {expandedPosition === index && (
                      <div className="mt-4 sm:mt-6 space-y-4 sm:space-y-6 border-t border-white/10 pt-4 sm:pt-6">
                        <div>
                          <h4 className="text-base sm:text-lg font-semibold mb-2 sm:mb-3 bg-clip-text text-transparent bg-gradient-to-r from-white to-white/70">
                            Role Overview
                          </h4>
                          <p className="text-sm sm:text-base text-gray-400">{position.overview}</p>
                        </div>

                        <div>
                          <h4 className="text-base sm:text-lg font-semibold mb-2 sm:mb-3 bg-clip-text text-transparent bg-gradient-to-r from-white to-white/70">
                            Key Responsibilities
                          </h4>
                          <ul className="space-y-2">
                            {position.responsibilities.map((responsibility, i) => (
                              <li key={i} className="flex items-start gap-2 sm:gap-3 text-sm sm:text-base text-gray-400">
                                <div className="w-4 sm:w-5 h-4 sm:h-5 rounded-full bg-indigo-500/10 flex items-center justify-center flex-shrink-0 mt-1">
                                  <Sparkles className="w-2.5 sm:w-3 h-2.5 sm:h-3 text-indigo-400" />
                                </div>
                                <span>{responsibility}</span>
                              </li>
                            ))}
                          </ul>
                        </div>

                        <div>
                          <h4 className="text-base sm:text-lg font-semibold mb-2 sm:mb-3 bg-clip-text text-transparent bg-gradient-to-r from-white to-white/70">
                            Qualifications
                          </h4>
                          <ul className="space-y-2">
                            {position.qualifications.map((qualification, i) => (
                              <li key={i} className="flex items-start gap-2 sm:gap-3 text-sm sm:text-base text-gray-400">
                                <div className="w-4 sm:w-5 h-4 sm:h-5 rounded-full bg-indigo-500/10 flex items-center justify-center flex-shrink-0 mt-1">
                                  <Sparkles className="w-2.5 sm:w-3 h-2.5 sm:h-3 text-indigo-400" />
                                </div>
                                <span>{qualification}</span>
                              </li>
                            ))}
                          </ul>
                        </div>

                        <div>
                          <h4 className="text-base sm:text-lg font-semibold mb-2 sm:mb-3 bg-clip-text text-transparent bg-gradient-to-r from-white to-white/70">
                            Compensation & Benefits
                          </h4>
                          <ul className="space-y-2">
                            {position.compensation.map((item, i) => (
                              <li key={i} className="flex items-start gap-2 sm:gap-3 text-sm sm:text-base text-gray-400">
                                <div className="w-4 sm:w-5 h-4 sm:h-5 rounded-full bg-indigo-500/10 flex items-center justify-center flex-shrink-0 mt-1">
                                  <Sparkles className="w-2.5 sm:w-3 h-2.5 sm:h-3 text-indigo-400" />
                                </div>
                                <span>{item}</span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Open Application */}
      <section className="relative pb-32">
        <div className="container mx-auto px-6">
          <div className="relative overflow-hidden">
            <div className="absolute -inset-px rounded-3xl bg-gradient-to-b from-indigo-500/50 via-indigo-500/5 to-transparent" />
            <div className="relative bg-black/40 backdrop-blur-xl rounded-3xl p-12 text-center border border-white/10">
              <h2 className="text-3xl md:text-4xl font-bold mb-6">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
                  Don't See Your Dream Role?
                </span>
              </h2>
              <p className="text-gray-400 text-lg mb-8 max-w-2xl mx-auto">
                We're always looking for exceptional talent. If you're passionate about digital media and think you'd be a great fit, we want to hear from you.
              </p>
              <button 
                onClick={() => setIsModalOpen(true)}
                className="group relative inline-flex items-center gap-2"
              >
                <div className="absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-xl blur-xl opacity-0 group-hover:opacity-20 transition-opacity duration-500" />
                <div className="relative inline-flex items-center gap-2 px-8 py-4 bg-gradient-to-r from-indigo-500/10 to-purple-500/10 rounded-xl border border-white/10 font-medium text-white hover:border-white/20 transition-colors duration-500">
                  <span>Tell Us Where You'd Fit In</span>
                  <div className="w-8 h-8 rounded-full bg-white/5 flex items-center justify-center group-hover:bg-white/10 transition-colors duration-500">
                    <ArrowRight className="w-4 h-4" />
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Modal */}
      {isModalOpen && <ApplicationModal />}
    </div>
  );
}