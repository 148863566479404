import { 
  Download,
  Palette,
  Type,
  Image,
  FileImage,
  Copy,
  CheckCheck,
  Layers
} from 'lucide-react';
import { useState } from 'react';

export default function BrandingPage() {
  const [copiedColor, setCopiedColor] = useState<string | null>(null);

  const colors = {
    primary: [
      { name: 'Indigo 400', hex: '#818CF8' },
      { name: 'Indigo 500', hex: '#6366F1' },
      { name: 'Indigo 600', hex: '#4F46E5' },
      { name: 'Purple 400', hex: '#C084FC' },
      { name: 'Purple 500', hex: '#A855F7' },
      { name: 'Purple 600', hex: '#9333EA' }
    ],
    neutral: [
      { name: 'White', hex: '#FFFFFF' },
      { name: 'Gray 400', hex: '#9CA3AF' },
      { name: 'Gray 500', hex: '#6B7280' },
      { name: 'Gray 600', hex: '#4B5563' },
      { name: 'Black', hex: '#000000' }
    ]
  };

  const gradients = [
    {
      name: 'Indigo Fade',
      description: 'Primary brand gradient',
      className: 'bg-gradient-to-r from-indigo-600 to-black'
    },
    {
      name: 'Deep Space',
      description: 'Dark indigo blend',
      className: 'bg-gradient-to-br from-indigo-900 via-indigo-800 to-black'
    },
    {
      name: 'Cosmic Dust',
      description: 'Subtle indigo overlay',
      className: 'bg-gradient-to-r from-black via-indigo-950 to-black'
    },
    {
      name: 'Northern Lights',
      description: 'Purple-indigo aurora',
      className: 'bg-gradient-to-br from-indigo-600 via-purple-600 to-black'
    },
    {
      name: 'Galactic Core',
      description: 'Radial indigo burst',
      className: 'bg-[radial-gradient(circle_at_center,#4338ca,transparent_70%)] bg-black'
    },
    {
      name: 'Star Trail',
      description: 'Diagonal indigo streak',
      className: 'bg-[linear-gradient(45deg,#4338ca_0%,rgba(67,56,202,0.3)_30%,transparent_50%,rgba(67,56,202,0.3)_70%,#4338ca_100%)] bg-black'
    },
    {
      name: 'Nebula',
      description: 'Multi-color cosmic blend',
      className: 'bg-gradient-to-br from-indigo-600 via-purple-600 via-pink-600 to-black'
    },
    {
      name: 'Dark Matter',
      description: 'Subtle energy waves',
      className: 'bg-[linear-gradient(to_right,#000000,#4338ca20,#000000)]'
    },
    {
      name: 'Quantum Field',
      description: 'Complex energy pattern',
      className: 'bg-[radial-gradient(circle_at_top_left,#4338ca40,transparent_30%),radial-gradient(circle_at_bottom_right,#6366f140,transparent_30%)] bg-black'
    },
    {
      name: 'Cosmic Web',
      description: 'Interconnected energy',
      className: 'bg-[linear-gradient(45deg,#4338ca30,transparent),linear-gradient(-45deg,#6366f130,transparent)] bg-black'
    },
    {
      name: 'Event Horizon',
      description: 'Edge of space gradient',
      className: 'bg-gradient-to-b from-indigo-950 via-black to-black'
    },
    {
      name: 'Stellar Wind',
      description: 'Dynamic energy flow',
      className: 'bg-[linear-gradient(60deg,#4338ca40,transparent),linear-gradient(120deg,#6366f140,transparent),linear-gradient(180deg,#4f46e540,transparent)] bg-black'
    }
  ];

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopiedColor(text);
    setTimeout(() => setCopiedColor(null), 2000);
  };

  const downloadAsset = (filename: string) => {
    const link = document.createElement('a');
    link.href = `/${filename}`;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const generateGradientPNG = (gradient: typeof gradients[0]) => {
    try {
      // Create a new offscreen canvas
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      // Set dimensions for 4K
      const desiredWidth = 3840;
      const desiredHeight = 2160;
      
      // Set canvas size with device pixel ratio
      const scale = window.devicePixelRatio || 1;
      canvas.width = desiredWidth * scale;
      canvas.height = desiredHeight * scale;
      canvas.style.width = `${desiredWidth}px`;
      canvas.style.height = `${desiredHeight}px`;
      
      // Scale context
      ctx.scale(scale, scale);

      // Create temporary div to render the gradient
      const tempDiv = document.createElement('div');
      tempDiv.style.width = `${desiredWidth}px`;
      tempDiv.style.height = `${desiredHeight}px`;
      tempDiv.className = gradient.className;
      document.body.appendChild(tempDiv);

      // Get computed gradient
      const computedStyle = window.getComputedStyle(tempDiv);
      const backgroundImage = computedStyle.backgroundImage;
      const backgroundColor = computedStyle.backgroundColor;

      // Clean up temporary div
      document.body.removeChild(tempDiv);

      // Create gradient on canvas
      if (backgroundColor) {
        ctx.fillStyle = backgroundColor;
        ctx.fillRect(0, 0, desiredWidth, desiredHeight);
      }

      // Handle different gradient types
      if (backgroundImage !== 'none') {
        const canvasGradient = ctx.createLinearGradient(0, 0, desiredWidth, desiredHeight);
        
        // Parse gradient colors and stops
        const colors = backgroundImage.match(/rgba?\([\d\s,\.]+\)|#[a-f\d]+/gi) || [];
        const stops = backgroundImage.match(/\d+%/g) || [];
        
        colors.forEach((color, index) => {
          const stop = stops[index] ? parseInt(stops[index]) / 100 : index / (colors.length - 1);
          canvasGradient.addColorStop(stop, color);
        });

        ctx.fillStyle = canvasGradient;
        ctx.fillRect(0, 0, desiredWidth, desiredHeight);
      }

      // Convert to PNG and trigger download
      const dataUrl = canvas.toDataURL('image/png', 1.0);
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = `${gradient.name.toLowerCase().replace(' ', '-')}-4k.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error generating gradient PNG:', error);
    }
  };

  return (
    <div className="relative min-h-screen">
      {/* Background */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-black">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_60%_-20%,#4338ca,transparent_50%)]" />
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_150%,#6366f1,transparent_50%)]" />
        </div>
        <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-10" />
      </div>

      {/* Content */}
      <div className="relative pt-32 pb-20">
        <div className="container mx-auto px-6">
          {/* Header */}
          <div className="text-center mb-16">
            <div className="inline-block mb-4">
              <div className="relative px-6 py-2 bg-black/50 backdrop-blur-xl rounded-full border border-white/10 flex items-center gap-2">
                <Palette className="w-4 h-4 text-indigo-400" />
                <span className="text-sm font-medium bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
                  Brand Guidelines
                </span>
              </div>
            </div>
            <h1 className="text-4xl md:text-5xl font-bold mb-6">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
                Galactic Media Brand Assets
              </span>
            </h1>
            <p className="text-xl text-gray-400 max-w-2xl mx-auto">
              Official brand guidelines and downloadable assets for Galactic Media
            </p>
          </div>

          {/* Color Palettes */}
          <section className="mb-20">
            <h2 className="text-2xl font-bold mb-8">Color Palette</h2>
            
            {/* Primary Colors */}
            <div className="mb-12">
              <h3 className="text-lg font-medium mb-4 text-gray-400">Primary Colors</h3>
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
                {colors.primary.map((color) => (
                  <div 
                    key={color.hex}
                    className="relative group"
                  >
                    <div className="absolute -inset-px bg-gradient-to-b from-white/20 to-white/0 rounded-xl blur-sm opacity-0 group-hover:opacity-100 transition-opacity" />
                    <div className="relative bg-black/20 backdrop-blur-sm rounded-xl border border-white/10 overflow-hidden">
                      <div 
                        className="h-24 cursor-pointer transition-transform hover:scale-105"
                        style={{ backgroundColor: color.hex }}
                        onClick={() => copyToClipboard(color.hex)}
                      />
                      <div className="p-4">
                        <div className="text-sm font-medium mb-1">{color.name}</div>
                        <div className="flex items-center justify-between">
                          <code className="text-sm text-gray-400">{color.hex}</code>
                          <button
                            onClick={() => copyToClipboard(color.hex)}
                            className="text-gray-400 hover:text-white transition-colors"
                          >
                            {copiedColor === color.hex ? (
                              <CheckCheck className="w-4 h-4" />
                            ) : (
                              <Copy className="w-4 h-4" />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Neutral Colors */}
            <div>
              <h3 className="text-lg font-medium mb-4 text-gray-400">Neutral Colors</h3>
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
                {colors.neutral.map((color) => (
                  <div 
                    key={color.hex}
                    className="relative group"
                  >
                    <div className="absolute -inset-px bg-gradient-to-b from-white/20 to-white/0 rounded-xl blur-sm opacity-0 group-hover:opacity-100 transition-opacity" />
                    <div className="relative bg-black/20 backdrop-blur-sm rounded-xl border border-white/10 overflow-hidden">
                      <div 
                        className="h-24 cursor-pointer transition-transform hover:scale-105"
                        style={{ backgroundColor: color.hex }}
                        onClick={() => copyToClipboard(color.hex)}
                      />
                      <div className="p-4">
                        <div className="text-sm font-medium mb-1">{color.name}</div>
                        <div className="flex items-center justify-between">
                          <code className="text-sm text-gray-400">{color.hex}</code>
                          <button
                            onClick={() => copyToClipboard(color.hex)}
                            className="text-gray-400 hover:text-white transition-colors"
                          >
                            {copiedColor === color.hex ? (
                              <CheckCheck className="w-4 h-4" />
                            ) : (
                              <Copy className="w-4 h-4" />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>

          {/* Typography */}
          <section className="mb-20">
            <h2 className="text-2xl font-bold mb-8">Typography</h2>
            <div className="grid md:grid-cols-2 gap-8">
              {/* Primary Font */}
              <div className="relative group">
                <div className="absolute -inset-px bg-gradient-to-b from-indigo-500/20 to-purple-500/20 rounded-xl blur-sm" />
                <div className="relative p-6 bg-black/20 backdrop-blur-sm rounded-xl border border-white/10">
                  <h3 className="text-lg font-medium mb-4 text-gray-400">Primary Font - Outfit</h3>
                  <div className="space-y-4 mb-6">
                    <div>
                      <div className="text-4xl font-black">Display Black</div>
                      <div className="text-sm text-gray-400 mt-1">font-weight: 900</div>
                    </div>
                    <div>
                      <div className="text-2xl font-bold">Heading Bold</div>
                      <div className="text-sm text-gray-400 mt-1">font-weight: 700</div>
                    </div>
                    <div>
                      <div className="text-xl font-medium">Subheading Medium</div>
                      <div className="text-sm text-gray-400 mt-1">font-weight: 500</div>
                    </div>
                    <div>
                      <div className="text-base">Body Regular</div>
                      <div className="text-sm text-gray-400 mt-1">font-weight: 400</div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <button
                      onClick={() => downloadAsset('fonts/outfit.zip')}
                      className="flex items-center justify-between px-3 py-1.5 rounded-lg bg-indigo-500/10 border border-indigo-500/20 hover:bg-indigo-500/20 transition-colors text-sm group"
                    >
                      <div className="flex items-center gap-2">
                        <Download className="w-4 h-4" />
                        <span>Download Font Family</span>
                      </div>
                      <span className="text-xs text-gray-400 group-hover:text-gray-300">All Weights</span>
                    </button>
                    <a
                      href="https://fonts.google.com/specimen/Outfit"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center justify-between px-3 py-1.5 rounded-lg bg-indigo-500/10 border border-indigo-500/20 hover:bg-indigo-500/20 transition-colors text-sm group"
                    >
                      <div className="flex items-center gap-2">
                        <Type className="w-4 h-4" />
                        <span>Google Fonts</span>
                      </div>
                      <span className="text-xs text-gray-400 group-hover:text-gray-300">Web Font</span>
                    </a>
                  </div>
                </div>
              </div>

              {/* Secondary Font */}
              <div className="relative group">
                <div className="absolute -inset-px bg-gradient-to-b from-indigo-500/20 to-purple-500/20 rounded-xl blur-sm" />
                <div className="relative p-6 bg-black/20 backdrop-blur-sm rounded-xl border border-white/10">
                  <h3 className="text-lg font-medium mb-4 text-gray-400">Secondary Font - Exo 2</h3>
                  <div className="space-y-4 mb-6">
                    <div>
                      <div className="text-4xl font-black font-display">Display Black</div>
                      <div className="text-sm text-gray-400 mt-1">font-weight: 900</div>
                    </div>
                    <div>
                      <div className="text-2xl font-bold font-display">Heading Bold</div>
                      <div className="text-sm text-gray-400 mt-1">font-weight: 700</div>
                    </div>
                    <div>
                      <div className="text-xl font-medium font-display">Subheading Medium</div>
                      <div className="text-sm text-gray-400 mt-1">font-weight: 500</div>
                    </div>
                    <div>
                      <div className="text-base font-display">Body Regular</div>
                      <div className="text-sm text-gray-400 mt-1">font-weight: 400</div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <button
                      onClick={() => downloadAsset('fonts/exo2.zip')}
                      className="flex items-center justify-between px-3 py-1.5 rounded-lg bg-indigo-500/10 border border-indigo-500/20 hover:bg-indigo-500/20 transition-colors text-sm group"
                    >
                      <div className="flex items-center gap-2">
                        <Download className="w-4 h-4" />
                        <span>Download Font Family</span>
                      </div>
                      <span className="text-xs text-gray-400 group-hover:text-gray-300">All Weights</span>
                    </button>
                    <a
                      href="https://fonts.google.com/specimen/Exo+2"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center justify-between px-3 py-1.5 rounded-lg bg-indigo-500/10 border border-indigo-500/20 hover:bg-indigo-500/20 transition-colors text-sm group"
                    >
                      <div className="flex items-center gap-2">
                        <Type className="w-4 h-4" />
                        <span>Google Fonts</span>
                      </div>
                      <span className="text-xs text-gray-400 group-hover:text-gray-300">Web Font</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Updated Downloadable Assets */}
          <section>
            <h2 className="text-2xl font-bold mb-8">Downloadable Assets</h2>
            
            {/* Updated Logo Section */}
            <div className="mb-12">
              <h3 className="text-lg font-medium mb-4 text-gray-400">Logo</h3>
              <div className="grid md:grid-cols-2 gap-6">
                {/* Primary Logo */}
                <div className="relative group">
                  <div className="absolute -inset-px bg-gradient-to-b from-indigo-500/20 to-purple-500/20 rounded-xl blur-sm" />
                  <div className="relative p-6 bg-black/20 backdrop-blur-sm rounded-xl border border-white/10">
                    <div className="mb-4">
                      <div className="aspect-[3/1] rounded-lg bg-black/40 p-6 mb-4">
                        <img src="/svg/logo.svg" alt="Galactic Media Logo" className="w-full h-full object-contain" />
                      </div>
                    </div>
                    <div>
                      <h4 className="font-medium mb-2">Primary Logo</h4>
                      <div className="flex flex-col gap-2">
                        <button
                          onClick={() => downloadAsset('svg/logo.svg')}
                          className="flex items-center justify-between px-3 py-1.5 rounded-lg bg-indigo-500/10 border border-indigo-500/20 hover:bg-indigo-500/20 transition-colors text-sm group"
                        >
                          <div className="flex items-center gap-2">
                            <Download className="w-4 h-4" />
                            <span>Download SVG</span>
                          </div>
                          <span className="text-xs text-gray-400 group-hover:text-gray-300">Vector</span>
                        </button>
                        <button
                          onClick={() => downloadAsset('images/logo-4k.png')}
                          className="flex items-center justify-between px-3 py-1.5 rounded-lg bg-indigo-500/10 border border-indigo-500/20 hover:bg-indigo-500/20 transition-colors text-sm group"
                        >
                          <div className="flex items-center gap-2">
                            <Download className="w-4 h-4" />
                            <span>Download PNG</span>
                          </div>
                          <span className="text-xs text-gray-400 group-hover:text-gray-300">4K Resolution</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Black Logo */}
                <div className="relative group">
                  <div className="absolute -inset-px bg-gradient-to-b from-indigo-500/20 to-purple-500/20 rounded-xl blur-sm" />
                  <div className="relative p-6 bg-black/20 backdrop-blur-sm rounded-xl border border-white/10">
                    <div className="mb-4">
                      <div className="aspect-[3/1] rounded-lg bg-white p-6 mb-4">
                        <img src="/svg/logo-black.svg" alt="Galactic Media Logo (Black)" className="w-full h-full object-contain" />
                      </div>
                    </div>
                    <div>
                      <h4 className="font-medium mb-2">Black Logo</h4>
                      <div className="flex flex-col gap-2">
                        <button
                          onClick={() => downloadAsset('svg/logo-black.svg')}
                          className="flex items-center justify-between px-3 py-1.5 rounded-lg bg-indigo-500/10 border border-indigo-500/20 hover:bg-indigo-500/20 transition-colors text-sm group"
                        >
                          <div className="flex items-center gap-2">
                            <Download className="w-4 h-4" />
                            <span>Download SVG</span>
                          </div>
                          <span className="text-xs text-gray-400 group-hover:text-gray-300">Vector</span>
                        </button>
                        <button
                          onClick={() => downloadAsset('images/logo-black-4k.png')}
                          className="flex items-center justify-between px-3 py-1.5 rounded-lg bg-indigo-500/10 border border-indigo-500/20 hover:bg-indigo-500/20 transition-colors text-sm group"
                        >
                          <div className="flex items-center gap-2">
                            <Download className="w-4 h-4" />
                            <span>Download PNG</span>
                          </div>
                          <span className="text-xs text-gray-400 group-hover:text-gray-300">4K Resolution</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Updated Gradients section */}
            <div className="mb-12">
              <div className="flex items-center gap-2 mb-4">
                <h3 className="text-lg font-medium text-gray-400">Gradient Collection</h3>
                <div className="px-2 py-1 rounded-md bg-indigo-500/10 border border-indigo-500/20 text-xs text-indigo-400">
                  {gradients.length} variations
                </div>
              </div>
              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                {gradients.map((gradient, index) => (
                  <div key={index} className="relative group">
                    <div className="absolute -inset-px bg-gradient-to-b from-indigo-500/20 to-purple-500/20 rounded-xl blur-sm opacity-0 group-hover:opacity-100 transition-opacity" />
                    <div className="relative bg-black/20 backdrop-blur-sm rounded-xl border border-white/10 overflow-hidden">
                      <div className={`h-32 ${gradient.className}`} />
                      <div className="p-4">
                        <div className="mb-3">
                          <h4 className="font-medium">{gradient.name}</h4>
                          <p className="text-sm text-gray-400">{gradient.description}</p>
                        </div>
                        <div className="flex flex-col gap-2">
                          <button
                            onClick={() => downloadAsset(`gradients/${gradient.name.toLowerCase().replace(' ', '-')}.svg`)}
                            className="flex items-center justify-between px-3 py-1.5 rounded-lg bg-indigo-500/10 border border-indigo-500/20 hover:bg-indigo-500/20 transition-colors text-sm group"
                          >
                            <div className="flex items-center gap-2">
                              <Download className="w-4 h-4" />
                              <span>Download SVG</span>
                            </div>
                            <span className="text-xs text-gray-400 group-hover:text-gray-300">Vector</span>
                          </button>
                          <button
                            onClick={() => generateGradientPNG(gradient)}
                            className="flex items-center justify-between px-3 py-1.5 rounded-lg bg-indigo-500/10 border border-indigo-500/20 hover:bg-indigo-500/20 transition-colors text-sm group"
                          >
                            <div className="flex items-center gap-2">
                              <Download className="w-4 h-4" />
                              <span>Download PNG</span>
                            </div>
                            <span className="text-xs text-gray-400 group-hover:text-gray-300">4K Resolution</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>

          {/* UI Elements & Graphics */}
          <section className="mb-20">
            <h2 className="text-2xl font-bold mb-8">UI Elements & Graphics</h2>
            
            {/* Background Elements */}
            <div className="mb-12">
              <h3 className="text-lg font-medium mb-4 text-gray-400">Background Elements</h3>
              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
                {/* Grid Pattern */}
                <div className="relative group">
                  <div className="absolute -inset-px bg-gradient-to-b from-indigo-500/20 to-purple-500/20 rounded-xl blur-sm" />
                  <div className="relative p-6 bg-black/20 backdrop-blur-sm rounded-xl border border-white/10">
                    <div className="aspect-video rounded-lg bg-black/40 mb-4 overflow-hidden">
                      <div className="h-full w-full bg-[url('/grid.svg')] opacity-20" />
                    </div>
                    <div>
                      <h4 className="font-medium mb-2">Grid Pattern</h4>
                      <button
                        onClick={() => downloadAsset('grid.svg')}
                        className="flex items-center justify-between w-full px-3 py-1.5 rounded-lg bg-indigo-500/10 border border-indigo-500/20 hover:bg-indigo-500/20 transition-colors text-sm group"
                      >
                        <div className="flex items-center gap-2">
                          <Download className="w-4 h-4" />
                          <span>Download SVG</span>
                        </div>
                        <span className="text-xs text-gray-400 group-hover:text-gray-300">Vector</span>
                      </button>
                    </div>
                  </div>
                </div>

                {/* Radial Gradients */}
                <div className="relative group">
                  <div className="absolute -inset-px bg-gradient-to-b from-indigo-500/20 to-purple-500/20 rounded-xl blur-sm" />
                  <div className="relative p-6 bg-black/20 backdrop-blur-sm rounded-xl border border-white/10">
                    <div className="aspect-video rounded-lg bg-black mb-4 overflow-hidden">
                      <div className="absolute inset-0">
                        <div className="absolute inset-0 bg-[radial-gradient(circle_at_60%_-20%,#4338ca,transparent_50%)]" />
                        <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_150%,#6366f1,transparent_50%)]" />
                      </div>
                    </div>
                    <div>
                      <h4 className="font-medium mb-2">Radial Gradients</h4>
                      <div className="text-sm text-gray-400 mb-4 space-y-1">
                        <code className="block">radial-gradient(circle at 60% -20%, #4338ca, transparent 50%)</code>
                        <code className="block">radial-gradient(circle at 30% 150%, #6366f1, transparent 50%)</code>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Floating Orbs */}
                <div className="relative group">
                  <div className="absolute -inset-px bg-gradient-to-b from-indigo-500/20 to-purple-500/20 rounded-xl blur-sm" />
                  <div className="relative p-6 bg-black/20 backdrop-blur-sm rounded-xl border border-white/10">
                    <div className="aspect-video rounded-lg bg-black mb-4 overflow-hidden relative">
                      <div className="absolute -top-24 left-1/4 w-48 h-48 bg-indigo-500/20 rounded-full blur-3xl animate-float" />
                      <div className="absolute -bottom-24 right-1/4 w-48 h-48 bg-purple-500/20 rounded-full blur-3xl animate-float-delayed" />
                    </div>
                    <div>
                      <h4 className="font-medium mb-2">Floating Orbs</h4>
                      <div className="text-sm text-gray-400">Animated background elements with blur effect</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Animated Elements */}
            <div className="mb-12">
              <h3 className="text-lg font-medium mb-4 text-gray-400">Animated Elements</h3>
              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
                {/* Stars */}
                <div className="relative group">
                  <div className="absolute -inset-px bg-gradient-to-b from-indigo-500/20 to-purple-500/20 rounded-xl blur-sm" />
                  <div className="relative p-6 bg-black/20 backdrop-blur-sm rounded-xl border border-white/10">
                    <div className="aspect-video rounded-lg bg-black mb-4 overflow-hidden relative">
                      <div className="absolute inset-0">
                        <div className="absolute top-[20%] left-[15%] w-1 h-1 bg-white rounded-full animate-[twinkle_3s_ease-in-out_infinite]" />
                        <div className="absolute top-[40%] right-[25%] w-1 h-1 bg-white rounded-full animate-[twinkle_3s_ease-in-out_infinite_0.5s]" />
                        <div className="absolute top-[60%] left-[45%] w-2 h-2 bg-indigo-400 rounded-full blur-[1px] animate-[twinkle_4s_ease-in-out_infinite_0.7s]" />
                        <div className="absolute top-[30%] right-[35%] w-2 h-2 bg-purple-400 rounded-full blur-[1px] animate-[twinkle_4s_ease-in-out_infinite_1.7s]" />
                      </div>
                    </div>
                    <div>
                      <h4 className="font-medium mb-2">Animated Stars</h4>
                      <div className="text-sm text-gray-400">Twinkling star effect with different sizes and colors</div>
                    </div>
                  </div>
                </div>

                {/* Radio Waves */}
                <div className="relative group">
                  <div className="absolute -inset-px bg-gradient-to-b from-indigo-500/20 to-purple-500/20 rounded-xl blur-sm" />
                  <div className="relative p-6 bg-black/20 backdrop-blur-sm rounded-xl border border-white/10">
                    <div className="aspect-video rounded-lg bg-black mb-4 overflow-hidden relative">
                      <div className="absolute inset-0 overflow-hidden">
                        {[...Array(3)].map((_, i) => (
                          <div
                            key={i}
                            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 border border-indigo-500/10 rounded-full"
                            style={{
                              width: `${(i + 1) * 100}px`,
                              height: `${(i + 1) * 100}px`,
                              animation: `radioPulse ${2 + i}s infinite ease-out ${i * 0.5}s`
                            }}
                          />
                        ))}
                      </div>
                    </div>
                    <div>
                      <h4 className="font-medium mb-2">Radio Waves</h4>
                      <div className="text-sm text-gray-400">Expanding circular waves animation</div>
                    </div>
                  </div>
                </div>

                {/* Floating Elements */}
                <div className="relative group">
                  <div className="absolute -inset-px bg-gradient-to-b from-indigo-500/20 to-purple-500/20 rounded-xl blur-sm" />
                  <div className="relative p-6 bg-black/20 backdrop-blur-sm rounded-xl border border-white/10">
                    <div className="aspect-video rounded-lg bg-black mb-4 overflow-hidden relative">
                      <div className="absolute inset-0">
                        <div className="absolute top-1/4 left-1/4 w-12 h-12 rounded-xl bg-white/5 border border-white/10 backdrop-blur-sm flex items-center justify-center animate-float">
                          <Layers className="w-6 h-6 text-indigo-400/50" />
                        </div>
                        <div className="absolute bottom-1/4 right-1/4 w-12 h-12 rounded-xl bg-white/5 border border-white/10 backdrop-blur-sm flex items-center justify-center animate-float" style={{ animationDelay: '1s' }}>
                          <Image className="w-6 h-6 text-purple-400/50" />
                        </div>
                      </div>
                    </div>
                    <div>
                      <h4 className="font-medium mb-2">Floating Icons</h4>
                      <div className="text-sm text-gray-400">Smoothly animated floating UI elements</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* UI Components */}
            <div>
              <h3 className="text-lg font-medium mb-4 text-gray-400">UI Components</h3>
              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
                {/* Glass Card */}
                <div className="relative group">
                  <div className="absolute -inset-px bg-gradient-to-b from-indigo-500/20 to-purple-500/20 rounded-xl blur-sm" />
                  <div className="relative p-6 bg-black/20 backdrop-blur-sm rounded-xl border border-white/10">
                    <div className="glass-card p-6 rounded-lg mb-4">
                      <h5 className="text-lg font-medium mb-2">Glass Card</h5>
                      <p className="text-sm text-gray-400">A frosted glass effect card component</p>
                    </div>
                    <div>
                      <h4 className="font-medium mb-2">Glass Effect Card</h4>
                      <div className="text-sm text-gray-400 space-y-1">
                        <code className="block">backdrop-filter: blur(10px)</code>
                        <code className="block">background: rgba(32, 31, 47, 0.8)</code>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Progress Circle */}
                <div className="relative group">
                  <div className="absolute -inset-px bg-gradient-to-b from-indigo-500/20 to-purple-500/20 rounded-xl blur-sm" />
                  <div className="relative p-6 bg-black/20 backdrop-blur-sm rounded-xl border border-white/10">
                    <div className="flex justify-center mb-4">
                      <div className="relative w-24 h-24">
                        <svg className="w-full h-full" viewBox="0 0 36 36">
                          <circle
                            cx="18"
                            cy="18"
                            r="16"
                            fill="none"
                            className="stroke-current text-indigo-900"
                            strokeWidth="2"
                          />
                          <circle
                            cx="18"
                            cy="18"
                            r="16"
                            fill="none"
                            className="stroke-current text-indigo-400"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeDasharray="75, 100"
                            transform="rotate(-90 18 18)"
                          />
                        </svg>
                        <div className="absolute inset-0 flex items-center justify-center text-lg font-medium text-indigo-400">
                          75%
                        </div>
                      </div>
                    </div>
                    <div>
                      <h4 className="font-medium mb-2">Progress Circle</h4>
                      <div className="text-sm text-gray-400">Circular progress indicator with gradient</div>
                    </div>
                  </div>
                </div>

                {/* Hover Card */}
                <div className="relative group">
                  <div className="absolute -inset-px bg-gradient-to-b from-indigo-500/20 to-purple-500/20 rounded-xl blur-sm" />
                  <div className="relative p-6 bg-black/20 backdrop-blur-sm rounded-xl border border-white/10">
                    <div className="relative group/card mb-4">
                      <div className="absolute -inset-px bg-gradient-to-b from-indigo-500/20 to-purple-500/20 rounded-xl blur-sm opacity-0 group-hover/card:opacity-100 transition-opacity" />
                      <div className="relative p-4 bg-black/20 backdrop-blur-sm rounded-xl border border-white/10">
                        <h5 className="font-medium">Hover Effect</h5>
                        <p className="text-sm text-gray-400">Hover to see the glow</p>
                      </div>
                    </div>
                    <div>
                      <h4 className="font-medium mb-2">Hover Card</h4>
                      <div className="text-sm text-gray-400">Card with hover glow effect</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
} 