import { Outlet, Link, useLocation } from 'react-router-dom';
import { Menu, Twitter, Instagram, Youtube, Linkedin, Github, ChevronDown } from 'lucide-react';
import { useState, useEffect, useRef } from 'react';

export default function RootLayout() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [aboutMenuOpen, setAboutMenuOpen] = useState(false);
  const aboutMenuRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (aboutMenuRef.current && !aboutMenuRef.current.contains(event.target as Node)) {
        setAboutMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const navigation = [
    { name: 'Creators', href: '/creators' },
    { name: 'Studio', href: '/studio' },
    { name: 'Investments', href: '/investments' },
    { name: 'Technology', href: '/technology' },
    {
      name: 'Company',
      href: '/company',
      submenu: [
        { name: 'About Us', href: '/about' },
        { name: 'Careers', href: '/careers' },
        { name: 'Contact', href: '/contact' }
      ]
    },
  ];

  return (
    <div className="min-h-screen bg-black text-white overflow-x-hidden">
      <nav className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 border-b ${
        isScrolled ? 'bg-black/50 backdrop-blur-xl border-white/10' : 'bg-transparent border-transparent'
      }`}>
        <div className="container mx-auto px-4 sm:px-6">
          <div className="flex items-center justify-between h-14 sm:h-16 md:h-20">
            {/* Logo */}
            <Link to="/" className="relative group">
              <div className={`absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-full blur-lg opacity-0 group-hover:opacity-20 transition-all duration-500 ${
                isScrolled ? 'opacity-20' : 'opacity-0'
              }`} />
              <img src="/svg/logo.svg" alt="Galactic Media Logo" className="relative w-[120px] sm:w-[150px] h-[40px] sm:h-[50px]" />
            </Link>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center gap-6 sm:gap-10">
              {navigation.map((item) => (
                <div key={item.name} className="relative" ref={item.submenu ? aboutMenuRef : null}>
                  {item.submenu ? (
                    <button
                      onClick={() => setAboutMenuOpen(!aboutMenuOpen)}
                      className={`group relative text-sm sm:text-base font-medium flex items-center gap-1 ${
                        location.pathname === item.href ? 'text-white' : 'text-gray-300 hover:text-white'
                      } transition-colors duration-300`}
                    >
                      <div className={`absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-full blur-lg opacity-0 group-hover:opacity-20 transition-all duration-500 ${
                        location.pathname === item.href ? 'opacity-20' : 'opacity-0'
                      }`} />
                      <span className="relative">{item.name}</span>
                      <ChevronDown className="w-4 h-4" />
                      
                      {/* Dropdown Menu */}
                      {aboutMenuOpen && (
                        <div className="absolute top-full left-0 mt-2 w-56 sm:w-48 rounded-lg bg-black/95 backdrop-blur-xl border border-white/10 shadow-lg shadow-black/50 py-1 sm:py-2">
                          {item.submenu.map((subItem) => (
                            <Link
                              key={subItem.name}
                              to={subItem.href}
                              className="block px-4 py-3 sm:py-2 text-gray-300 hover:text-white hover:bg-white/5 text-base sm:text-sm"
                              onClick={() => setAboutMenuOpen(false)}
                            >
                              {subItem.name}
                            </Link>
                          ))}
                        </div>
                      )}
                    </button>
                  ) : (
                    <Link
                      to={item.href}
                      className={`group relative text-sm sm:text-base font-medium ${
                        location.pathname === item.href ? 'text-white' : 'text-gray-300 hover:text-white'
                      } transition-colors duration-300`}
                    >
                      <div className={`absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-full blur-lg opacity-0 group-hover:opacity-20 transition-all duration-500 ${
                        location.pathname === item.href ? 'opacity-20' : 'opacity-0'
                      }`} />
                      <span className="relative">
                        {item.name}
                      </span>
                    </Link>
                  )}
                </div>
              ))}
            </div>

            {/* Mobile Menu Button */}
            <button
              className={`md:hidden relative w-12 h-12 flex items-center justify-center rounded-xl transition-colors duration-300 ${
                isScrolled ? 'bg-white/5 hover:bg-white/10' : 'bg-white/10 hover:bg-white/20'
              }`}
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <Menu className="w-6 h-6 text-white" />
            </button>
          </div>

          {/* Mobile Navigation */}
          {mobileMenuOpen && (
            <div className={`md:hidden py-2 sm:py-4 border-t border-white/5 ${
              isScrolled ? 'bg-black/80 backdrop-blur-2xl' : 'bg-black/60 backdrop-blur-xl'
            }`}>
              {navigation.map((item) => (
                <div key={item.name}>
                  {item.submenu ? (
                    <>
                      <div
                        className={`group relative block py-4 px-4 sm:px-6 text-white font-medium border-b border-white/5 text-base sm:text-lg`}
                      >
                        <span className="relative flex items-center gap-1">
                          {item.name}
                          <ChevronDown className="w-4 h-4 opacity-50" />
                        </span>
                      </div>
                      <div className="bg-white/5">
                        {item.submenu.map((subItem) => (
                          <Link
                            key={subItem.name}
                            to={subItem.href}
                            className="block py-4 px-8 text-gray-300 hover:text-white active:bg-white/10 transition-colors duration-300 text-base sm:text-lg"
                            onClick={() => setMobileMenuOpen(false)}
                          >
                            {subItem.name}
                          </Link>
                        ))}
                      </div>
                    </>
                  ) : (
                    <Link
                      to={item.href}
                      className={`group relative block py-4 px-4 sm:px-6 ${
                        location.pathname === item.href ? 'text-white bg-white/5' : 'text-gray-300'
                      } hover:text-white active:bg-white/10 transition-colors duration-300 text-base sm:text-lg`}
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <div className={`absolute inset-0 bg-gradient-to-r from-indigo-500 to-purple-500 opacity-0 group-hover:opacity-10 transition-all duration-500 ${
                        location.pathname === item.href ? 'opacity-10' : 'opacity-0'
                      }`} />
                      <span className="relative">
                        {item.name}
                      </span>
                    </Link>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </nav>

      <main className="overflow-x-hidden">
        <Outlet />
      </main>

      <footer className="bg-black border-t border-white/5 py-8 sm:py-12 md:py-16">
        <div className="container mx-auto px-4 sm:px-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 sm:gap-12">
            {/* Logo and Social Links */}
            <div className="col-span-1 sm:col-span-2 md:col-span-1">
              <img src="/svg/logo.svg" alt="Galactic Media Logo" className="w-[100px] sm:w-[120px] md:w-[150px] h-[35px] sm:h-[40px] md:h-[50px] mb-4 sm:mb-6" />
              <p className="text-gray-400 text-sm sm:text-base mb-4 sm:mb-6">
                A Full-Stack Creator Ecosystem
              </p>
              <div className="flex gap-4 sm:gap-6">
                <a
                  href="https://x.com/galacticmediatv"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-indigo-400 transition-colors"
                  aria-label="X (Twitter)"
                >
                  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
                  </svg>
                </a>
                <a
                  href="https://linkedin.com/company/galactic-media-group-inc"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-indigo-400 transition-colors"
                  aria-label="LinkedIn"
                >
                  <Linkedin className="w-5 h-5" />
                </a>
              </div>
            </div>
            
            {/* Divisions */}
            <div>
              <h4 className="font-display font-bold text-sm sm:text-base mb-3 sm:mb-4">Divisions</h4>
              <ul className="space-y-2 sm:space-y-2">
                {navigation.filter(item => !['Careers', 'Company'].includes(item.name)).map((item) => (
                  <li key={item.name}>
                    <Link
                      to={item.href}
                      className="text-gray-400 hover:text-indigo-400 transition-colors text-sm sm:text-base"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            
            {/* Company */}
            <div>
              <h4 className="font-display font-bold text-sm sm:text-base mb-3 sm:mb-4">Company</h4>
              <ul className="space-y-2 sm:space-y-2">
                <li>
                  <Link to="/about" className="text-gray-400 hover:text-indigo-400 transition-colors text-sm sm:text-base">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/careers" className="text-gray-400 hover:text-indigo-400 transition-colors text-sm sm:text-base">
                    Careers
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="text-gray-400 hover:text-indigo-400 transition-colors text-sm sm:text-base">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            
            {/* Legal */}
            <div>
              <h4 className="font-display font-bold text-sm sm:text-base mb-3 sm:mb-4">Legal</h4>
              <ul className="space-y-2 sm:space-y-2">
                <li>
                  <Link to="/privacy" className="text-gray-400 hover:text-indigo-400 transition-colors text-sm sm:text-base">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/terms" className="text-gray-400 hover:text-indigo-400 transition-colors text-sm sm:text-base">
                    Terms of Service
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          
          <div className="mt-8 sm:mt-12 pt-6 sm:pt-8 border-t border-white/5 text-center text-gray-400 text-xs sm:text-sm md:text-base">
            © 2025 GALACTIC MEDIA GROUP INC. All rights reserved.
          </div>
        </div>
      </footer>
    </div>
  );
}