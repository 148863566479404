import { 
  LayoutGrid, Calendar, BarChart2, Clock, CheckCircle2, AlertCircle, 
  PlayCircle, Users, MessageSquare, Settings, ChevronDown, Search,
  Bell, User, Plus, Filter, MoreVertical, ChevronRight, ArrowRight,
  TrendingUp, TrendingDown, DollarSign, Eye, ThumbsUp, MessageCircle,
  Building2, Camera, FileVideo, Mic, Film, CalendarRange, FileCheck,
  Clock4, MapPin, Briefcase, Share2, FileText, Pencil, Video, Library,
  Layers, Timer, UserCheck, Shield, Music, Megaphone, PieChart
} from 'lucide-react';

export function ProjectDashboard() {
  const projects = [
    {
      title: "Inside Tesla's AI Revolution",
      status: "In Production",
      progress: 65,
      dueDate: "Aug 15",
      team: 8,
      priority: "High",
      budget: "$85,000",
      description: "Deep dive into Tesla's AI development and autonomous driving",
      metrics: {
        researchHours: 48,
        interviewsScheduled: 6,
        bRollShots: 18,
        scriptProgress: "75%"
      },
      nextMilestones: [
        "Tesla HQ B-roll",
        "AI Team Interview",
        "Demo Footage Review"
      ]
    },
    {
      title: "Stripe: The Payment Revolution",
      status: "Pre-Production",
      progress: 30,
      dueDate: "Sep 30",
      team: 6,
      priority: "Medium",
      budget: "$65,000",
      description: "How Stripe transformed online payments and business",
      metrics: {
        researchHours: 32,
        interviewsScheduled: 4,
        bRollShots: 8,
        scriptProgress: "40%"
      },
      nextMilestones: [
        "Fintech Expert Interviews",
        "Product Demo Filming",
        "Historical Timeline"
      ]
    },
    {
      title: "SpaceX's Journey to Mars",
      status: "Post-Production",
      progress: 85,
      dueDate: "Jul 20",
      team: 7,
      priority: "High",
      budget: "$95,000",
      description: "SpaceX's mission to colonize Mars and revolutionize space travel",
      metrics: {
        researchHours: 64,
        interviewsCompleted: 8,
        bRollShots: 24,
        scriptProgress: "90%"
      },
      nextMilestones: [
        "Final Edit Review",
        "Thumbnail Design",
        "SEO Optimization"
      ]
    }
  ];

  const upcomingInterviews = [
    {
      subject: "Dr. Andrej Karpathy",
      role: "Former AI Director, Tesla",
      date: "Tomorrow",
      time: "10:00 AM",
      location: "Remote",
      status: "Confirmed",
      topics: ["AI Development", "Autopilot Evolution", "Future of AI"],
      requirements: ["High-speed internet", "Backup recording", "B-roll list"]
    },
    {
      subject: "Patrick Collison",
      role: "CEO, Stripe",
      date: "Jul 18",
      time: "2:30 PM",
      location: "Stripe HQ",
      status: "Pending",
      topics: ["Founding Story", "Company Culture", "Future of Payments"],
      requirements: ["Office tour shots", "Product demos", "Team footage"]
    },
    {
      subject: "Gwynne Shotwell",
      role: "President, SpaceX",
      date: "Jul 20",
      time: "11:00 AM",
      location: "SpaceX Facility",
      status: "Confirmed",
      topics: ["Mars Mission", "Rocket Development", "Company Vision"],
      requirements: ["Facility footage", "Launch clips", "Safety briefing"]
    }
  ];

  const productionMetrics = {
    avgViewDuration: "15:42",
    retentionRate: "64%",
    clickThroughRate: "4.8%",
    subscriberConversion: "2.1%",
    avgEngagementRate: "8.9%",
    thumbnailCTR: "12.4%",
    searchImpressions: "850K",
    recommendedImpressions: "1.2M"
  };

  const recentActivity = [
    {
      type: "interview",
      title: "Interview Completed - Tesla Engineer",
      project: "Inside Tesla's AI",
      time: "2 hours ago",
      status: "success",
      details: "45 minutes of core content captured, great technical insights"
    },
    {
      type: "review",
      title: "Rough Cut Feedback - SpaceX",
      project: "SpaceX's Journey",
      time: "4 hours ago",
      status: "success",
      details: "Pacing adjustments needed in middle segment"
    },
    {
      type: "asset",
      title: "B-roll Captured - Stripe Office",
      project: "Stripe: Revolution",
      time: "Yesterday",
      status: "success",
      details: "Team culture and office environment footage"
    },
    {
      type: "review",
      title: "Thumbnail A/B Test Results",
      project: "SpaceX's Journey",
      time: "Yesterday",
      status: "success",
      details: "Version B performing 24% better"
    }
  ];

  return (
    <div className="flex h-[800px] bg-black/40 backdrop-blur-xl">
      {/* Sidebar */}
      <div className="w-64 border-r border-white/10 p-4 flex flex-col">
        <div className="px-2 mb-8">
          <img src="/svg/logo.svg" alt="Galactic" className="h-8" />
        </div>

        <nav className="space-y-1">
          <button className="w-full flex items-center gap-3 px-3 py-2 text-indigo-400 bg-indigo-500/10 rounded-lg">
            <LayoutGrid className="w-5 h-5" />
            <span>Dashboard</span>
          </button>
          <button className="w-full flex items-center gap-3 px-3 py-2 text-gray-400 hover:bg-white/5 rounded-lg">
            <Video className="w-5 h-5" />
            <span>Content</span>
          </button>
          <button className="w-full flex items-center gap-3 px-3 py-2 text-gray-400 hover:bg-white/5 rounded-lg">
            <BarChart2 className="w-5 h-5" />
            <span>Analytics</span>
          </button>
          <button className="w-full flex items-center gap-3 px-3 py-2 text-gray-400 hover:bg-white/5 rounded-lg">
            <MessageSquare className="w-5 h-5" />
            <span>Comments</span>
          </button>
          <button className="w-full flex items-center gap-3 px-3 py-2 text-gray-400 hover:bg-white/5 rounded-lg">
            <Users className="w-5 h-5" />
            <span>Team</span>
          </button>
          <button className="w-full flex items-center gap-3 px-3 py-2 text-gray-400 hover:bg-white/5 rounded-lg">
            <Settings className="w-5 h-5" />
            <span>Settings</span>
          </button>
        </nav>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col">
        {/* Top Navigation */}
        <div className="h-16 border-b border-white/10 px-6 flex items-center justify-between">
          <div className="flex items-center gap-4">
            <button className="p-2 hover:bg-white/5 rounded-lg transition-colors">
              <Search className="w-5 h-5 text-gray-400" />
            </button>
            <div className="relative">
              <input 
                type="text"
                placeholder="Search projects..."
                className="w-64 px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-sm text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500/30"
              />
            </div>
          </div>
          <div className="flex items-center gap-4">
            <button className="p-2 hover:bg-white/5 rounded-lg transition-colors relative">
              <Bell className="w-5 h-5 text-gray-400" />
              <div className="absolute top-1 right-1 w-2 h-2 bg-indigo-500 rounded-full"></div>
            </button>
            <div className="h-8 w-px bg-white/10"></div>
            <div className="flex items-center gap-3">
              <div className="w-8 h-8 rounded-full overflow-hidden">
                <img src="/images/mrbeast.jpg" alt="MrBeast" className="w-full h-full object-cover" />
              </div>
              <span className="text-sm text-white">MrBeast</span>
              <ChevronDown className="w-4 h-4 text-gray-400" />
            </div>
          </div>
        </div>

        {/* Dashboard Content */}
        <div className="flex-1 overflow-hidden p-6 space-y-6">
          {/* Top Stats */}
          <div className="grid grid-cols-4 gap-4">
            <div className="bg-white/5 rounded-xl p-4 border border-white/10">
              <div className="flex items-center gap-3 mb-3">
                <div className="w-8 h-8 rounded-lg bg-indigo-500/20 flex items-center justify-center">
                  <Eye className="w-4 h-4 text-indigo-400" />
                </div>
                <span className="text-sm text-gray-400">Total Views</span>
              </div>
              <div className="flex items-baseline gap-2">
                <span className="text-2xl font-semibold text-white">28.5M</span>
                <span className="text-sm text-green-400">+22% this month</span>
              </div>
            </div>
            <div className="bg-white/5 rounded-xl p-4 border border-white/10">
              <div className="flex items-center gap-3 mb-3">
                <div className="w-8 h-8 rounded-lg bg-purple-500/20 flex items-center justify-center">
                  <Clock className="w-4 h-4 text-purple-400" />
                </div>
                <span className="text-sm text-gray-400">Watch Time</span>
              </div>
              <div className="flex items-baseline gap-2">
                <span className="text-2xl font-semibold text-white">4.2M</span>
                <span className="text-sm text-green-400">+18% vs last month</span>
              </div>
            </div>
            <div className="bg-white/5 rounded-xl p-4 border border-white/10">
              <div className="flex items-center gap-3 mb-3">
                <div className="w-8 h-8 rounded-lg bg-blue-500/20 flex items-center justify-center">
                  <Users className="w-4 h-4 text-blue-400" />
                </div>
                <span className="text-sm text-gray-400">Subscribers</span>
              </div>
              <div className="flex items-baseline gap-2">
                <span className="text-2xl font-semibold text-white">1.2M</span>
                <span className="text-sm text-green-400">+45K this week</span>
              </div>
            </div>
            <div className="bg-white/5 rounded-xl p-4 border border-white/10">
              <div className="flex items-center gap-3 mb-3">
                <div className="w-8 h-8 rounded-lg bg-green-500/20 flex items-center justify-center">
                  <DollarSign className="w-4 h-4 text-green-400" />
                </div>
                <span className="text-sm text-gray-400">Revenue</span>
              </div>
              <div className="flex items-baseline gap-2">
                <span className="text-2xl font-semibold text-white">$842K</span>
                <span className="text-sm text-green-400">+25% this month</span>
              </div>
            </div>
          </div>

          {/* Projects Section */}
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-8">
              <div className="bg-white/5 rounded-xl border border-white/10">
                <div className="p-4 border-b border-white/10 flex items-center justify-between">
                  <h3 className="text-lg font-semibold text-white">Active Projects</h3>
                  <button className="px-3 py-1.5 bg-indigo-500/20 text-indigo-400 rounded-lg text-sm hover:bg-indigo-500/30 transition-colors flex items-center gap-2">
                    <Plus className="w-4 h-4" />
                    <span>New Video</span>
                  </button>
                </div>
                <div className="p-4 space-y-4">
                  {[
                    {
                      title: "$1,000,000 Extreme Tag",
                      status: "In Production",
                      progress: 75,
                      dueDate: "Aug 15",
                      team: 12,
                      priority: "High",
                      description: "Epic game of tag across multiple cities with huge prize money",
                      nextMilestones: [
                        "City Permits",
                        "Player Selection",
                        "Prize Setup"
                      ]
                    },
                    {
                      title: "Last To Leave Luxury Island",
                      status: "Pre-Production",
                      progress: 40,
                      dueDate: "Sep 30",
                      team: 15,
                      priority: "High",
                      description: "100 contestants compete for a private island",
                      nextMilestones: [
                        "Location Scouting",
                        "Contestant Selection",
                        "Challenge Design"
                      ]
                    },
                    {
                      title: "Giving Away 100 Cars",
                      status: "Post-Production",
                      progress: 90,
                      dueDate: "Jul 20",
                      team: 18,
                      priority: "High",
                      description: "Surprising deserving people with brand new cars",
                      nextMilestones: [
                        "Final Edit Review",
                        "Sponsor Integration",
                        "Thumbnail Design"
                      ]
                    }
                  ].map((project, index) => (
                    <div key={index} className="bg-white/5 rounded-lg p-4 hover:bg-white/10 transition-colors">
                      <div className="flex items-start justify-between mb-4">
                        <div>
                          <h4 className="text-white font-medium mb-1">{project.title}</h4>
                          <p className="text-sm text-gray-400">{project.description}</p>
                        </div>
                        <div className="flex items-center gap-3">
                          <span className={`px-2 py-1 rounded-full text-xs ${
                            project.status === 'In Production' ? 'bg-yellow-500/20 text-yellow-400' :
                            project.status === 'Post-Production' ? 'bg-green-500/20 text-green-400' :
                            'bg-blue-500/20 text-blue-400'
                          }`}>
                            {project.status}
                          </span>
                          <button className="p-1 hover:bg-white/10 rounded">
                            <MoreVertical className="w-4 h-4 text-gray-400" />
                          </button>
                        </div>
                      </div>
                      <div className="grid grid-cols-4 gap-4 mb-4">
                        <div className="space-y-1">
                          <div className="text-xs text-gray-400">Target Length</div>
                          <div className="text-sm text-white">15-20 mins</div>
                        </div>
                        <div className="space-y-1">
                          <div className="text-xs text-gray-400">Team</div>
                          <div className="flex -space-x-2">
                            {[...Array(Math.min(project.team, 5))].map((_, i) => (
                              <div key={i} className="w-6 h-6 rounded-full bg-indigo-500/20 border border-white/10 flex items-center justify-center">
                                <User className="w-3 h-3 text-indigo-400" />
                              </div>
                            ))}
                            {project.team > 5 && (
                              <div className="w-6 h-6 rounded-full bg-indigo-500/20 border border-white/10 flex items-center justify-center">
                                <span className="text-xs text-indigo-400">+{project.team - 5}</span>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="space-y-1">
                          <div className="text-xs text-gray-400">Target Upload</div>
                          <div className="text-sm text-white">{project.dueDate}</div>
                        </div>
                        <div className="space-y-1">
                          <div className="text-xs text-gray-400">Progress</div>
                          <div className="flex items-center gap-2">
                            <div className="flex-1 h-1.5 bg-white/10 rounded-full overflow-hidden">
                              <div 
                                className="h-full bg-indigo-500 rounded-full"
                                style={{ width: `${project.progress}%` }}
                              />
                            </div>
                            <span className="text-xs text-white">{project.progress}%</span>
                          </div>
                        </div>
                      </div>
                      <div className="border-t border-white/10 pt-4">
                        <h5 className="text-sm text-gray-400 mb-2">Next Steps</h5>
                        <div className="flex gap-2">
                          {project.nextMilestones.map((milestone, i) => (
                            <span key={i} className="px-2 py-1 bg-white/5 rounded text-xs text-gray-300">
                              {milestone}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Recent Activity */}
            <div className="col-span-4">
              <div className="bg-white/5 rounded-xl border border-white/10">
                <div className="p-4 border-b border-white/10 flex items-center justify-between">
                  <h3 className="text-lg font-semibold text-white">Recent Activity</h3>
                  <button className="p-2 hover:bg-white/10 rounded-lg transition-colors">
                    <Filter className="w-4 h-4 text-gray-400" />
                  </button>
                </div>
                <div className="p-4">
                  <div className="space-y-4">
                    <div className="flex items-start gap-3">
                      <div className="w-8 h-8 rounded-lg bg-green-500/20 flex items-center justify-center">
                        <FileCheck className="w-4 h-4 text-green-400" />
                      </div>
                      <div>
                        <div className="flex items-center gap-2 mb-1">
                          <h4 className="text-sm font-medium text-white">Final Cut Approved</h4>
                          <span className="text-xs text-gray-400">10m ago</span>
                        </div>
                        <p className="text-xs text-gray-400">$1M Hide and Seek Challenge</p>
                        <p className="text-xs text-gray-500 mt-1">Ready for premiere in 24 hours</p>
                      </div>
                    </div>

                    <div className="flex items-start gap-3">
                      <div className="w-8 h-8 rounded-lg bg-blue-500/20 flex items-center justify-center">
                        <Eye className="w-4 h-4 text-blue-400" />
                      </div>
                      <div>
                        <div className="flex items-center gap-2 mb-1">
                          <h4 className="text-sm font-medium text-white">50M Views Milestone</h4>
                          <span className="text-xs text-gray-400">1h ago</span>
                        </div>
                        <p className="text-xs text-gray-400">Giving Away A Private Jet</p>
                        <p className="text-xs text-gray-500 mt-1">Fastest video to reach 50M</p>
                      </div>
                    </div>

                    <div className="flex items-start gap-3">
                      <div className="w-8 h-8 rounded-lg bg-purple-500/20 flex items-center justify-center">
                        <MessageCircle className="w-4 h-4 text-purple-400" />
                      </div>
                      <div>
                        <div className="flex items-center gap-2 mb-1">
                          <h4 className="text-sm font-medium text-white">Viral Comment Thread</h4>
                          <span className="text-xs text-gray-400">2h ago</span>
                        </div>
                        <p className="text-xs text-gray-400">Last To Leave Circle Challenge</p>
                        <p className="text-xs text-gray-500 mt-1">500K+ engagement on pinned comment</p>
                      </div>
                    </div>

                    <div className="flex items-start gap-3">
                      <div className="w-8 h-8 rounded-lg bg-yellow-500/20 flex items-center justify-center">
                        <DollarSign className="w-4 h-4 text-yellow-400" />
                      </div>
                      <div>
                        <div className="flex items-center gap-2 mb-1">
                          <h4 className="text-sm font-medium text-white">Revenue Record</h4>
                          <span className="text-xs text-gray-400">3h ago</span>
                        </div>
                        <p className="text-xs text-gray-400">Monthly Revenue Update</p>
                        <p className="text-xs text-gray-500 mt-1">Exceeded $800K for the first time</p>
                      </div>
                    </div>

                    <div className="flex items-start gap-3">
                      <div className="w-8 h-8 rounded-lg bg-indigo-500/20 flex items-center justify-center">
                        <Users className="w-4 h-4 text-indigo-400" />
                      </div>
                      <div>
                        <div className="flex items-center gap-2 mb-1">
                          <h4 className="text-sm font-medium text-white">Team Milestone</h4>
                          <span className="text-xs text-gray-400">5h ago</span>
                        </div>
                        <p className="text-xs text-gray-400">100 Cars Giveaway</p>
                        <p className="text-xs text-gray-500 mt-1">All logistics teams coordinated</p>
                      </div>
                    </div>

                    <div className="flex items-start gap-3">
                      <div className="w-8 h-8 rounded-lg bg-red-500/20 flex items-center justify-center">
                        <Video className="w-4 h-4 text-red-400" />
                      </div>
                      <div>
                        <div className="flex items-center gap-2 mb-1">
                          <h4 className="text-sm font-medium text-white">Premiere Scheduled</h4>
                          <span className="text-xs text-gray-400">6h ago</span>
                        </div>
                        <p className="text-xs text-gray-400">Extreme Tag Challenge</p>
                        <p className="text-xs text-gray-500 mt-1">Set for optimal viewer time</p>
                      </div>
                    </div>

                    <div className="flex items-start gap-3">
                      <div className="w-8 h-8 rounded-lg bg-green-500/20 flex items-center justify-center">
                        <CheckCircle2 className="w-4 h-4 text-green-400" />
                      </div>
                      <div>
                        <div className="flex items-center gap-2 mb-1">
                          <h4 className="text-sm font-medium text-white">Location Secured</h4>
                          <span className="text-xs text-gray-400">8h ago</span>
                        </div>
                        <p className="text-xs text-gray-400">Luxury Island Challenge</p>
                        <p className="text-xs text-gray-500 mt-1">All permits approved</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function ProjectTimeline() {
  const tasks = {
    todo: [
      {
        title: "Stunt Planning - $1M Basketball Shot",
        dueDate: "Mar 18",
        assignee: "Chris",
        priority: "High",
        comments: 8
      },
      {
        title: "Prize Money Setup - Island Challenge",
        dueDate: "Mar 19",
        assignee: "Nolan",
        priority: "High",
        comments: 5
      },
      {
        title: "Location Scout - Mega Mall Challenge",
        dueDate: "Mar 20",
        assignee: "Chandler",
        priority: "Medium",
        comments: 3
      }
    ],
    inProgress: [
      {
        title: "Main Edit - 100 Cars Giveaway",
        dueDate: "Mar 16",
        assignee: "Karl",
        priority: "High",
        comments: 12
      },
      {
        title: "Sponsor Integration - Tag Video",
        dueDate: "Mar 17",
        assignee: "Chris",
        priority: "Medium",
        comments: 6
      },
      {
        title: "Challenge Setup - Last To Leave",
        dueDate: "Mar 18",
        assignee: "Nolan",
        priority: "High",
        comments: 9
      }
    ],
    review: [
      {
        title: "Final Cut - Extreme Tag",
        dueDate: "Mar 15",
        assignee: "Karl",
        priority: "High",
        comments: 15
      },
      {
        title: "Thumbnail Options - Island",
        dueDate: "Mar 16",
        assignee: "Chris",
        priority: "Medium",
        comments: 7
      }
    ],
    complete: [
      {
        title: "Prize Distribution - $1M Challenge",
        completedDate: "Mar 14",
        assignee: "Chandler",
        comments: 18
      },
      {
        title: "Sponsor Approval - Car Video",
        completedDate: "Mar 14",
        assignee: "Chris",
        comments: 4
      },
      {
        title: "Legal Clearance - Money Drop",
        completedDate: "Mar 13",
        assignee: "Nolan",
        comments: 6
      }
    ]
  };

  return (
    <div className="bg-black/40 backdrop-blur-xl rounded-3xl border border-white/10 p-6">
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center gap-4">
          <button className="p-2 rounded-lg bg-white/5 hover:bg-white/10 transition-colors">
            <Calendar className="w-5 h-5 text-indigo-400" />
          </button>
          <h2 className="text-xl font-semibold text-white">Project Timeline</h2>
        </div>
        <div className="flex items-center gap-4">
          <button className="px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-sm text-white hover:bg-white/10 transition-colors flex items-center gap-2">
            <Filter className="w-4 h-4" />
            <span>Filter</span>
          </button>
          <button className="px-4 py-2 bg-indigo-500/20 text-indigo-400 rounded-lg text-sm hover:bg-indigo-500/30 transition-colors flex items-center gap-2">
            <Plus className="w-4 h-4" />
            <span>Add Task</span>
          </button>
        </div>
      </div>

      <div className="grid grid-cols-4 gap-4">
        {Object.entries(tasks).map(([status, taskList]) => (
          <div key={status} className="bg-white/5 rounded-xl p-4 border border-white/10">
            <div className="flex items-center justify-between mb-4">
              <h3 className="font-semibold text-white capitalize">{status.replace(/([A-Z])/g, ' $1').trim()}</h3>
              <span className="px-2 py-1 bg-white/5 rounded-full text-xs text-gray-400">{taskList.length}</span>
            </div>
            <div className="space-y-3">
              {taskList.map((task, index) => (
                <div key={index} className="bg-white/5 rounded-lg p-3 border border-white/10">
                  <h4 className="text-sm font-medium text-white mb-2">{task.title}</h4>
                  <div className="flex items-center justify-between text-xs text-gray-400">
                    <div className="flex items-center gap-2">
                      <Clock className="w-3 h-3" />
                      <span>{'dueDate' in task ? task.dueDate : task.completedDate}</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <MessageSquare className="w-3 h-3" />
                      <span>{task.comments}</span>
                    </div>
                  </div>
                  {'assignee' in task && (
                    <div className="mt-2 flex items-center gap-2">
                      <div className="w-4 h-4 rounded-full bg-indigo-500/20 flex items-center justify-center">
                        <User className="w-2 h-2 text-indigo-400" />
                      </div>
                      <span className="text-xs text-gray-400">{task.assignee}</span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export function AnalyticsDashboard() {
  const analytics = {
    overview: [
      {
        title: "Watch Time",
        value: "24.8M hrs",
        change: "+32.5%",
        trend: "up",
        previousPeriod: "18.7M hrs"
      },
      {
        title: "Avg. View Duration",
        value: "12:45",
        change: "+18.2%",
        trend: "up",
        previousPeriod: "10:45"
      },
      {
        title: "CTR",
        value: "28.8%",
        change: "+5.1%",
        trend: "up",
        previousPeriod: "23.7%"
      },
      {
        title: "Revenue",
        value: "$842K",
        change: "+25.3%",
        trend: "up",
        previousPeriod: "$672K"
      }
    ],
    recentVideos: [
      {
        title: "I Gave Away 100 Cars To My Subscribers",
        views: "45.2M",
        retention: "78%",
        ctr: "32.4%",
        revenue: "$284K",
        publishDate: "2 days ago",
        duration: "16:42",
        comments: 158420
      },
      {
        title: "Last To Leave Luxury Island Wins It",
        views: "38.7M",
        retention: "82%",
        ctr: "29.5%",
        revenue: "$245K",
        publishDate: "4 days ago",
        duration: "22:15",
        comments: 142856
      },
      {
        title: "$1,000,000 Extreme Hide & Seek",
        views: "52.1M",
        retention: "85%",
        ctr: "34.2%",
        revenue: "$312K",
        publishDate: "1 week ago",
        duration: "19:38",
        comments: 198742
      }
    ],
    topPerformers: [
      {
        metric: "Audience Retention",
        value: "82%",
        benchmark: "65%"
      },
      {
        metric: "Subscriber Growth",
        value: "+1.2M",
        benchmark: "+800K"
      },
      {
        metric: "Avg. Watch Time",
        value: "12:45",
        benchmark: "8:30"
      }
    ]
  };

  return (
    <div className="bg-black/40 backdrop-blur-xl rounded-3xl border border-white/10 p-6">
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center gap-4">
          <button className="p-2 rounded-lg bg-white/5 hover:bg-white/10 transition-colors">
            <BarChart2 className="w-5 h-5 text-indigo-400" />
          </button>
          <h2 className="text-xl font-semibold text-white">Analytics Overview</h2>
        </div>
        <div className="flex items-center gap-4">
          <button className="px-4 py-2 bg-white/5 border border-white/10 rounded-lg text-sm text-white hover:bg-white/10 transition-colors flex items-center gap-2">
            <span>Last 30 Days</span>
            <ChevronDown className="w-4 h-4" />
          </button>
          <button className="px-4 py-2 bg-indigo-500/20 text-indigo-400 rounded-lg text-sm hover:bg-indigo-500/30 transition-colors">
            Download Report
          </button>
        </div>
      </div>

      <div className="grid grid-cols-4 gap-6 mb-8">
        {analytics.overview.map((stat, index) => (
          <div key={index} className="bg-white/5 rounded-xl p-4 border border-white/10">
            <div className="flex items-center justify-between mb-2">
              <span className="text-sm text-gray-400">{stat.title}</span>
              {stat.trend === 'up' ? (
                <TrendingUp className="w-4 h-4 text-green-400" />
              ) : (
                <TrendingDown className="w-4 h-4 text-red-400" />
              )}
            </div>
            <div className="flex items-baseline gap-2">
              <span className="text-2xl font-semibold text-white">{stat.value}</span>
              <span className={`text-sm ${
                stat.trend === 'up' ? 'text-green-400' : 'text-red-400'
              }`}>
                {stat.change}
              </span>
            </div>
            <p className="text-xs text-gray-400 mt-1">Previous: {stat.previousPeriod}</p>
          </div>
        ))}
      </div>

      <div className="grid grid-cols-1 gap-6">
        <div className="bg-white/5 rounded-xl p-4 border border-white/10">
          <div className="flex items-center justify-between mb-4">
            <h3 className="font-semibold text-white">Recent Videos Performance</h3>
            <button className="text-sm text-indigo-400 hover:text-indigo-300 transition-colors">
              View All
            </button>
          </div>
          <div className="space-y-4">
            {analytics.recentVideos.map((video, index) => (
              <div key={index} className="grid grid-cols-6 gap-4 p-3 bg-white/5 rounded-lg">
                <div className="col-span-2">
                  <h4 className="text-sm font-medium text-white truncate">{video.title}</h4>
                  <p className="text-xs text-gray-400">{video.publishDate} • {video.duration}</p>
                </div>
                <div className="flex items-center gap-2 text-sm text-gray-400">
                  <Eye className="w-4 h-4" />
                  <span>{video.views}</span>
                </div>
                <div className="flex items-center gap-2 text-sm text-gray-400">
                  <ThumbsUp className="w-4 h-4" />
                  <span>{video.retention}</span>
                </div>
                <div className="flex items-center gap-2 text-sm text-gray-400">
                  <MessageCircle className="w-4 h-4" />
                  <span>{video.comments}</span>
                </div>
                <div className="flex items-center gap-2 text-sm text-gray-400">
                  <DollarSign className="w-4 h-4" />
                  <span>{video.revenue}</span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="bg-white/5 rounded-xl p-4 border border-white/10">
          <h3 className="font-semibold text-white mb-4">Performance Benchmarks</h3>
          <div className="grid grid-cols-3 gap-4">
            {analytics.topPerformers.map((metric, index) => (
              <div key={index} className="bg-white/5 rounded-lg p-3">
                <h4 className="text-sm text-gray-400 mb-2">{metric.metric}</h4>
                <div className="flex items-baseline gap-2">
                  <span className="text-xl font-semibold text-white">{metric.value}</span>
                  <span className="text-sm text-gray-400">vs {metric.benchmark}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
} 