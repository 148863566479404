import { Shield, Lock, Eye, FileText, Server, Bell, UserCheck, ArrowRight } from 'lucide-react';

export default function PrivacyPage() {
  return (
    <div className="relative overflow-hidden">
      {/* Hero Section */}
      <section className="relative py-24">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-black">
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_60%_-20%,#4338ca,transparent_25%)]" />
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_150%,#6366f1,transparent_25%)]" />
          </div>
          <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-5" />
        </div>

        <div className="relative container mx-auto px-6">
          {/* Badge */}
          <div className="mb-8">
            <div className="relative inline-block">
              <div className="absolute -inset-1 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-full blur opacity-30" />
              <div className="relative px-6 py-2 bg-black/50 backdrop-blur-xl rounded-full border border-white/10 flex items-center gap-2">
                <Shield className="w-4 h-4 text-indigo-400" />
                <span className="text-sm font-medium bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
                  Privacy Policy
                </span>
              </div>
            </div>
          </div>

          <h1 className="text-4xl md:text-5xl font-bold mb-6">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
              Privacy Policy
            </span>
          </h1>
          
          <p className="text-lg text-gray-400 max-w-2xl mb-8">
            Last updated: March 2024
          </p>
        </div>
      </section>

      {/* Content Section */}
      <section className="relative py-16">
        <div className="container mx-auto px-6">
          <div className="prose prose-invert max-w-4xl mx-auto">
            <div className="space-y-16">
              {/* Introduction */}
              <div>
                <h2 className="text-2xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-white to-white/70">
                  Introduction
                </h2>
                <div className="space-y-4 text-gray-400">
                  <p>
                    At Galactic Media ("we," "our," or "us"), we respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our services.
                  </p>
                  <p>
                    Please read this Privacy Policy carefully. By accessing or using our services, you agree to this Privacy Policy and our Terms of Service.
                  </p>
                </div>
              </div>

              {/* Information We Collect */}
              <div>
                <h2 className="text-2xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-white to-white/70">
                  Information We Collect
                </h2>
                <div className="grid gap-8">
                  {[
                    {
                      icon: <UserCheck className="w-6 h-6" />,
                      title: "Personal Information",
                      content: "Name, email address, phone number, and business information provided during account creation or service subscription."
                    },
                    {
                      icon: <Server className="w-6 h-6" />,
                      title: "Usage Data",
                      content: "Information about how you use our services, including analytics data, preferences, and interaction patterns."
                    },
                    {
                      icon: <FileText className="w-6 h-6" />,
                      title: "Content Data",
                      content: "Information related to the content you create, upload, or manage through our services."
                    }
                  ].map((item, index) => (
                    <div key={index} className="group relative">
                      <div className="absolute -inset-px rounded-3xl bg-gradient-to-b from-indigo-500/50 via-indigo-500/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
                      <div className="relative bg-black/40 backdrop-blur-xl rounded-3xl p-8 border border-white/10">
                        <div className="flex gap-4 items-start">
                          <div className="w-12 h-12 rounded-xl bg-indigo-500/10 flex items-center justify-center flex-shrink-0">
                            <div className="text-indigo-400">
                              {item.icon}
                            </div>
                          </div>
                          <div>
                            <h3 className="text-xl font-semibold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-white to-white/70">
                              {item.title}
                            </h3>
                            <p className="text-gray-400">
                              {item.content}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* How We Use Your Information */}
              <div>
                <h2 className="text-2xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-white to-white/70">
                  How We Use Your Information
                </h2>
                <div className="space-y-4 text-gray-400">
                  <p>We use the collected information for various purposes, including:</p>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>Providing and maintaining our services</li>
                    <li>Personalizing your experience</li>
                    <li>Communicating with you about updates and services</li>
                    <li>Analyzing and improving our services</li>
                    <li>Protecting against unauthorized access and fraud</li>
                    <li>Complying with legal obligations</li>
                  </ul>
                </div>
              </div>

              {/* Data Security */}
              <div>
                <h2 className="text-2xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-white to-white/70">
                  Data Security
                </h2>
                <div className="space-y-4 text-gray-400">
                  <p>
                    We implement appropriate technical and organizational security measures to protect your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure.
                  </p>
                </div>
              </div>

              {/* Your Rights */}
              <div>
                <h2 className="text-2xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-white to-white/70">
                  Your Rights
                </h2>
                <div className="space-y-4 text-gray-400">
                  <p>You have the right to:</p>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>Access your personal information</li>
                    <li>Correct inaccurate data</li>
                    <li>Request deletion of your data</li>
                    <li>Object to processing of your data</li>
                    <li>Request data portability</li>
                    <li>Withdraw consent</li>
                  </ul>
                </div>
              </div>

              {/* Contact Us */}
              <div>
                <h2 className="text-2xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-white to-white/70">
                  Contact Us
                </h2>
                <div className="space-y-4 text-gray-400">
                  <p>
                    If you have any questions about this Privacy Policy, please contact us at:
                  </p>
                  <p>
                    Email: legal@galacticmedia.tv
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}