import { Crown, Youtube, TrendingUp, BarChart as ChartBar, ArrowRight, Target, Users, DollarSign, Globe, CheckCircle2, Laptop, Gamepad, Heart, GraduationCap, Film, Briefcase, HelpCircle, Clock, CalendarDays, Calendar, X, Search, FileEdit } from 'lucide-react';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

type Step = {
  title: string;
  description: string;
};

interface LocationState {
  channelUrl?: string;
  channelId?: string;
  subscribers?: number;
  monthlyViews?: number;
  averageViews?: number;
  totalViews?: number;
  videoCount?: number;
  channelTitle?: string;
  channelDescription?: string;
  joinDate?: string;
  uploadFrequency?: string;
}

const steps: Step[] = [
  {
    title: "Channel Analysis",
    description: "Tell us about your YouTube channel"
  },
  {
    title: "Content Strategy",
    description: "Your content and audience"
  },
  {
    title: "Business Goals",
    description: "Where you want to take your business"
  },
  {
    title: "Additional Info",
    description: "Final details about you"
  }
];

export default function ApplicationPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const channelData = location.state as LocationState & { isManualMode?: boolean };
  
  const [currentStep, setCurrentStep] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [isManualMode, setIsManualMode] = useState(!!location.state?.isManualMode);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    channelUrl: '',
    subscribers: '',
    monthlyViews: '',
    averageViews: '',
    totalViews: '',
    contentCategory: '',
    uploadFrequency: '',
    revenueStreams: [] as string[],
    goals: [] as string[],
    about: '',
    name: '',
    email: '',
    phone: ''
  });

  // Pre-fill form data when channel data is available and not in manual mode
  useEffect(() => {
    if (channelData && !isManualMode) {
      setFormData(prev => ({
        ...prev,
        channelUrl: channelData.channelUrl || '',
        subscribers: channelData.subscribers?.toString() || '',
        monthlyViews: channelData.monthlyViews?.toString() || '',
        averageViews: channelData.averageViews?.toString() || '',
        totalViews: channelData.totalViews?.toString() || '',
        uploadFrequency: channelData.uploadFrequency || '',
        about: `Channel Name: ${channelData.channelTitle || ''}\n\n${channelData.channelDescription || ''}`
      }));
    }
  }, [channelData, isManualMode]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: checked
        ? [...prev[name as keyof typeof formData] as string[], value]
        : (prev[name as keyof typeof formData] as string[]).filter(item => item !== value)
    }));
  };

  const handleNext = () => {
    setCurrentStep(prev => Math.min(prev + 1, steps.length - 1));
  };

  const handlePrevious = () => {
    setCurrentStep(prev => Math.max(prev - 1, 0));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    fetch("https://script.google.com/macros/s/AKfycbyruo8KNdeJUPAXZupjTpMo6MBlYVm9CqNDR1dnhmB1IX42sxmW0m0_foG-mM59_YsB/exec", {
      method: "POST",
      headers: { "Content-Type": "text/plain;charset=UTF-8" },
      body: JSON.stringify(formData)
    })
      .then(response => {
        if (response.ok) {
          navigate('/apply/success');
        } else {
          throw new Error("Network response was not ok");
        }
      })
      .catch(error => {
        console.error("Error:", error);
        alert("There was an error submitting your application. Please try again.");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleSearchNewChannel = () => {
    setShowModal(false);
    navigate('/apply/youtube');
  };

  const handleManualEntry = () => {
    setIsManualMode(true);
    setShowModal(false);
    setFormData({
      channelUrl: '',
      subscribers: '',
      monthlyViews: '',
      averageViews: '',
      totalViews: '',
      contentCategory: '',
      uploadFrequency: '',
      revenueStreams: [],
      goals: [],
      about: '',
      name: '',
      email: '',
      phone: ''
    });
  };

  return (
    <div className="min-h-screen bg-black text-white">
      {/* Hero Section */}
      <section className="relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-purple-900/30 via-indigo-900/20 to-black z-0">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_right,rgba(67,56,202,0.3),transparent_50%)]" />
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_bottom_left,rgba(99,102,241,0.15),transparent_50%)]" />
        </div>
        <div className="absolute inset-0">
          {[...Array(50)].map((_, i) => (
            <div
              key={i}
              className="star"
              style={{
                width: Math.random() * 2 + 1 + 'px',
                height: Math.random() * 2 + 1 + 'px',
                left: Math.random() * 100 + '%',
                top: Math.random() * 100 + '%',
                animationDelay: Math.random() * 3 + 's'
              }}
            />
          ))}
        </div>
        <div className="relative z-10 container mx-auto px-6 py-12">
          <div className="flex flex-col items-center text-center mb-12">
            <div className="glass-card px-4 py-2 rounded-full border border-indigo-500/30 flex items-center gap-2 mb-6 animate-fade-in">
              <Crown className="w-4 h-4 text-indigo-400" />
              <span className="text-sm font-medium text-indigo-400">Creator Application</span>
            </div>
            <h1 className="text-4xl md:text-5xl font-bold mb-4 tracking-tight">
              Join Our Creator Network
            </h1>
            <p className="text-gray-400 max-w-2xl">
              Complete the application below to begin your journey with us. We'll review your channel and get back to you within 48 hours.
            </p>
          </div>

          {/* Progress Steps */}
          <div className="max-w-4xl mx-auto mb-8">
            <div className="flex justify-between items-center">
              {steps.map((step, index) => (
                <div key={index} className="flex-1 relative">
                  {index < steps.length - 1 && (
                    <div className={`absolute top-5 left-1/2 w-full h-[2px] transition-colors ${
                      index < currentStep ? 'bg-indigo-500' : 'bg-gray-700'
                    }`} />
                  )}
                  <div className="flex flex-col items-center relative">
                    <div className={`w-10 h-10 rounded-full border-2 flex items-center justify-center mb-2 transition-colors bg-black ${
                      index === currentStep
                        ? 'border-indigo-500 bg-indigo-500/20 text-white'
                        : index < currentStep
                        ? 'border-indigo-500 bg-indigo-500 text-white'
                        : 'border-gray-700 text-gray-500'
                    }`}>
                      {index < currentStep ? (
                        <CheckCircle2 className="w-5 h-5" />
                      ) : (
                        <span className="text-sm">{index + 1}</span>
                      )}
                    </div>
                    <div className="text-center">
                      <div className={`text-sm font-medium mb-1 ${
                        index === currentStep ? 'text-white' : 'text-gray-500'
                      }`}>
                        {step.title}
                      </div>
                      <div className="text-xs text-gray-500 hidden md:block">
                        {step.description}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Modal */}
          {showModal && (
            <div className="fixed inset-0 z-50 flex items-center justify-center">
              <div className="absolute inset-0 bg-black/80 backdrop-blur-sm" onClick={() => setShowModal(false)} />
              <div className="relative z-10 bg-[#0D0C14] rounded-2xl p-8 max-w-md w-full mx-4 border border-white/10">
                <button
                  onClick={() => setShowModal(false)}
                  className="absolute top-4 right-4 text-gray-400 hover:text-white transition-colors"
                >
                  <X className="w-5 h-5" />
                </button>
                
                <h3 className="text-xl font-semibold mb-4">Change Channel</h3>
                <p className="text-gray-400 mb-6">
                  Would you like to search for a different channel or manually enter your information?
                </p>
                
                <div className="grid gap-4">
                  <button
                    onClick={handleSearchNewChannel}
                    className="flex items-center justify-center gap-3 w-full px-6 py-4 rounded-xl bg-indigo-500/10 border border-indigo-500/20 hover:bg-indigo-500/20 transition-colors"
                  >
                    <Search className="w-5 h-5 text-indigo-400" />
                    <span>Search for a Different Channel</span>
                  </button>
                  
                  <button
                    onClick={handleManualEntry}
                    className="flex items-center justify-center gap-3 w-full px-6 py-4 rounded-xl bg-white/5 border border-white/10 hover:bg-white/10 transition-colors"
                  >
                    <FileEdit className="w-5 h-5 text-gray-400" />
                    <span>Enter Information Manually</span>
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Form */}
          <div className="max-w-4xl mx-auto">
            <form 
              onSubmit={handleSubmit} 
              className="space-y-8 backdrop-blur-xl bg-white/5 rounded-2xl p-8 border border-white/10 shadow-2xl"
            >
              {/* Form content */}
              <div className="space-y-8">
                {/* Form Steps */}
                {currentStep === 0 && (
                  <div className="space-y-6">
                    <div className="mb-8">
                      <h2 className="text-2xl font-semibold mb-2">Channel Details</h2>
                      <p className="text-gray-400">Review your channel's analytics and metrics</p>
                    </div>
                    <div>
                      <label htmlFor="channelUrl" className="block text-sm font-medium mb-2">
                        YouTube Channel URL
                      </label>
                      <div className="relative">
                        <Youtube className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                        <input
                          type="url"
                          id="channelUrl"
                          name="channelUrl"
                          required
                          value={formData.channelUrl}
                          onChange={handleInputChange}
                          readOnly={!isManualMode}
                          className={`w-full pl-10 pr-4 py-3 bg-black/30 border border-white/10 rounded-lg ${
                            isManualMode 
                              ? 'focus:outline-none focus:border-indigo-500 text-white' 
                              : 'text-gray-400 cursor-not-allowed'
                          }`}
                          placeholder={isManualMode ? "Enter your channel URL" : ""}
                        />
                      </div>
                      {channelData?.channelId && !isManualMode && (
                        <div className="mt-2 text-xs text-gray-500">
                          Channel ID: {channelData.channelId}
                        </div>
                      )}
                    </div>

                    <div className="grid md:grid-cols-2 gap-6">
                      <div>
                        <label htmlFor="subscribers" className="block text-sm font-medium mb-2">
                          Total Subscribers
                        </label>
                        <div className="relative">
                          <Users className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                          <input
                            type="text"
                            id="subscribers"
                            name="subscribers"
                            required
                            value={isManualMode ? formData.subscribers : Number(formData.subscribers).toLocaleString()}
                            onChange={handleInputChange}
                            readOnly={!isManualMode}
                            className={`w-full pl-10 pr-4 py-3 bg-black/30 border border-white/10 rounded-lg ${
                              isManualMode 
                                ? 'focus:outline-none focus:border-indigo-500 text-white' 
                                : 'text-gray-400 cursor-not-allowed'
                            }`}
                            placeholder={isManualMode ? "Enter your subscriber count" : ""}
                          />
                        </div>
                      </div>
                      <div>
                        <label htmlFor="monthlyViews" className="block text-sm font-medium mb-2">
                          Monthly Views
                        </label>
                        <div className="relative">
                          <ChartBar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                          <input
                            type="text"
                            id="monthlyViews"
                            name="monthlyViews"
                            required
                            value={isManualMode ? formData.monthlyViews : Number(formData.monthlyViews).toLocaleString()}
                            onChange={handleInputChange}
                            readOnly={!isManualMode}
                            className={`w-full pl-10 pr-4 py-3 bg-black/30 border border-white/10 rounded-lg ${
                              isManualMode 
                                ? 'focus:outline-none focus:border-indigo-500 text-white' 
                                : 'text-gray-400 cursor-not-allowed'
                            }`}
                            placeholder={isManualMode ? "Enter your monthly views" : ""}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="grid md:grid-cols-2 gap-6">
                      <div>
                        <label htmlFor="averageViews" className="block text-sm font-medium mb-2">
                          Average Views per Video
                        </label>
                        <div className="relative">
                          <TrendingUp className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                          <input
                            type="text"
                            id="averageViews"
                            name="averageViews"
                            value={isManualMode ? formData.averageViews : channelData?.averageViews?.toLocaleString() || ''}
                            onChange={handleInputChange}
                            readOnly={!isManualMode}
                            className={`w-full pl-10 pr-4 py-3 bg-black/30 border border-white/10 rounded-lg ${
                              isManualMode 
                                ? 'focus:outline-none focus:border-indigo-500 text-white' 
                                : 'text-gray-400 cursor-not-allowed'
                            }`}
                            placeholder={isManualMode ? "Enter your average views per video" : ""}
                          />
                        </div>
                      </div>
                      <div>
                        <label htmlFor="totalViews" className="block text-sm font-medium mb-2">
                          Total Views
                        </label>
                        <div className="relative">
                          <Globe className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                          <input
                            type="text"
                            id="totalViews"
                            name="totalViews"
                            value={isManualMode ? formData.totalViews : channelData?.totalViews?.toLocaleString() || ''}
                            onChange={handleInputChange}
                            readOnly={!isManualMode}
                            className={`w-full pl-10 pr-4 py-3 bg-black/30 border border-white/10 rounded-lg ${
                              isManualMode 
                                ? 'focus:outline-none focus:border-indigo-500 text-white' 
                                : 'text-gray-400 cursor-not-allowed'
                            }`}
                            placeholder={isManualMode ? "Enter your total channel views" : ""}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {currentStep === 1 && (
                  <div className="space-y-6">
                    <div className="mb-8">
                      <h2 className="text-2xl font-semibold mb-2">Content Strategy</h2>
                      <p className="text-gray-400">Tell us about your content and upload schedule</p>
                    </div>
                    <div>
                      <label htmlFor="contentCategory" className="block text-sm font-medium mb-2">
                        Primary Content Category
                      </label>
                      <div className="relative group">
                        <select
                          id="contentCategory"
                          name="contentCategory"
                          required
                          value={formData.contentCategory}
                          onChange={handleInputChange}
                          className="w-full pl-10 pr-10 py-3 bg-black/50 border border-white/10 rounded-lg focus:outline-none focus:border-indigo-500 transition-colors appearance-none"
                        >
                          <option value="" disabled>Select a category</option>
                          <option value="tech" className="flex items-center gap-2 py-2 px-4 bg-[#0D0C14] hover:bg-black/60">
                            Technology
                          </option>
                          <option value="gaming" className="py-2 px-4 bg-[#0D0C14] hover:bg-black/60">
                            Gaming
                          </option>
                          <option value="lifestyle" className="py-2 px-4 bg-[#0D0C14] hover:bg-black/60">
                            Lifestyle
                          </option>
                          <option value="education" className="py-2 px-4 bg-[#0D0C14] hover:bg-black/60">
                            Education
                          </option>
                          <option value="entertainment" className="py-2 px-4 bg-[#0D0C14] hover:bg-black/60">
                            Entertainment
                          </option>
                          <option value="business" className="py-2 px-4 bg-[#0D0C14] hover:bg-black/60">
                            Business
                          </option>
                          <option value="other" className="py-2 px-4 bg-[#0D0C14] hover:bg-black/60">
                            Other
                          </option>
                        </select>
                        {/* Category Icon */}
                        <div className="pointer-events-none absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                          {formData.contentCategory === 'tech' && <Laptop className="w-4 h-4" />}
                          {formData.contentCategory === 'gaming' && <Gamepad className="w-4 h-4" />}
                          {formData.contentCategory === 'lifestyle' && <Heart className="w-4 h-4" />}
                          {formData.contentCategory === 'education' && <GraduationCap className="w-4 h-4" />}
                          {formData.contentCategory === 'entertainment' && <Film className="w-4 h-4" />}
                          {formData.contentCategory === 'business' && <Briefcase className="w-4 h-4" />}
                          {formData.contentCategory === 'other' && <HelpCircle className="w-4 h-4" />}
                          {!formData.contentCategory && <Film className="w-4 h-4" />}
                        </div>
                        {/* Chevron Icon */}
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-400">
                          <svg 
                            className="fill-current h-4 w-4 transition-transform duration-200 group-focus-within:rotate-180" 
                            xmlns="http://www.w3.org/2000/svg" 
                            viewBox="0 0 20 20"
                          >
                            <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div>
                      <label htmlFor="uploadFrequency" className="block text-sm font-medium mb-2">
                        Upload Frequency
                      </label>
                      <div className="relative group">
                        <select
                          id="uploadFrequency"
                          name="uploadFrequency"
                          required
                          value={formData.uploadFrequency}
                          onChange={handleInputChange}
                          className="w-full pl-10 pr-10 py-3 bg-black/50 border border-white/10 rounded-lg focus:outline-none focus:border-indigo-500 transition-colors appearance-none"
                        >
                          <option value="" disabled>Select frequency</option>
                          <option value="daily" className="py-2 px-4 bg-[#0D0C14] hover:bg-black/60">Daily</option>
                          <option value="2-3-times-week" className="py-2 px-4 bg-[#0D0C14] hover:bg-black/60">2-3 Times per Week</option>
                          <option value="weekly" className="py-2 px-4 bg-[#0D0C14] hover:bg-black/60">Weekly</option>
                          <option value="biweekly" className="py-2 px-4 bg-[#0D0C14] hover:bg-black/60">Biweekly</option>
                          <option value="monthly" className="py-2 px-4 bg-[#0D0C14] hover:bg-black/60">Monthly</option>
                          <option value="less-than-monthly" className="py-2 px-4 bg-[#0D0C14] hover:bg-black/60">Less than Monthly</option>
                        </select>
                        {/* Frequency Icon */}
                        <div className="pointer-events-none absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                          {formData.uploadFrequency === 'daily' && <Clock className="w-4 h-4" />}
                          {formData.uploadFrequency === '2-3-times-week' && <CalendarDays className="w-4 h-4" />}
                          {formData.uploadFrequency === 'weekly' && <Calendar className="w-4 h-4" />}
                          {formData.uploadFrequency === 'biweekly' && <Calendar className="w-4 h-4" />}
                          {formData.uploadFrequency === 'monthly' && <Calendar className="w-4 h-4" />}
                          {formData.uploadFrequency === 'less-than-monthly' && <Calendar className="w-4 h-4" />}
                          {!formData.uploadFrequency && <Clock className="w-4 h-4" />}
                        </div>
                        {/* Chevron Icon */}
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-400">
                          <svg 
                            className="fill-current h-4 w-4 transition-transform duration-200 group-focus-within:rotate-180" 
                            xmlns="http://www.w3.org/2000/svg" 
                            viewBox="0 0 20 20"
                          >
                            <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {currentStep === 2 && (
                  <div className="space-y-6">
                    <div className="mb-8">
                      <h2 className="text-2xl font-semibold mb-2">Revenue & Goals</h2>
                      <p className="text-gray-400">Share your current revenue streams and future goals</p>
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-4">
                        Current Revenue Streams
                      </label>
                      <div className="grid md:grid-cols-2 gap-4">
                        {[
                          { name: 'Ad Revenue', icon: <DollarSign className="w-4 h-4" /> },
                          { name: 'Brand Deals', icon: <Briefcase className="w-4 h-4" /> },
                          { name: 'Merchandise', icon: <TrendingUp className="w-4 h-4" /> },
                          { name: 'Channel Memberships', icon: <Users className="w-4 h-4" /> },
                          { name: 'Super Chat', icon: <Globe className="w-4 h-4" /> },
                          { name: 'Affiliate Marketing', icon: <Target className="w-4 h-4" /> },
                          { name: 'Course Sales', icon: <GraduationCap className="w-4 h-4" /> },
                          { name: 'Consulting', icon: <Film className="w-4 h-4" /> }
                        ].map(({ name, icon }) => (
                          <label
                            key={name}
                            className={`flex items-center gap-3 p-4 rounded-lg border transition-colors ${
                              formData.revenueStreams.includes(name)
                                ? 'border-indigo-500 bg-indigo-500/10'
                                : 'border-white/10 hover:border-white/20'
                            }`}
                          >
                            <input
                              type="checkbox"
                              name="revenueStreams"
                              value={name}
                              checked={formData.revenueStreams.includes(name)}
                              onChange={handleCheckboxChange}
                              className="hidden"
                            />
                            <div className={`flex items-center justify-center w-8 h-8 rounded-full transition-colors ${
                              formData.revenueStreams.includes(name)
                                ? 'bg-indigo-500 text-white'
                                : 'bg-black/50 text-gray-400'
                            }`}>
                              {icon}
                            </div>
                            <span className="text-sm font-medium">{name}</span>
                          </label>
                        ))}
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium mb-4">
                        Areas of Interest for Growth
                      </label>
                      <div className="grid md:grid-cols-2 gap-4">
                        {[
                          { name: 'Increase Revenue', icon: <DollarSign className="w-4 h-4" /> },
                          { name: 'Grow Audience', icon: <Users className="w-4 h-4" /> },
                          { name: 'Launch Products', icon: <Target className="w-4 h-4" /> },
                          { name: 'Build Team', icon: <Users className="w-4 h-4" /> },
                          { name: 'Improve Production Quality', icon: <Film className="w-4 h-4" /> },
                          { name: 'Expand to New Platforms', icon: <Globe className="w-4 h-4" /> },
                          { name: 'Get More Brand Deals', icon: <Briefcase className="w-4 h-4" /> },
                          { name: 'Create Multiple Channels', icon: <Youtube className="w-4 h-4" /> }
                        ].map(({ name, icon }) => (
                          <label
                            key={name}
                            className={`flex items-center gap-3 p-4 rounded-lg border transition-colors ${
                              formData.goals.includes(name)
                                ? 'border-indigo-500 bg-indigo-500/10'
                                : 'border-white/10 hover:border-white/20'
                            }`}
                          >
                            <input
                              type="checkbox"
                              name="goals"
                              value={name}
                              checked={formData.goals.includes(name)}
                              onChange={handleCheckboxChange}
                              className="hidden"
                            />
                            <div className={`flex items-center justify-center w-8 h-8 rounded-full transition-colors ${
                              formData.goals.includes(name)
                                ? 'bg-indigo-500 text-white'
                                : 'bg-black/50 text-gray-400'
                            }`}>
                              {icon}
                            </div>
                            <span className="text-sm font-medium">{name}</span>
                          </label>
                        ))}
                      </div>
                    </div>
                  </div>
                )}

                {currentStep === 3 && (
                  <div className="space-y-6">
                    <div>
                      <label htmlFor="about" className="block text-sm font-medium mb-2">
                        Tell us about yourself and your channel
                      </label>
                      <textarea
                        id="about"
                        name="about"
                        value={formData.about}
                        required
                        onChange={handleInputChange}
                        rows={4}
                        className="w-full px-4 py-3 bg-black/50 border border-white/10 rounded-lg focus:outline-none focus:border-indigo-500 transition-colors"
                        placeholder="Share your story, achievements, and vision..."
                      />
                    </div>
                    <div className="grid md:grid-cols-2 gap-6">
                      <div>
                        <label htmlFor="name" className="block text-sm font-medium mb-2">
                          Full Name
                        </label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          required
                          value={formData.name}
                          onChange={handleInputChange}
                          className="w-full px-4 py-3 bg-black/50 border border-white/10 rounded-lg focus:outline-none focus:border-indigo-500 transition-colors"
                        />
                      </div>
                      <div>
                        <label htmlFor="email" className="block text-sm font-medium mb-2">
                          Email
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          required
                          value={formData.email}
                          onChange={handleInputChange}
                          className="w-full px-4 py-3 bg-black/50 border border-white/10 rounded-lg focus:outline-none focus:border-indigo-500 transition-colors"
                        />
                      </div>
                    </div>
                    <div>
                      <label htmlFor="phone" className="block text-sm font-medium mb-2">
                        Phone Number <span className="text-gray-500">(Optional)</span>
                      </label>
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        className="w-full px-4 py-3 bg-black/50 border border-white/10 rounded-lg focus:outline-none focus:border-indigo-500 transition-colors"
                        placeholder="Enter your phone number (optional)"
                      />
                    </div>
                  </div>
                )}
              </div>

              {/* Navigation Buttons */}
              <div className="flex justify-between items-center pt-8">
                {currentStep > 0 ? (
                  <button
                    type="button"
                    onClick={handlePrevious}
                    className="flex items-center gap-2 px-6 py-3 rounded-xl bg-white/5 border border-white/10 hover:bg-white/10 transition-colors"
                  >
                    <ArrowRight className="w-4 h-4 rotate-180" />
                    <span>Previous</span>
                  </button>
                ) : (
                  <div>{/* Empty div for spacing */}</div>
                )}

                {currentStep < steps.length - 1 ? (
                  <button
                    type="button"
                    onClick={handleNext}
                    className="flex items-center gap-2 px-6 py-3 rounded-xl bg-gradient-to-r from-indigo-500/10 to-purple-500/10 border border-white/10 hover:border-white/20 transition-colors"
                  >
                    <span>Next</span>
                    <ArrowRight className="w-4 h-4" />
                  </button>
                ) : (
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`flex items-center gap-2 px-8 py-4 rounded-xl border font-medium transition-all ${
                      isSubmitting
                        ? 'bg-indigo-500/50 border-transparent cursor-not-allowed'
                        : 'bg-gradient-to-r from-indigo-500 to-purple-500 hover:from-indigo-600 hover:to-purple-600 border-transparent'
                    }`}
                  >
                    {isSubmitting ? (
                      <>
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Submitting...
                      </>
                    ) : (
                      <>
                        <span>Submit Application</span>
                        <ArrowRight className="w-5 h-5" />
                      </>
                    )}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}