import { createBrowserRouter } from 'react-router-dom';
import RootLayout from './layouts/RootLayout';
import HomePage from './pages/HomePage';
import CreatorsPage from './pages/CreatorsPage';
import StudioPage from './pages/StudioPage';
import AboutPage from './pages/AboutPage';
import CareersPage from './pages/CareersPage';
import PrivacyPage from './pages/PrivacyPage';
import TermsPage from './pages/TermsPage';
import ContactPage from './pages/ContactPage';
import YouTubeApplicationPage from './pages/YouTubeApplicationPage';
import ApplicationPage from './pages/ApplicationPage';
import ApplicationSuccessPage from './pages/ApplicationSuccessPage';
import InvestmentsPage from './pages/InvestmentsPage';
import TechnologyPage from './pages/TechnologyPage';
import BrandingPage from './pages/BrandingPage';
import MockupPage from './pages/MockupPage';
import ContractorApplicationPage from './pages/ContractorApplicationPage';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      { index: true, element: <HomePage /> },
      { path: 'creators', element: <CreatorsPage /> },
      { path: 'studio', element: <StudioPage /> },
      { path: 'investments', element: <InvestmentsPage /> },
      { path: 'technology', element: <TechnologyPage /> },
      { path: 'branding', element: <BrandingPage /> },
      { path: 'about', element: <AboutPage /> },
      { path: 'careers', element: <CareersPage /> },
      { path: 'contractor-application', element: <ContractorApplicationPage /> },
      { path: 'privacy', element: <PrivacyPage /> },
      { path: 'terms', element: <TermsPage /> },
      { path: 'contact', element: <ContactPage /> },
      { path: 'apply', element: <ApplicationPage /> },
      { path: 'apply/youtube', element: <YouTubeApplicationPage /> },
      { path: 'apply/success', element: <ApplicationSuccessPage /> },
      { path: 'mockups', element: <MockupPage /> }
    ],
  },
]);