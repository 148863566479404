import { Crown, Youtube, TrendingUp, BarChart as ChartBar, ArrowRight, Target, Users, DollarSign, Globe, Loader2, CheckCircle2, XCircle } from 'lucide-react';
import { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getChannelData } from '../lib/youtube';
import { getMonthlyViews } from '../lib/socialblade';

const MAX_DESCRIPTION_LENGTH = 150;

export default function YouTubeApplicationPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [channelUrl, setChannelUrl] = useState('');
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [monthlyViewsProgress, setMonthlyViewsProgress] = useState(0);
  const [monthlyViews, setMonthlyViews] = useState<number | null>(null);
  const [monthlyViewsError, setMonthlyViewsError] = useState<string | null>(null);
  const [channelData, setChannelData] = useState<{
    id: string;
    title: string;
    description: string;
    thumbnails?: any;
    statistics: {
      subscribers: number;
      totalViews: number;
      videoCount: number;
      averageViews: number;
    };
    joinDate: string;
    country?: string;
    username: string;
    recentVideos: Array<{
      publishedAt: string;
      isShort: boolean;
      duration: string;
    }>;
  } | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let isMounted = true;
    let progressInterval: number | null = null;

    const fetchMonthlyViews = async (username: string) => {
      try {
        // Start progress animation
        let progress = 0;
        progressInterval = window.setInterval(() => {
          if (!isMounted) return;
          // Slowly increase progress up to 85%
          if (progress < 85) {
            progress += 0.5;
            setMonthlyViewsProgress(progress / 100);
          }
        }, 50);

        const result = await getMonthlyViews(username);
        
        if (!isMounted) return;

        // Clear the progress interval
        if (progressInterval) {
          window.clearInterval(progressInterval);
          progressInterval = null;
        }

        if (result.error) {
          setMonthlyViewsError(result.error);
          setMonthlyViews(null);
          setMonthlyViewsProgress(1);
        } else {
          setMonthlyViewsError(null);
          
          // Complete the progress animation first
          const currentProgress = Math.min(85, progress);
          for (let i = currentProgress; i <= 99; i += 1) {
            if (!isMounted) return;
            setMonthlyViewsProgress(i / 100);
            await new Promise(resolve => setTimeout(resolve, 20));
          }

          // Set the monthly views
          setMonthlyViews(result.monthly_views || null);
          
          // Finally, set progress to 100%
          setMonthlyViewsProgress(1);
        }
      } catch (err) {
        if (!isMounted) return;
        if (progressInterval) {
          window.clearInterval(progressInterval);
          progressInterval = null;
        }
        setMonthlyViewsError('Failed to fetch monthly views');
        setMonthlyViews(null);
        setMonthlyViewsProgress(1);
      }
    };

    if (channelData?.username) {
      // Reset states when starting a new fetch
      setMonthlyViewsError(null);
      setMonthlyViews(null);
      setMonthlyViewsProgress(0);
      fetchMonthlyViews(channelData.username);
    }

    return () => {
      isMounted = false;
      if (progressInterval) {
        window.clearInterval(progressInterval);
      }
    };
  }, [channelData?.username]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const data = await getChannelData(channelUrl);
      setChannelData(data);

      if (data.statistics.subscribers < 100000) {
        setError('Your channel needs at least 100,000 subscribers to qualify for our program.');
      }
    } catch (err) {
      setError('Failed to fetch channel data. Please try again.');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  const truncatedDescription = useMemo(() => {
    if (!channelData?.description) return '';
    if (channelData.description.length <= MAX_DESCRIPTION_LENGTH) return channelData.description;
    return showFullDescription
      ? channelData.description
      : `${channelData.description.slice(0, MAX_DESCRIPTION_LENGTH)}...`;
  }, [channelData?.description, showFullDescription]);

  const shouldTruncate = useMemo(() => {
    return channelData?.description && channelData.description.length > MAX_DESCRIPTION_LENGTH;
  }, [channelData?.description]);

  const meetsAllRequirements = useMemo(() => {
    if (!channelData || monthlyViews === null) return false;
    return (
      monthlyViews >= 500000 &&
      channelData.statistics.subscribers >= 100000 &&
      channelData.statistics.averageViews >= 20000
    );
  }, [channelData, monthlyViews]);

  const calculateUploadFrequency = (videos: Array<{ publishedAt: string; isShort: boolean; duration: string }>) => {
    if (!videos || videos.length < 2) return null;

    // Sort videos by publish date, newest first
    const sortedDates = videos
      .map(v => new Date(v.publishedAt))
      .sort((a, b) => b.getTime() - a.getTime());

    // Calculate average days between uploads
    let totalDays = 0;
    for (let i = 0; i < sortedDates.length - 1; i++) {
      const daysDiff = (sortedDates[i].getTime() - sortedDates[i + 1].getTime()) / (1000 * 60 * 60 * 24);
      totalDays += daysDiff;
    }
    
    // If we have less than 2 regular videos after filtering, return less-than-monthly
    if (sortedDates.length < 2) return 'less-than-monthly';
    
    const averageDays = totalDays / (sortedDates.length - 1);

    // Map average days to frequency categories
    if (averageDays <= 1) return 'daily';
    if (averageDays <= 3.5) return '2-3-times-week';
    if (averageDays <= 7) return 'weekly';
    if (averageDays <= 14) return 'biweekly';
    if (averageDays <= 31) return 'monthly';
    return 'less-than-monthly';
  };

  return (
    <div className="min-h-screen bg-black text-white">
      <section className="relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-purple-900/30 via-indigo-900/20 to-black z-0">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_right,rgba(67,56,202,0.3),transparent_50%)]" />
        </div>
        <div className="absolute inset-0">
          {[...Array(50)].map((_, i) => (
            <div
              key={i}
              className="star"
              style={{
                width: Math.random() * 2 + 1 + 'px',
                height: Math.random() * 2 + 1 + 'px',
                left: Math.random() * 100 + '%',
                top: Math.random() * 100 + '%',
                animationDelay: Math.random() * 3 + 's'
              }}
            />
          ))}
        </div>
        <div className="relative z-10 container mx-auto px-6 py-24">
          <div className="flex items-center mb-8">
            <div className="glass-card px-4 py-2 rounded-full border border-indigo-500/30 flex items-center gap-2">
              <Crown className="w-4 h-4 text-indigo-400" />
              <span className="text-sm font-medium text-indigo-400">Quick Application</span>
            </div>
          </div>
          <h1 className="text-5xl md:text-7xl font-bold max-w-4xl mb-8 leading-tight">
            Apply with Your{' '}
            <span className="relative inline-block">
              <span className="absolute inset-0 bg-gradient-to-r from-indigo-400 to-blue-400 blur-2xl opacity-30" />
              <span className="relative gradient-text">
                YouTube Channel
              </span>
            </span>
          </h1>
          <p className="text-xl text-gray-300 max-w-2xl mb-12 leading-relaxed">
            Let us analyze your channel and provide instant feedback on your eligibility.
          </p>

          <div className="bg-[#0D0C14] backdrop-blur-md rounded-xl p-8 max-w-4xl mx-auto">
            <form 
              onSubmit={handleSubmit} 
              name="youtube-application" 
              method="POST" 
              data-netlify="true" 
              className="space-y-8"
            >
              {/* Hidden input for Netlify */}
              <input type="hidden" name="form-name" value="youtube-application" />
              <div>
                <label htmlFor="channelUrl" className="block text-sm font-medium mb-2">
                  YouTube Channel URL or Username
                </label>
                <div className="relative">
                  <Youtube className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  <input
                    type="text"
                    id="channelUrl"
                    value={channelUrl}
                    onChange={(e) => setChannelUrl(e.target.value)}
                    className="w-full pl-10 pr-4 py-3 bg-black/50 border border-white/10 rounded-lg focus:outline-none focus:border-indigo-500 transition-colors"
                    placeholder="Enter channel URL, username, or @handle"
                    required
                  />
                </div>
              </div>

              {error && (
                <div className="p-4 bg-red-500/10 border border-red-500/20 rounded-lg text-red-400">
                  {error}
                </div>
              )}

              <button
                type="submit"
                disabled={loading}
                className="w-full bg-gradient-to-r from-indigo-500 to-blue-500 hover:from-indigo-600 hover:to-blue-600 px-8 py-4 rounded-xl text-lg transition-all shadow-lg shadow-indigo-500/25 hover:shadow-indigo-500/40 flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {loading ? (
                  <>
                    <Loader2 className="w-5 h-5 mr-2 animate-spin" />
                    Analyzing Channel...
                  </>
                ) : (
                  <>
                    Analyze Channel
                    <ArrowRight className="ml-2 w-5 h-5" />
                  </>
                )}
              </button>

              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-white/10"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-[#0D0C14] text-gray-400">or</span>
                </div>
              </div>

              <button
                type="button"
                onClick={() => navigate('/apply', { 
                  state: { 
                    isManualMode: true 
                  }
                })}
                className="w-full bg-white/5 hover:bg-white/10 border border-white/10 px-8 py-4 rounded-xl text-lg transition-all flex items-center justify-center"
              >
                Apply Manually
                <ArrowRight className="ml-2 w-5 h-5" />
              </button>
            </form>

            {/* Channel Analysis Results */}
            {channelData && (
              <div className="mt-8 space-y-6 bg-[#0D0C14] backdrop-blur-sm rounded-xl p-8">
                {!meetsAllRequirements && monthlyViewsProgress === 1 && (
                  <div className="p-6 bg-amber-500/10 border-l-4 border-amber-500 rounded-lg">
                    <div className="flex items-start gap-3">
                      <div className="p-1">
                        <Target className="w-5 h-5 text-amber-500" />
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold text-amber-500 mb-1">Almost There!</h3>
                        <p className="text-gray-300 leading-relaxed">
                          While your channel hasn't quite reached all our typical metrics yet, we encourage you to continue with your application. 
                          Our team will personally review your channel, taking into account factors beyond just the numbers, such as content quality and growth potential.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {/* Channel Profile */}
                <div className="flex items-center gap-4 mb-8">
                  {channelData.thumbnails?.default && (
                    <div className="flex-shrink-0">
                      <img
                        src={channelData.thumbnails.default.url}
                        alt={`${channelData.title} profile`}
                        className="w-20 h-20 rounded-full ring-4 ring-indigo-500/20"
                      />
                    </div>
                  )}
                  <div>
                    <h2 className="text-2xl font-bold">{channelData.title}</h2>
                    <div className="text-gray-400">
                      <p className="whitespace-pre-line">{truncatedDescription}</p>
                      {shouldTruncate && (
                        <button
                          onClick={() => setShowFullDescription(!showFullDescription)}
                          className="text-indigo-400 hover:text-indigo-300 transition-colors mt-1 text-sm font-medium"
                        >
                          {showFullDescription ? 'Show less' : 'Show more'}
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                <div className="grid md:grid-cols-2 gap-4">
                  <div className="glass-card p-4 rounded-lg hover:bg-white/5 transition-colors">
                    <div className="flex items-center justify-between mb-1">
                      <div className="text-sm text-gray-400">Monthly Views</div>
                      {monthlyViews !== null && (
                        monthlyViews >= 500000 ? (
                          <div className="flex items-center gap-1 text-xs text-emerald-400">
                            <CheckCircle2 className="w-4 h-4" />
                            <span>Eligible</span>
                          </div>
                        ) : (
                          <div className="flex items-center gap-1 text-xs text-red-400">
                            <XCircle className="w-4 h-4" />
                            <span>500K+ Required</span>
                          </div>
                        )
                      )}
                    </div>
                    <div className="text-lg font-medium text-white">
                      {monthlyViewsProgress < 1 ? (
                        <div className="flex items-center">
                          <div className="relative w-8 h-8 mr-3">
                            <svg className="w-full h-full" viewBox="0 0 36 36">
                              <circle
                                cx="18"
                                cy="18"
                                r="16"
                                fill="none"
                                className="stroke-current text-indigo-900"
                                strokeWidth="2"
                              />
                              <circle
                                cx="18"
                                cy="18"
                                r="16"
                                fill="none"
                                className="stroke-current text-indigo-400"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeDasharray={`${monthlyViewsProgress * 100.53}, 100`}
                                transform="rotate(-90 18 18)"
                              />
                            </svg>
                            <div className="absolute inset-0 flex items-center justify-center text-xs font-medium text-indigo-400">
                              {Math.round(monthlyViewsProgress * 100)}%
                            </div>
                          </div>
                          <span>Calculating...</span>
                        </div>
                      ) : monthlyViewsError ? (
                        <div className="text-red-400">Unable to fetch monthly views</div>
                      ) : (
                        monthlyViews?.toLocaleString() || 'N/A'
                      )}
                    </div>
                  </div>
                  <div className="glass-card p-4 rounded-lg hover:bg-white/5 transition-colors">
                    <div className="flex items-center justify-between mb-1">
                      <div className="text-sm text-gray-400">Subscribers</div>
                      {channelData.statistics.subscribers >= 100000 ? (
                        <div className="flex items-center gap-1 text-xs text-emerald-400">
                          <CheckCircle2 className="w-4 h-4" />
                          <span>Eligible</span>
                        </div>
                      ) : (
                        <div className="flex items-center gap-1 text-xs text-red-400">
                          <XCircle className="w-4 h-4" />
                          <span>100K+ Required</span>
                        </div>
                      )}
                    </div>
                    <div className="text-lg font-medium text-white">{channelData.statistics.subscribers.toLocaleString()}</div>
                  </div>
                  <div className="glass-card p-4 rounded-lg hover:bg-white/5 transition-colors">
                    <div className="text-sm text-gray-400">Total Views</div>
                    <div className="text-lg font-medium text-white">{channelData.statistics.totalViews.toLocaleString()}</div>
                  </div>
                  <div className="glass-card p-4 rounded-lg hover:bg-white/5 transition-colors">
                    <div className="text-sm text-gray-400">Video Count</div>
                    <div className="text-lg font-medium text-white">{channelData.statistics.videoCount.toLocaleString()}</div>
                  </div>
                  <div className="glass-card p-4 rounded-lg hover:bg-white/5 transition-colors">
                    <div className="flex items-center justify-between mb-1">
                      <div className="text-sm text-gray-400">Average Views per Video</div>
                      {channelData.statistics.averageViews >= 20000 ? (
                        <div className="flex items-center gap-1 text-xs text-emerald-400">
                          <CheckCircle2 className="w-4 h-4" />
                          <span>Eligible</span>
                        </div>
                      ) : (
                        <div className="flex items-center gap-1 text-xs text-red-400">
                          <XCircle className="w-4 h-4" />
                          <span>20K+ Required</span>
                        </div>
                      )}
                    </div>
                    <div className="text-lg font-medium text-white">{channelData.statistics.averageViews.toLocaleString()}</div>
                  </div>
                  <div className="glass-card p-4 rounded-lg hover:bg-white/5 transition-colors">
                    <div className="text-sm text-gray-400">Channel Created</div>
                    <div className="text-lg font-medium text-white">
                      {new Date(channelData.joinDate).toLocaleDateString('en-US', {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric'
                      })}
                    </div>
                  </div>
                </div>

                <div className="flex justify-end">
                  <button
                    onClick={() => navigate('/apply', {
                      state: {
                        channelUrl: `https://www.youtube.com/@${channelData.username}`,
                        channelId: channelData.id,
                        subscribers: channelData.statistics.subscribers,
                        monthlyViews: monthlyViews || 0,
                        averageViews: channelData.statistics.averageViews,
                        totalViews: channelData.statistics.totalViews,
                        videoCount: channelData.statistics.videoCount,
                        channelTitle: channelData.title,
                        channelDescription: channelData.description,
                        joinDate: channelData.joinDate,
                        uploadFrequency: calculateUploadFrequency(channelData.recentVideos)
                      }
                    })}
                    disabled={channelData.statistics.subscribers < 100000}
                    className="bg-gradient-to-r from-indigo-500 to-blue-500 hover:from-indigo-600 hover:to-blue-600 px-8 py-3 rounded-lg text-white transition-all flex items-center gap-2"
                  >
                    Continue Application
                    <ArrowRight className="w-4 h-4" />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}