import { 
  Crown, Diamond, Rocket, Target, 
  BarChart as ChartBar, ArrowRight, 
  Zap, Star, Shield, Trophy, 
  Sparkles, DollarSign, Briefcase,
  Headphones, ShoppingBag, Users,
  Building2, Scale, Plane,
  GraduationCap, Wallet, HandshakeIcon,
  BarChart3, ScrollText, Gift,
  CheckCircle2, TrendingUp, Award,
  ChevronDown, Youtube, Loader2, X,
  Search, FileEdit, Clock, CalendarDays,
  Calendar, Heart, Laptop, Gamepad,
  Film, HelpCircle, Globe, Utensils,
  Dumbbell, Hammer, Palette, Music,
  Newspaper, Car, FlaskConical
} from 'lucide-react';
import { Link } from 'react-router-dom';
import React, { useState, useMemo, useEffect } from 'react';
import { getChannelData } from '../lib/youtube';
import { getMonthlyViews } from '../lib/socialblade';
import { CreatorApplicationForm } from '../components/CreatorApplicationForm';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../components/ui/Select';

const MAX_DESCRIPTION_LENGTH = 150;

// Analysis Console Component
const AnalysisConsole = ({ logs }: { logs: Array<{ type: 'info' | 'success' | 'warning' | 'error' | 'data', message: string }> }) => {
  const consoleRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (consoleRef.current) {
      consoleRef.current.scrollTop = consoleRef.current.scrollHeight;
    }
  }, [logs]);

  return (
    <div className="relative">
      <div className="absolute -inset-[1px] bg-gradient-to-b from-indigo-500/20 to-purple-500/20 rounded-lg blur-sm" />
      <div className="relative bg-black/90 backdrop-blur-xl rounded-lg border border-white/10 overflow-hidden">
        {/* Console Header */}
        <div className="px-4 py-2 border-b border-white/10 flex items-center gap-2">
          <div className="flex gap-1.5">
            <div className="w-3 h-3 rounded-full bg-red-500/20 border border-red-500/30" />
            <div className="w-3 h-3 rounded-full bg-yellow-500/20 border border-yellow-500/30" />
            <div className="w-3 h-3 rounded-full bg-green-500/20 border border-green-500/30" />
          </div>
          <div className="text-xs text-gray-500 font-mono">Channel Analysis</div>
        </div>
        
        {/* Console Content */}
        <div 
          ref={consoleRef}
          className="h-[400px] overflow-y-auto p-4 font-mono text-sm space-y-1 scrollbar-thin scrollbar-thumb-white/10 scrollbar-track-transparent"
        >
          {logs.map((log, index) => (
            <div 
              key={index}
              className={`flex items-start gap-2 ${
                log.type === 'success' ? 'text-emerald-400' :
                log.type === 'warning' ? 'text-yellow-400' :
                log.type === 'error' ? 'text-red-400' :
                log.type === 'data' ? 'text-indigo-400' :
                'text-gray-400'
              }`}
            >
              <span className="flex-shrink-0 opacity-50">
                {log.type === 'success' && '✓'}
                {log.type === 'warning' && '⚠'}
                {log.type === 'error' && '✕'}
                {log.type === 'info' && 'ℹ'}
                {log.type === 'data' && '→'}
              </span>
              <span className="whitespace-pre-wrap">{log.message}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default function CreatorsPage() {
  const [activeService, setActiveService] = useState(0);
  const [loading, setLoading] = useState(false);
  const [channelUrl, setChannelUrl] = useState('');
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [monthlyViewsProgress, setMonthlyViewsProgress] = useState(0);
  const [monthlyViews, setMonthlyViews] = useState<number | null>(null);
  const [monthlyViewsError, setMonthlyViewsError] = useState<string | null>(null);
  const [channelData, setChannelData] = useState<{
    id: string;
    title: string;
    description: string;
    thumbnails?: any;
    statistics: {
      subscribers: number;
      totalViews: number;
      videoCount: number;
      averageViews: number;
    };
    joinDate: string;
    country?: string;
    username: string;
    recentVideos: Array<{
      publishedAt: string;
      isShort: boolean;
      duration: string;
      viewCount: number;
    }>;
  } | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    channelUrl: '',
    subscribers: '',
    monthlyViews: '',
    averageViews: '',
    totalViews: '',
    contentCategory: '',
    uploadFrequency: '',
    revenueStreams: [] as string[],
    goals: [] as string[],
    additionalGoals: '',
    about: '',
    name: '',
    email: '',
    phone: ''
  });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [analysisLogs, setAnalysisLogs] = useState<Array<{ type: 'info' | 'success' | 'warning' | 'error' | 'data', message: string }>>([]);
  const [isAnalyzing, setIsAnalyzing] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: checked
        ? [...prev[name as keyof typeof formData] as string[], value]
        : (prev[name as keyof typeof formData] as string[]).filter(item => item !== value)
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch("https://script.google.com/macros/s/AKfycbyruo8KNdeJUPAXZupjTpMo6MBlYVm9CqNDR1dnhmB1IX42sxmW0m0_foG-mM59_YsB/exec", {
        method: "POST",
        headers: { "Content-Type": "text/plain;charset=UTF-8" },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        // Reset form and show success message
        setFormData({
          channelUrl: '',
          subscribers: '',
          monthlyViews: '',
          averageViews: '',
          totalViews: '',
          contentCategory: '',
          uploadFrequency: '',
          revenueStreams: [],
          goals: [],
          additionalGoals: '',
          about: '',
          name: '',
          email: '',
          phone: ''
        });
        setCurrentStep(0);
        setChannelData(null);
        setShowSuccessModal(true);
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("There was an error submitting your application. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const addLog = (type: 'info' | 'success' | 'warning' | 'error' | 'data', message: string) => {
    setAnalysisLogs(prev => [...prev, { type, message }]);
  };

  const simulateAnalysis = async (data: any) => {
    // Initial connection
    addLog('info', 'Initializing channel analysis...');
    await new Promise(resolve => setTimeout(resolve, 800));
    
    // Channel metadata
    addLog('info', 'Fetching channel metadata...');
    await new Promise(resolve => setTimeout(resolve, 1000));
    addLog('data', `Channel: ${data.title}`);
    addLog('data', `Created: ${new Date(data.joinDate).toLocaleDateString()}`);
    addLog('data', `Location: ${data.country || 'Not specified'}`);
    addLog('data', `Custom URL: @${data.username}`);
    addLog('success', 'Channel metadata retrieved successfully');
    await new Promise(resolve => setTimeout(resolve, 800));
    
    // Subscriber analysis
    addLog('info', 'Analyzing subscriber metrics...');
    await new Promise(resolve => setTimeout(resolve, 1000));
    const subCount = data.statistics.subscribers.toLocaleString();
    addLog('data', `Current subscriber count: ${subCount}`);
    const meetsSubRequirement = data.statistics.subscribers >= 100000;
    addLog(
      meetsSubRequirement ? 'success' : 'warning',
      `Subscriber eligibility: ${meetsSubRequirement ? 'Meets requirements' : 'Below threshold'}`
    );
    await new Promise(resolve => setTimeout(resolve, 800));
    
    // Video library analysis
    addLog('info', 'Analyzing video library...');
    await new Promise(resolve => setTimeout(resolve, 1000));
    const totalVideos = data.statistics.videoCount.toLocaleString();
    addLog('data', `Total videos published: ${totalVideos}`);
    addLog('data', `Total channel views: ${data.statistics.totalViews.toLocaleString()}`);
    addLog('data', `Average views per video: ${data.statistics.averageViews.toLocaleString()}`);
    
    // Recent uploads analysis
    addLog('info', 'Processing video library...');
    await new Promise(resolve => setTimeout(resolve, 1200));
    
    // Show all videos being analyzed
    addLog('data', 'Analyzing recent uploads (last 12 months):');
    const regularVideos = data.recentVideos.filter((v: any) => !v.isShort);
    const shortVideos = data.recentVideos.filter((v: any) => v.isShort);
    
    addLog('data', `Found ${regularVideos.length} regular videos and ${shortVideos.length} shorts`);
    await new Promise(resolve => setTimeout(resolve, 800));
    
    // Display regular videos
    addLog('info', 'Regular video analysis:');
    for (const video of regularVideos) {
      await new Promise(resolve => setTimeout(resolve, 200));
      const date = new Date(video.publishedAt).toLocaleDateString();
      const views = video.viewCount.toLocaleString();
      addLog('data', `  • ${date} - ${views} views`);
    }
    
    // Upload pattern analysis
    addLog('info', 'Calculating upload patterns...');
    await new Promise(resolve => setTimeout(resolve, 1000));
    
    // Calculate intervals between uploads
    const uploadIntervals = [];
    for (let i = 0; i < regularVideos.length - 1; i++) {
      const current = new Date(regularVideos[i].publishedAt);
      const next = new Date(regularVideos[i + 1].publishedAt);
      const days = Math.round((current.getTime() - next.getTime()) / (1000 * 60 * 60 * 24));
      uploadIntervals.push(days);
    }
    
    // Show upload interval analysis
    if (uploadIntervals.length > 0) {
      const avgInterval = uploadIntervals.reduce((a, b) => a + b, 0) / uploadIntervals.length;
      addLog('data', `Average days between uploads: ${avgInterval.toFixed(1)}`);
      addLog('data', 'Recent upload intervals:');
      uploadIntervals.slice(0, 5).forEach((interval, i) => {
        addLog('data', `  • ${interval} days between videos ${i + 1} and ${i + 2}`);
      });
    }
    
    // View pattern analysis
    addLog('info', 'Analyzing view patterns...');
    await new Promise(resolve => setTimeout(resolve, 1000));
    
    // Calculate and show view velocity
    const v60 = calculateV60(data.recentVideos);
    addLog('data', `30-day view average: ${v60.toLocaleString()}`);
    
    // Show view distribution
    const viewCounts = regularVideos.map((v: { viewCount: number }) => v.viewCount);
    const maxViews = Math.max(...viewCounts);
    const minViews = Math.min(...viewCounts);
    const medianViews = viewCounts.sort((a: number, b: number) => a - b)[Math.floor(viewCounts.length / 2)];
    
    addLog('data', 'View distribution:');
    addLog('data', `  • Highest performing: ${maxViews.toLocaleString()} views`);
    addLog('data', `  • Median performance: ${medianViews.toLocaleString()} views`);
    addLog('data', `  • Lowest performing: ${minViews.toLocaleString()} views`);
    
    await new Promise(resolve => setTimeout(resolve, 800));
    
    // Revenue potential analysis
    addLog('info', 'Calculating revenue potential...');
    await new Promise(resolve => setTimeout(resolve, 1000));
    const annualVolume = calculateAnnualUploadVolume(data.recentVideos);
    addLog('data', `Estimated annual uploads: ${annualVolume} videos`);
    const brandRate = calculateBrandDealRate(data.statistics.averageViews);
    addLog('data', `Estimated brand deal rate: $${brandRate.toLocaleString()} per integration`);
    const yearlyPotential = Math.round(brandRate * annualVolume * 0.9);
    addLog('data', `Potential yearly brand revenue: $${yearlyPotential.toLocaleString()}`);
    
    // Final eligibility check
    addLog('info', 'Running final eligibility checks...');
    await new Promise(resolve => setTimeout(resolve, 1000));
    const meetsAllCriteria = data.statistics.subscribers >= 100000 && v60 >= 20000;
    
    // Show detailed eligibility breakdown
    addLog('data', 'Eligibility criteria:');
    addLog(data.statistics.subscribers >= 100000 ? 'success' : 'warning',
      `• Subscribers: ${data.statistics.subscribers.toLocaleString()} (${data.statistics.subscribers >= 100000 ? '✓' : '✗'})`);
    addLog(v60 >= 20000 ? 'success' : 'warning',
      `• 30-day views: ${v60.toLocaleString()} (${v60 >= 20000 ? '✓' : '✗'})`);
    addLog(annualVolume >= 24 ? 'success' : 'warning',
      `• Upload frequency: ${annualVolume} videos/year (${annualVolume >= 24 ? '✓' : '✗'})`);
    
    await new Promise(resolve => setTimeout(resolve, 600));
    addLog(
      meetsAllCriteria ? 'success' : 'warning',
      meetsAllCriteria ? 'Channel meets all basic eligibility criteria' : 'Some eligibility criteria not met'
    );
    addLog('success', 'Channel analysis complete');
  };

  const handleChannelSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setIsAnalyzing(true);
    setError(null);
    setAnalysisLogs([]);
    setMonthlyViewsError(null);
    setMonthlyViews(null);
    setMonthlyViewsProgress(0);

    try {
      // First get the channel data
      const data = await getChannelData(channelUrl);

      // Start socialblade fetch in parallel with analysis
      const socialbladeFetch = (async () => {
        // Start progress animation
        let progress = 0;
        const progressInterval = window.setInterval(() => {
          // Slowly increase progress up to 85%
          if (progress < 85) {
            progress += 0.5;
            setMonthlyViewsProgress(progress / 100);
          }
        }, 50);

        try {
          const result = await getMonthlyViews(data.username);
          
          // Clear the progress interval
          window.clearInterval(progressInterval);

          if (result.error) {
            setMonthlyViewsError(result.error);
            setMonthlyViews(null);
            setMonthlyViewsProgress(1);
          } else {
            // Complete the progress animation
            for (let i = Math.min(85, progress); i <= 99; i += 1) {
              setMonthlyViewsProgress(i / 100);
              await new Promise(resolve => setTimeout(resolve, 20));
            }

            // Set the monthly views
            const monthlyViewsValue = result.monthly_views || null;
            setMonthlyViews(monthlyViewsValue);
            
            // Update form data with the monthly views
            if (monthlyViewsValue) {
              setFormData(prev => ({
                ...prev,
                monthlyViews: monthlyViewsValue.toString()
              }));
            }
            
            setMonthlyViewsProgress(1);
          }
        } catch (err) {
          window.clearInterval(progressInterval);
          setMonthlyViewsError('Failed to fetch monthly views');
          setMonthlyViews(null);
          setMonthlyViewsProgress(1);
        }
      })();

      // Run analysis simulation and socialblade fetch in parallel
      await Promise.all([
        simulateAnalysis(data),
        socialbladeFetch
      ]);

      setChannelData(data);

      if (data.statistics.subscribers < 100000) {
        setError('Your channel needs at least 100,000 subscribers to qualify for our program.');
      } else {
        // Pre-fill form data with channel info
        const annualVolume = calculateAnnualUploadVolume(data.recentVideos);
        const uploadFrequency = (() => {
          if (annualVolume >= 300) return 'daily';
          if (annualVolume >= 100) return '2-3-times-week';
          if (annualVolume >= 45) return 'weekly';
          if (annualVolume >= 24) return 'biweekly';
          if (annualVolume >= 12) return 'monthly';
          return 'less-than-monthly';
        })();

        setFormData(prev => ({
          ...prev,
          channelUrl: `https://www.youtube.com/@${data.username}`,
          subscribers: data.statistics.subscribers.toString(),
          averageViews: data.statistics.averageViews.toString(),
          totalViews: data.statistics.totalViews.toString(),
          about: `Channel Name: ${data.title}\n\n${data.description}`,
          uploadFrequency
        }));
      }
    } catch (err) {
      setError('Failed to fetch channel data. Please try again, or feel free to contact us directly.');
      console.error('Error:', err);
    } finally {
      await new Promise(resolve => setTimeout(resolve, 1000)); // Extra delay for visual polish
      setLoading(false);
      setIsAnalyzing(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    let progressInterval: number | null = null;

    const fetchMonthlyViews = async (username: string) => {
      try {
        // Start progress animation
        let progress = 0;
        progressInterval = window.setInterval(() => {
          if (!isMounted) return;
          // Slowly increase progress up to 85%
          if (progress < 85) {
            progress += 0.5;
            setMonthlyViewsProgress(progress / 100);
          }
        }, 50);

        const result = await getMonthlyViews(username);
        
        if (!isMounted) return;

        // Clear the progress interval
        if (progressInterval) {
          window.clearInterval(progressInterval);
          progressInterval = null;
        }

        if (result.error) {
          setMonthlyViewsError(result.error);
          setMonthlyViews(null);
          setMonthlyViewsProgress(1);
        } else {
          setMonthlyViewsError(null);
          
          // Complete the progress animation first
          const currentProgress = Math.min(85, progress);
          for (let i = currentProgress; i <= 99; i += 1) {
            if (!isMounted) return;
            setMonthlyViewsProgress(i / 100);
            await new Promise(resolve => setTimeout(resolve, 20));
          }

          // Set the monthly views
          const monthlyViewsValue = result.monthly_views || null;
          setMonthlyViews(monthlyViewsValue);
          
          // Update form data with the monthly views
          if (monthlyViewsValue) {
            setFormData(prev => ({
              ...prev,
              monthlyViews: monthlyViewsValue.toString()
            }));
          }
          
          // Finally, set progress to 100%
          setMonthlyViewsProgress(1);
        }
      } catch (err) {
        if (!isMounted) return;
        if (progressInterval) {
          window.clearInterval(progressInterval);
          progressInterval = null;
        }
        setMonthlyViewsError('Failed to fetch monthly views');
        setMonthlyViews(null);
        setMonthlyViewsProgress(1);
      }
    };

    if (channelData?.username) {
      // Reset states when starting a new fetch
      setMonthlyViewsError(null);
      setMonthlyViews(null);
      setMonthlyViewsProgress(0);
      fetchMonthlyViews(channelData.username);
    }

    return () => {
      isMounted = false;
      if (progressInterval) {
        window.clearInterval(progressInterval);
      }
    };
  }, [channelData?.username]);

  const truncatedDescription = useMemo(() => {
    if (!channelData?.description) return '';
    if (channelData.description.length <= MAX_DESCRIPTION_LENGTH) return channelData.description;
    return showFullDescription
      ? channelData.description
      : `${channelData.description.slice(0, MAX_DESCRIPTION_LENGTH)}...`;
  }, [channelData?.description, showFullDescription]);

  const shouldTruncate = useMemo(() => {
    return channelData?.description && channelData.description.length > MAX_DESCRIPTION_LENGTH;
  }, [channelData?.description]);

  const meetsAllRequirements = useMemo(() => {
    if (!channelData || monthlyViews === null) return false;
    return (
      monthlyViews >= 500000 &&
      channelData.statistics.subscribers >= 100000 &&
      channelData.statistics.averageViews >= 20000
    );
  }, [channelData, monthlyViews]);

  const calculateUploadFrequency = (videos: Array<{ publishedAt: string; isShort: boolean; duration: string }>) => {
    if (!videos || videos.length < 2) return null;

    // Sort videos by publish date, newest first
    const sortedDates = videos
      .map(v => new Date(v.publishedAt))
      .sort((a, b) => b.getTime() - a.getTime());

    // Calculate average days between uploads
    let totalDays = 0;
    for (let i = 0; i < sortedDates.length - 1; i++) {
      const daysDiff = (sortedDates[i].getTime() - sortedDates[i + 1].getTime()) / (1000 * 60 * 60 * 24);
      totalDays += daysDiff;
    }
    
    // If we have less than 2 regular videos after filtering, return less-than-monthly
    if (sortedDates.length < 2) return 'less-than-monthly';
    
    const averageDays = totalDays / (sortedDates.length - 1);

    // Map average days to frequency categories
    if (averageDays <= 1) return 'daily';
    if (averageDays <= 3.5) return '2-3-times-week';
    if (averageDays <= 7) return 'weekly';
    if (averageDays <= 14) return 'biweekly';
    if (averageDays <= 31) return 'monthly';
    return 'less-than-monthly';
  };

  const calculateAnnualUploadVolume = (videos: Array<{ publishedAt: string; isShort: boolean; duration: string }>) => {
    if (!videos || videos.length < 2) {
      console.log('No videos or insufficient videos provided');
      return 0;
    }

    console.log('Initial videos array:', videos.length, 'videos');

    // Filter out Shorts and future dates, then sort by published date (newest first)
    const now = new Date();
    const regularVideos = videos
      .filter(video => {
        const publishDate = new Date(video.publishedAt);
        if (video.isShort) {
          console.log('Filtering out Short:', { publishedAt: video.publishedAt });
          return false;
        }
        if (publishDate > now) {
          console.log('Filtering out future date:', { publishedAt: video.publishedAt });
          return false;
        }
        return true;
      })
      .sort((a, b) => {
        const dateA = new Date(a.publishedAt).getTime();
        const dateB = new Date(b.publishedAt).getTime();
        console.log('Comparing dates:', {
          dateA: new Date(a.publishedAt).toISOString(),
          dateB: new Date(b.publishedAt).toISOString(),
          result: dateB - dateA
        });
        return dateB - dateA;
      })
      .slice(0, 25);

    console.log('After filtering and sorting:', regularVideos.length, 'regular videos');
    console.log('Regular videos dates:', regularVideos.map(v => ({
      publishedAt: v.publishedAt,
      date: new Date(v.publishedAt).toISOString()
    })));

    if (regularVideos.length < 2) {
      console.log('Insufficient regular videos after filtering');
      return 0;
    }

    // Now map to dates (already in descending order)
    const sortedDates = regularVideos.map(v => new Date(v.publishedAt));

    // Compute intervals in days between consecutive videos
    const intervals: number[] = [];
    const MAX_INTERVAL = 30; // Cap any interval at 30 days to prevent large gaps from skewing average
    
    for (let i = 0; i < sortedDates.length - 1; i++) {
      const daysDiff = (sortedDates[i].getTime() - sortedDates[i + 1].getTime()) / (1000 * 60 * 60 * 24);
      // Cap the interval at MAX_INTERVAL days
      const cappedDiff = Math.min(daysDiff, MAX_INTERVAL);
      intervals.push(cappedDiff);
      console.log(`Interval ${i}:`, {
        video1: sortedDates[i].toISOString(),
        video2: sortedDates[i + 1].toISOString(),
        actualDaysDiff: daysDiff,
        cappedDaysDiff: cappedDiff
      });
    }

    // Apply weighted average with stronger recency bias
    const lambda = 2; // Increased recency bias (was 3)
    let weightedSum = 0;
    let totalWeight = 0;
    intervals.forEach((interval, index) => {
      // Weight decays exponentially with the index, but more quickly now
      const weight = Math.exp(-index / lambda);
      weightedSum += interval * weight;
      totalWeight += weight;
      console.log(`Weighted interval ${index}:`, {
        interval,
        weight,
        weightedValue: interval * weight
      });
    });

    // Compute the weighted average interval
    const weightedAvgInterval = weightedSum / totalWeight;
    console.log('Weighted average interval:', weightedAvgInterval, 'days');

    // Calculate the estimated annual upload frequency
    const estimatedUploadsPerYear = Math.round(365 / weightedAvgInterval);
    console.log('Estimated uploads per year:', estimatedUploadsPerYear);
    
    return estimatedUploadsPerYear;
  };

  const calculateBrandDealRate = (views: number) => {
    const a = 0.0685;
    const b = 0.961;
    const rate = a * (Math.pow(views, b));
    return Math.round(rate);
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(amount);
  };

  const calculateV60 = (
    videos: Array<{ publishedAt: string; isShort: boolean; viewCount: number }>
  ): number => {
    // Input validation
    if (!videos || !Array.isArray(videos)) {
      console.log('No videos array provided');
      return 0;
    }

    console.log('Initial videos array:', videos.length, 'videos');
    console.log('Raw videos data:', videos);

    // Filter out shorts and take up to 25 recent videos
    const regularVideos = videos
      .filter(video => {
        if (video.isShort) {
          console.log('Filtering out Short:', { publishedAt: video.publishedAt, viewCount: video.viewCount });
          return false;
        }
        return true;
      })
      .slice(0, 25);

    console.log(`Found ${regularVideos.length} regular videos after filtering out Shorts`);
    console.log('Regular videos:', regularVideos);
    
    if (regularVideos.length === 0) {
      console.log('No regular videos found after filtering');
      return 0;
    }

    const now = new Date();
    let totalV30 = 0;
    let count = 0;

    regularVideos.forEach((video, index) => {
      // Validate video data
      if (!video.publishedAt || typeof video.viewCount !== 'number' || isNaN(video.viewCount)) {
        console.log(`Invalid video data at index ${index}:`, {
          publishedAt: video.publishedAt,
          viewCount: video.viewCount,
          viewCountType: typeof video.viewCount
        });
        return;
      }

      // Parse the date and handle potential invalid dates
      const publishedDate = new Date(video.publishedAt);
      if (isNaN(publishedDate.getTime())) {
        console.log(`Invalid published date at index ${index}:`, video.publishedAt);
        return;
      }

      const daysSincePublish = (now.getTime() - publishedDate.getTime()) / (1000 * 60 * 60 * 24);

      // Skip invalid dates
      if (daysSincePublish <= 0) {
        console.log(`Skipping video ${index} due to invalid date:`, {
          publishedAt: video.publishedAt,
          daysSincePublish,
          now: now.toISOString(),
          publishedDate: publishedDate.toISOString()
        });
        return;
      }

      let estimatedV30 = 0;

      if (daysSincePublish < 30) {
        // For videos under 30 days, extrapolate linearly
        if (daysSincePublish < 1) {
          console.log(`Skipping video ${index} - too new (less than 1 day old):`, {
            publishedAt: video.publishedAt,
            daysSincePublish
          });
          return;
        }
        estimatedV30 = (video.viewCount / daysSincePublish) * 30;
      } else {
        // For videos over 30 days old, assume 60% of views came in first 30 days
        estimatedV30 = video.viewCount * 0.6;
      }

      // Validate estimation
      if (!isNaN(estimatedV30) && isFinite(estimatedV30) && estimatedV30 >= 0) {
        totalV30 += estimatedV30;
        count++;
        console.log(`Video ${index} V30 estimation:`, {
          viewCount: video.viewCount,
          daysSincePublish,
          estimatedV30,
          publishedAt: video.publishedAt
        });
      } else {
        console.log(`Invalid V30 estimation for video ${index}:`, {
          viewCount: video.viewCount,
          daysSincePublish,
          estimatedV30,
          publishedAt: video.publishedAt
        });
      }
    });

    // Return 0 if no valid videos were processed
    if (count === 0) {
      console.log('No valid videos were processed for V30 calculation');
      return 0;
    }

    const averageV30 = Math.round(totalV30 / count);
    console.log('Final V30 calculation:', {
      totalV30,
      count,
      averageV30,
      firstVideo: regularVideos[0],
      lastVideo: regularVideos[regularVideos.length - 1]
    });
    return averageV30;
  };

  const services = [
    {
      icon: <HandshakeIcon className="w-8 h-8" />,
      title: "Brand Deal & Sponsorship",
      tagline: "Maximizing Your Partnership Value", 
      description: "We handle everything from deal sourcing to payment processing, ensuring you get the best partnerships with minimal hassle.",
      container: "bg-gradient-to-br from-indigo-500/10 to-purple-500/10 border-2 border-indigo-500/20 hover:border-indigo-500/40 rounded-2xl p-8 transition-all duration-300 hover:shadow-xl hover:shadow-indigo-500/10 outline outline-1 outline-indigo-500/5",
      features: [
        { icon: <Target />, title: "Deal Sourcing", description: "Active outreach to secure high-value sponsorships" },
        { icon: <ScrollText />, title: "Contract Handling", description: "Expert negotiation for the best rates and terms" },
        { icon: <Wallet />, title: "Payment Processing", description: "Automated invoicing and payment collection" },
        { icon: <Shield />, title: "Brand Safety", description: "Legal compliance and contract protection" },
        { icon: <BarChart3 />, title: "Campaign Analytics", description: "Detailed performance tracking and reporting" },
        { icon: <Sparkles />, title: "Brand Matching", description: "Brand alignment and opportunity matching" }
      ]
    },
    {
      icon: <DollarSign className="w-8 h-8" />,
      title: "Revenue Expansion",
      tagline: "Diversify Your Income Streams",
      description: "Transform your influence into multiple revenue streams with our proven monetization strategies.",
      container: "bg-gradient-to-br from-blue-500/10 to-cyan-500/10 border-2 border-blue-500/20 hover:border-blue-500/40 rounded-2xl p-8 transition-all duration-300 hover:shadow-xl hover:shadow-blue-500/10 outline outline-1 outline-blue-500/5",
      features: [
        { icon: <Headphones />, title: "Podcast Development", description: "Launch and monetize your podcast" },
        { icon: <GraduationCap />, title: "Course Creation", description: "Package your knowledge into premium products" },
        { icon: <ShoppingBag />, title: "Merchandise", description: "Custom product development and fulfillment" },
        { icon: <Users />, title: "Community Building", description: "Monetized fan communities and memberships" },
        { icon: <Zap />, title: "Content Licensing", description: "Syndication and licensing opportunities" },
        { icon: <Star />, title: "Premium Experiences", description: "Exclusive events and fan experiences" }
      ]
    },
    {
      icon: <Briefcase className="w-8 h-8" />,
      title: "Financial & Legal Concierge",
      tagline: "Your Business Backend Handled",
      description: "Comprehensive business management services to optimize your operation and protect your assets.",
      container: "bg-gradient-to-br from-purple-500/10 to-pink-500/10 border-2 border-purple-500/20 hover:border-purple-500/40 rounded-2xl p-8 transition-all duration-300 hover:shadow-xl hover:shadow-purple-500/10 outline outline-1 outline-purple-500/5",
      features: [
        { icon: <Building2 />, title: "Business Formation", description: "Strategic business structure setup" },
        { icon: <Scale />, title: "Legal Services", description: "Contract reviews and legal protection" },
        { icon: <ChartBar />, title: "Tax Strategy", description: "Tax optimization and bookkeeping" },
        { icon: <Wallet />, title: "Banking", description: "Business credit and banking optimization" },
        { icon: <Trophy />, title: "Wealth Planning", description: "Investment and diversification strategies" },
        { icon: <Shield />, title: "Risk Management", description: "Insurance and liability protection" }
      ]
    },
    {
      icon: <Gift className="w-8 h-8" />,
      title: "Lifestyle & Concierge",
      tagline: "Elevating Your Creator Lifestyle",
      description: "VIP treatment and exclusive access to make your life easier and more luxurious.",
      container: "bg-gradient-to-br from-emerald-500/10 to-teal-500/10 border-2 border-emerald-500/20 hover:border-emerald-500/40 rounded-2xl p-8 transition-all duration-300 hover:shadow-xl hover:shadow-emerald-500/10 outline outline-1 outline-emerald-500/5",
      features: [
        { icon: <Plane />, title: "Travel Services", description: "VIP event access and luxury travel booking" },
        { icon: <Crown />, title: "Luxury Partnerships", description: "Exclusive brand and lifestyle deals" },
        { icon: <Users />, title: "Private Network", description: "Elite mastermind and networking events" },
        { icon: <Star />, title: "Wellness", description: "Premium health and wellness services" },
        { icon: <Shield />, title: "Personal Security", description: "Security and lifestyle assistance" },
        { icon: <Rocket />, title: "VIP Experiences", description: "Exclusive access to premium events and venues" }
      ]
    }
  ];

  const stats = [
    { icon: <HandshakeIcon className="w-5 h-5" />, text: "$25M+ in Brand Deals Executed" },
    { icon: <Users className="w-5 h-5" />, text: "10+ Creators Signed" },
    { icon: <Award className="w-5 h-5" />, text: "100+ Successful Campaigns" },
    { icon: <DollarSign className="w-5 h-5" />, text: "$20M+ Creator Revenue" },
    { icon: <CheckCircle2 className="w-5 h-5" />, text: "500M Annual Views" }
  ];

  const steps: Array<{
    title: string;
    description: string;
  }> = [
    {
      title: "Channel Analysis",
      description: "Tell us about your YouTube channel"
    },
    {
      title: "Monetization Strategy",
      description: "Your revenue streams"
    },
    {
      title: "Business Goals",
      description: "Where you want to take your business"
    },
    {
      title: "Additional Info",
      description: "Final details about you"
    }
  ];

  const handleNext = () => {
    setCurrentStep(prev => Math.min(prev + 1, steps.length - 1));
  };

  const handlePrevious = () => {
    setCurrentStep(prev => Math.max(prev - 1, 0));
  };

  const scrollToServices = () => {
    const servicesSection = document.getElementById('services-section');
    if (servicesSection) {
      servicesSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="relative">
      {/* Main Background */}
      <div className="absolute inset-0">
        {/* Base color */}
        <div className="absolute inset-0 bg-[#0a0426]" />
        
        {/* Radial gradients for color variation */}
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_60%_10%,#4338ca,transparent_70%)] opacity-40" />
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_20%_40%,#6366f1,transparent_70%)] opacity-40" />
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_80%_70%,#8b5cf6,transparent_70%)] opacity-35" />
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_90%,#6366f1,transparent_70%)] opacity-35" />
        </div>

        {/* Grid overlay */}
        <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-20" />

        {/* Darkening gradient overlay */}
        <div className="absolute inset-0 bg-gradient-to-b from-transparent from-50% via-black/50 via-75% to-black" />
      </div>

      {/* Content */}
      <div className="relative">
        {/* Hero Section */}
        <section className="relative min-h-screen flex flex-col justify-center py-24">
          {/* Hero Background Elements */}
          <div className="absolute inset-0">
            {/* Animated Background Elements */}
            <div className="absolute inset-0 overflow-hidden hidden sm:block">
              {/* Stars */}
              <div className="absolute inset-0">
                <div className="absolute top-[10%] left-[15%] w-1 h-1 bg-white rounded-full opacity-0 animate-[twinkle_3s_ease-in-out_infinite,fade-in_1s_ease-in-out_forwards]" />
                <div className="absolute top-[15%] right-[25%] w-1 h-1 bg-white rounded-full opacity-0 animate-[twinkle_3s_ease-in-out_infinite_0.5s,fade-in_1s_ease-in-out_forwards_0.2s]" />
                <div className="absolute top-[25%] left-[35%] w-1 h-1 bg-white rounded-full opacity-0 animate-[twinkle_3s_ease-in-out_infinite_1s,fade-in_1s_ease-in-out_forwards_0.4s]" />
                <div className="absolute top-[40%] right-[15%] w-1 h-1 bg-white rounded-full opacity-0 animate-[twinkle_3s_ease-in-out_infinite_1.5s,fade-in_1s_ease-in-out_forwards_0.6s]" />
                <div className="absolute top-[60%] left-[25%] w-1 h-1 bg-white rounded-full opacity-0 animate-[twinkle_3s_ease-in-out_infinite_2s,fade-in_1s_ease-in-out_forwards_0.8s]" />
                <div className="absolute top-[75%] right-[35%] w-1 h-1 bg-white rounded-full opacity-0 animate-[twinkle_3s_ease-in-out_infinite_2.5s,fade-in_1s_ease-in-out_forwards_1s]" />
                
                {/* Larger stars with glow */}
                <div className="absolute top-[20%] left-[45%] w-2 h-2 bg-indigo-400 rounded-full opacity-0 blur-[1px] animate-[twinkle_4s_ease-in-out_infinite_0.7s,fade-in_1s_ease-in-out_forwards_0.3s]" />
                <div className="absolute top-[50%] right-[40%] w-2 h-2 bg-purple-400 rounded-full opacity-0 blur-[1px] animate-[twinkle_4s_ease-in-out_infinite_1.7s,fade-in_1s_ease-in-out_forwards_0.7s]" />
                <div className="absolute top-[80%] left-[20%] w-2 h-2 bg-indigo-400 rounded-full opacity-0 blur-[1px] animate-[twinkle_4s_ease-in-out_infinite_2.7s,fade-in_1s_ease-in-out_forwards_1.1s]" />
              </div>

              {/* Floating Elements */}
              <div className="absolute top-1/4 left-1/4 w-64 h-64 bg-indigo-500/10 rounded-full blur-3xl opacity-0 animate-[pulse_3s_ease-in-out_infinite,fade-in_1s_ease-in-out_forwards]" />
              <div className="absolute bottom-1/4 right-1/4 w-64 h-64 bg-purple-500/10 rounded-full blur-3xl opacity-0 animate-[pulse_3s_ease-in-out_infinite_1s,fade-in_1s_ease-in-out_forwards_0.5s]" />
            </div>
          </div>
          
          <div className="container mx-auto px-6">
            {/* Badge */}
            <div className="text-center mb-8">
              <div className="inline-block">
                <div className="relative px-6 py-2 bg-black/50 backdrop-blur-xl rounded-full border border-white/10 flex items-center gap-2">
                  <Crown className="w-4 h-4 text-indigo-400" />
                  <span className="text-sm font-medium bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
                    Creators Program
                  </span>
                </div>
              </div>
            </div>

            {/* Hero Content */}
            <div className="max-w-4xl mx-auto text-center">
              <h1 className="text-5xl md:text-7xl font-bold mb-8 leading-tight">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
                  More Deals. More Revenue.{' '}
                </span>
                <span className="relative inline-block">
                  <span className="absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 blur-2xl opacity-20" />
                  <span className="relative bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
                    Less Hassle.
                  </span>
                </span>
              </h1>
              
              <p className="text-xl text-gray-400 max-w-2xl mx-auto mb-12 leading-relaxed">
                Talent management and business acceleration for creators who are ready to scale beyond AdSense.
              </p>

              {/* CTA Buttons */}
              <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
                {/* Apply Now Button */}
                <button
                  onClick={() => {
                    const applySection = document.getElementById('apply');
                    if (applySection) {
                      applySection.scrollIntoView({ behavior: 'smooth' });
                    }
                  }}
                  className="group relative inline-flex items-center gap-2"
                >
                  <div className="absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-xl blur-xl opacity-20 group-hover:opacity-40 transition-opacity duration-500" />
                  <div className="relative inline-flex items-center gap-2 px-8 py-4 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-xl font-medium text-white hover:from-indigo-600 hover:to-purple-600 transition-colors duration-500">
                    <span>Apply Now</span>
                    <ArrowRight className="w-5 h-5" />
                  </div>
                </button>

                {/* Learn More Button */}
                <button
                  onClick={scrollToServices}
                  className="group relative inline-flex items-center gap-2"
                >
                  <div className="absolute -inset-2 bg-gradient-to-r from-indigo-500/50 to-purple-500/50 rounded-xl blur-xl opacity-0 group-hover:opacity-20 transition-opacity duration-500" />
                  <div className="relative inline-flex items-center gap-2 px-8 py-4 bg-gradient-to-r from-indigo-500/10 to-purple-500/10 rounded-xl border border-white/10 font-medium text-white hover:border-white/20 transition-colors duration-500">
                    <span>Learn More</span>
                    <ChevronDown className="w-5 h-5 group-hover:translate-y-0.5 transition-transform duration-500" />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </section>

        {/* Services Showcase */}
        <section id="services-section" className="relative">
          <div className="relative py-32">
            <div className="container mx-auto px-6">
              <div className="text-center mb-24">
                <h2 className="text-4xl md:text-5xl font-bold mb-6">
                  <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
                    The{' '}
                  </span>
                  <span className="relative inline-block">
                    <span className="absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 blur-2xl opacity-20" />
                    <span className="relative bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
                      Complete Package
                    </span>
                  </span>
                </h2>
                <p className="text-lg text-gray-400 max-w-2xl mx-auto">
                  Everything you need to transform your channel into a thriving media empire
                </p>
              </div>

              <div className="space-y-32">
                {services.map((service, serviceIndex) => (
                  <div key={serviceIndex} className="relative">
                    {/* Content */}
                    <div className={`relative flex flex-col lg:flex-row items-start gap-8 lg:gap-12 ${
                      serviceIndex % 2 === 0 ? 'lg:flex-row' : 'lg:flex-row-reverse'
                    }`}>
                      <div className="relative w-full">
                        {/* Main Container */}
                        <div className="relative bg-black/40 backdrop-blur-xl rounded-3xl border border-white/10 p-4 sm:p-6 lg:p-12">
                          <div className={`flex flex-col lg:flex-row items-start gap-4 sm:gap-6 lg:gap-12 ${
                            serviceIndex % 2 === 0 ? 'lg:flex-row' : 'lg:flex-row-reverse'
                          }`}>
                            {/* Service Info */}
                            <div className="w-full lg:w-1/3">
                              <div className="relative flex-shrink-0 mb-4 lg:mb-8">
                                <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-2xl blur-xl opacity-20" />
                                <div className="relative flex items-center justify-center w-14 h-14 sm:w-16 sm:h-16 lg:w-20 lg:h-20 rounded-2xl bg-gradient-to-br from-indigo-500/10 to-purple-500/10 border border-white/10">
                                  {service.icon}
                                </div>
                              </div>
                              <div className="text-sm font-medium text-indigo-400 mb-2">
                                {service.tagline}
                              </div>
                              <h3 className="text-xl sm:text-2xl lg:text-3xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-white to-white/70">
                                {service.title}
                              </h3>
                              <p className="text-gray-400">
                                {service.description}
                              </p>
                            </div>

                            {/* Features Grid */}
                            <div className="w-full lg:w-2/3">
                              <div className="relative">
                                <div className="absolute -inset-4 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-3xl blur-2xl opacity-10" />
                                <div className="relative grid grid-cols-1 sm:grid-cols-2 gap-px bg-white/5 rounded-3xl overflow-hidden">
                                  {service.features.map((feature, index) => (
                                    <div key={index} className="group relative bg-black/40 backdrop-blur-sm p-3 sm:p-4 lg:p-8">
                                      <div className="relative flex items-center gap-2 sm:gap-3 lg:gap-4 mb-3 sm:mb-4">
                                        <div className="flex-shrink-0 w-8 h-8 sm:w-10 sm:h-10 rounded-xl bg-gradient-to-br from-indigo-500/10 to-purple-500/10 border border-white/10 flex items-center justify-center">
                                          <div className="w-4 h-4 sm:w-5 sm:h-5 text-indigo-400 flex items-center justify-center">
                                            {feature.icon}
                                          </div>
                                        </div>
                                        <h4 className="font-semibold text-sm sm:text-base text-white">
                                          {feature.title}
                                        </h4>
                                      </div>
                                      <p className="text-sm text-gray-400 group-hover:text-gray-300 transition-colors duration-300">
                                        {feature.description}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        
        {/* Application Section */}
        <section className="relative py-32" id="apply">
          <div className="container mx-auto px-6">
            <div className="max-w-4xl mx-auto">
              <div className="text-center mb-12">
                <div className="glass-card px-4 py-2 rounded-full border border-indigo-500/30 inline-flex items-center gap-2 mb-6">
                  <Crown className="w-4 h-4 text-indigo-400" />
                  <span className="text-sm font-medium text-indigo-400">Creator Application</span>
                </div>
                  <h2 className="text-4xl md:text-5xl font-bold mb-6">
                  Join{' '}
                    <span className="relative inline-block">
                      <span className="absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 blur-2xl opacity-20" />
                      <span className="relative bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
                      Galactic
                    </span>
                    </span>
                  </h2>
                <p className="text-lg text-gray-400 max-w-2xl mx-auto">
                  Start your application by analyzing your YouTube channel below
                </p>
              </div>

              <div className="space-y-8 backdrop-blur-xl bg-white/5 rounded-2xl p-8 border border-white/10 shadow-2xl">
                {/* Channel Analysis Form */}
                {!channelData && (
                  <form onSubmit={handleChannelSubmit} className="space-y-6">
                    <div>
                      <label htmlFor="channelUrl" className="block text-sm font-medium mb-2">
                        YouTube Channel URL
                      </label>
                      <div className="relative">
                        <Youtube className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                        <input
                          type="text"
                          id="channelUrl"
                          value={channelUrl}
                          onChange={(e) => setChannelUrl(e.target.value)}
                          className="w-full pl-10 pr-4 py-3 bg-black/50 border border-white/10 rounded-lg focus:outline-none focus:border-indigo-500 transition-colors"
                          placeholder="Enter channel URL, username, or @handle"
                          required
                        />
                      </div>
                    </div>

                    {error && (
                      <div className="p-4 bg-red-500/10 border border-red-500/20 rounded-lg text-red-400">
                        {error}
                        {error.includes('Failed to fetch') && (
                          <div className="mt-2">
                            <Link 
                              to="/contact" 
                              className="text-indigo-400 hover:text-indigo-300 underline transition-colors"
                            >
                              Contact us directly →
                            </Link>
                          </div>
                        )}
                      </div>
                    )}

                    {isAnalyzing && (
                      <div className="my-8">
                        <AnalysisConsole logs={analysisLogs} />
                      </div>
                    )}

                    <button
                      type="submit"
                      disabled={loading}
                      className="w-full bg-gradient-to-r from-indigo-500 to-blue-500 hover:from-indigo-600 hover:to-blue-600 px-8 py-4 rounded-xl text-lg transition-all shadow-lg shadow-indigo-500/25 hover:shadow-indigo-500/40 flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      {loading ? (
                        <>
                          <Loader2 className="w-5 h-5 mr-2 animate-spin" />
                          Analyzing Channel...
                        </>
                      ) : (
                        <>
                          Start Application
                          <ArrowRight className="ml-2 w-5 h-5" />
                        </>
                      )}
                    </button>
                  </form>
                )}

                {/* Channel Analysis Results & Application Form */}
                {channelData && (
                  <div className="space-y-8">
                    {/* Channel Profile */}
                    <div className="flex items-center gap-4 mb-8 opacity-0 animate-fadeIn">
                      {channelData.thumbnails?.default && (
                        <div className="flex-shrink-0">
                          <img
                            src={channelData.thumbnails.default.url}
                            alt={`${channelData.title} profile`}
                            className="w-20 h-20 rounded-full ring-4 ring-indigo-500/20"
                          />
                        </div>
                      )}
                      <div>
                        <h2 className="text-2xl font-bold">{channelData.title}</h2>
                        <div className="text-gray-400">
                          <p className="whitespace-pre-line">{truncatedDescription}</p>
                          {shouldTruncate && (
                            <button
                              onClick={() => setShowFullDescription(!showFullDescription)}
                              className="text-indigo-400 hover:text-indigo-300 transition-colors mt-1 text-sm font-medium"
                            >
                              {showFullDescription ? 'Show less' : 'Show more'}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Add encouraging message when requirements not met */}
                    {!meetsAllRequirements && monthlyViewsProgress === 1 && (
                      <div className="p-6 bg-indigo-500/10 border-l-4 border-indigo-500 rounded-lg mb-8 animate-fade-in">
                        <div className="flex items-start gap-3">
                          <div className="p-1">
                            <Heart className="w-5 h-5 text-indigo-400" />
                          </div>
                          <div>
                            <h3 className="text-lg font-semibold text-indigo-400 mb-1">You're More Than Numbers!</h3>
                            <p className="text-gray-300 leading-relaxed">
                              While your channel hasn't reached all our preferred metrics yet, we strongly encourage you to apply! We believe creators are more than just numbers, and we'd love to learn more about your unique story, content quality, and growth potential.
                            </p>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* Channel Stats */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-6">
                      <div className="relative group opacity-0 animate-fadeIn delay-200">
                        <div className="absolute -inset-[1px] bg-gradient-to-b from-indigo-500/20 to-purple-500/20 rounded-lg blur-sm transition-all group-hover:blur-md" />
                        <div className="relative p-3 sm:p-4 bg-gradient-to-br from-black/80 via-black/90 to-black/80 backdrop-blur-xl rounded-lg border border-white/10 hover:border-white/20 transition-colors">
                          <div className="flex items-center justify-between mb-1">
                            <div className="text-sm text-gray-400">Total Views</div>
                          </div>
                          <div className="text-lg font-medium text-white">
                            {channelData?.statistics.totalViews.toLocaleString()}
                          </div>
                        </div>
                      </div>
                      <div className="relative group opacity-0 animate-fadeIn delay-300">
                        <div className="absolute -inset-[1px] bg-gradient-to-b from-indigo-500/20 to-purple-500/20 rounded-lg blur-sm transition-all group-hover:blur-md" />
                        <div className="relative p-3 sm:p-4 bg-gradient-to-br from-black/80 via-black/90 to-black/80 backdrop-blur-xl rounded-lg border border-white/10 hover:border-white/20 transition-colors">
                          <div className="flex items-center justify-between mb-1">
                            <div className="text-sm text-gray-400">Subscribers</div>
                            {channelData?.statistics.subscribers >= 100000 ? (
                              <div className="flex items-center gap-1 text-xs text-emerald-400">
                                <CheckCircle2 className="w-4 h-4" />
                                <span>Eligible</span>
                              </div>
                            ) : (
                              <div className="flex items-center gap-1 text-xs text-red-400">
                                <X className="w-4 h-4" />
                                <span>100K+ preferred</span>
                              </div>
                            )}
                          </div>
                          <div className="text-lg font-medium text-white">
                            {channelData?.statistics.subscribers.toLocaleString()}
                          </div>
                        </div>
                      </div>

                      <div className="relative group opacity-0 animate-fadeIn delay-500">
                        <div className="absolute -inset-[1px] bg-gradient-to-b from-indigo-500/20 to-purple-500/20 rounded-lg blur-sm transition-all group-hover:blur-md" />
                        <div className="relative p-3 sm:p-4 bg-gradient-to-br from-black/80 via-black/90 to-black/80 backdrop-blur-xl rounded-lg border border-white/10 hover:border-white/20 transition-colors">
                          <div className="flex items-center justify-between mb-1">
                            <div className="text-sm text-gray-400 flex items-center gap-2">
                              Avg Views/Video (V30)
                              <div className="relative group/tooltip">
                                <div className="w-4 h-4 rounded-full bg-white/10 flex items-center justify-center cursor-help">
                                  <HelpCircle className="w-3 h-3 text-gray-400" />
                                </div>
                                <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 w-80 opacity-0 group-hover/tooltip:opacity-100 transition-opacity duration-200 pointer-events-none">
                                  <div className="relative">
                                    <div className="absolute -inset-1 bg-gradient-to-r from-indigo-500/20 to-purple-500/20 rounded-lg blur-sm" />
                                    <div className="relative bg-black/90 backdrop-blur-sm rounded-lg p-4 border border-white/10 text-xs text-gray-300">
                                      <div className="font-medium text-indigo-400 mb-2">How we calculate V30:</div>
                                      <ol className="space-y-2">
                                        <li>1. For videos under 30 days old: Extrapolate daily view rate to 30 days</li>
                                        <li>2. For videos over 30 days old: Take 60% of total views (typical 30-day share)</li>
                                        <li>3. Average across your recent regular videos</li>
                                        <li>4. Shorts are excluded from calculation</li>
                                      </ol>
                                      <div className="mt-2 text-gray-400">
                                        This provides a reliable estimate of your typical 30-day view performance.
                                      </div>
                                    </div>
                                    <div className="absolute left-1/2 -bottom-1 w-2 h-2 -translate-x-1/2 rotate-45 bg-black/90 border-r border-b border-white/10" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {channelData?.recentVideos && calculateV60(channelData.recentVideos) >= 20000 ? (
                              <div className="flex items-center gap-1 text-xs text-emerald-400">
                                <CheckCircle2 className="w-4 h-4" />
                                <span>Eligible</span>
                              </div>
                            ) : (
                              <div className="flex items-center gap-1 text-xs text-red-400">
                                <X className="w-4 h-4" />
                                <span>20K+ preferred</span>
                              </div>
                            )}
                          </div>
                          <div className="text-lg font-medium text-white">
                            {channelData?.recentVideos ? calculateV60(channelData.recentVideos).toLocaleString() : 'N/A'}
                          </div>
                        </div>
                      </div>

                      <div className="relative group opacity-0 animate-fadeIn delay-400">
                        <div className="absolute -inset-[1px] bg-gradient-to-b from-indigo-500/20 to-purple-500/20 rounded-lg blur-sm transition-all group-hover:blur-md" />
                        <div className="relative p-3 sm:p-4 bg-gradient-to-br from-black/80 via-black/90 to-black/80 backdrop-blur-xl rounded-lg border border-white/10 hover:border-white/20 transition-colors">
                          <div className="flex items-center justify-between mb-1">
                            <div className="text-sm text-gray-400">Monthly Views</div>
                            {monthlyViews !== null && (
                              monthlyViews >= 500000 ? (
                                <div className="flex items-center gap-1 text-xs text-emerald-400">
                                  <CheckCircle2 className="w-4 h-4" />
                                  <span>Eligible</span>
                                </div>
                              ) : (
                                <div className="flex items-center gap-1 text-xs text-red-400">
                                  <X className="w-4 h-4" />
                                  <span>500K+ preferred</span>
                                </div>
                              )
                            )}
                          </div>
                          <div className="text-lg font-medium text-white">
                            {monthlyViewsProgress < 1 ? (
                              <div className="flex items-center">
                                <div className="relative w-8 h-8 mr-3">
                                  <svg className="w-full h-full" viewBox="0 0 36 36">
                                    <circle
                                      cx="18"
                                      cy="18"
                                      r="16"
                                      fill="none"
                                      className="stroke-current text-indigo-900"
                                      strokeWidth="2"
                                    />
                                    <circle
                                      cx="18"
                                      cy="18"
                                      r="16"
                                      fill="none"
                                      className="stroke-current text-indigo-400"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeDasharray={`${monthlyViewsProgress * 100.53}, 100`}
                                      transform="rotate(-90 18 18)"
                                    />
                                  </svg>
                                  <div className="absolute inset-0 flex items-center justify-center text-xs font-medium text-indigo-400">
                                    {Math.round(monthlyViewsProgress * 100)}%
                                  </div>
                                </div>
                                <span>Calculating...</span>
                              </div>
                            ) : monthlyViewsError ? (
                              <div className="text-red-400">Unable to fetch monthly views</div>
                            ) : (
                              monthlyViews?.toLocaleString() || 'N/A'
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Additional Stats */}
                    <div className="relative group mt-4 opacity-0 animate-fadeIn delay-800">
                      <div className="absolute -inset-[1px] bg-gradient-to-b from-indigo-500/20 to-purple-500/20 rounded-lg blur-sm transition-all group-hover:blur-md" />
                      <div className="relative p-4 sm:p-6 bg-gradient-to-br from-black/80 via-black/90 to-black/80 backdrop-blur-xl rounded-lg border border-white/10 hover:border-white/20 transition-colors">
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 sm:gap-6">
                          <div>
                            <div className="text-sm text-gray-400 mb-1 flex items-center gap-2">
                              Estimated Annual Upload Volume
                              <div className="relative group/tooltip">
                                <div className="w-4 h-4 rounded-full bg-white/10 flex items-center justify-center cursor-help">
                                  <HelpCircle className="w-3 h-3 text-gray-400" />
                                </div>
                                <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 w-80 opacity-0 group-hover/tooltip:opacity-100 transition-opacity duration-200 pointer-events-none">
                                  <div className="relative">
                                    <div className="absolute -inset-1 bg-gradient-to-r from-indigo-500/20 to-purple-500/20 rounded-lg blur-sm" />
                                    <div className="relative bg-black/90 backdrop-blur-sm rounded-lg p-4 border border-white/10 text-xs text-gray-300">
                                      <div className="font-medium text-indigo-400 mb-2">How we calculate this:</div>
                                      <ol className="space-y-2">
                                        <li>1. We analyze your last 25 non-short videos</li>
                                        <li>2. Calculate time between each upload</li>
                                        <li>3. Apply weighted average (recent uploads count more)</li>
                                        <li>4. Convert to yearly estimate</li>
                                      </ol>
                                      <div className="mt-2 text-gray-400">
                                        This provides a more accurate prediction by giving more weight to your recent upload patterns.
                                      </div>
                                    </div>
                                    <div className="absolute left-1/2 -bottom-1 w-2 h-2 -translate-x-1/2 rotate-45 bg-black/90 border-r border-b border-white/10" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="text-lg font-medium text-white">
                              {channelData?.recentVideos ? `${calculateAnnualUploadVolume(channelData.recentVideos)} videos/year` : 'N/A'}
                            </div>
                            <div className="text-xs text-gray-500 mt-1">Based on recent upload frequency</div>
                          </div>

                          <div>
                            <div className="text-sm text-gray-400 mb-1 flex items-center gap-2">
                              Average Brand Deal Rate
                              <div className="relative group/tooltip">
                                <div className="w-4 h-4 rounded-full bg-white/10 flex items-center justify-center cursor-help">
                                  <HelpCircle className="w-3 h-3 text-gray-400" />
                                </div>
                                <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 w-80 opacity-0 group-hover/tooltip:opacity-100 transition-opacity duration-200 pointer-events-none">
                                  <div className="relative">
                                    <div className="absolute -inset-1 bg-gradient-to-r from-indigo-500/20 to-purple-500/20 rounded-lg blur-sm" />
                                    <div className="relative bg-black/90 backdrop-blur-sm rounded-lg p-4 border border-white/10 text-xs text-gray-300">
                                      <div className="font-medium text-indigo-400 mb-2">How we calculate this:</div>
                                      <p className="mb-2">We use a power-law model based on your V30 (30-day view average):</p>
                                      <ol className="space-y-2">
                                        <li>1. Start with your V30 metric</li>
                                        <li>2. Apply our proprietary pricing formula</li>
                                        <li>3. Factor in market rates and trends</li>
                                        <li>4. Round to nearest dollar amount</li>
                                      </ol>
                                      <div className="mt-2 text-gray-400">
                                        This provides a baseline rate for standard integrations based on your typical 30-day video performance.
                                      </div>
                                    </div>
                                    <div className="absolute left-1/2 -bottom-1 w-2 h-2 -translate-x-1/2 rotate-45 bg-black/90 border-r border-b border-white/10" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="text-lg font-medium text-white">
                              {channelData?.recentVideos ? formatCurrency(calculateBrandDealRate(calculateV60(channelData.recentVideos))) : 'N/A'}
                              <span className="text-xs text-gray-500 ml-1">per integration</span>
                            </div>
                            <div className="text-xs text-gray-500 mt-1">Based on 30-day view performance</div>
                          </div>

                          <div>
                            <div className="text-sm text-gray-400 mb-1 flex items-center gap-2">
                              Potential Yearly Brand Revenue
                              <div className="relative group/tooltip">
                                <div className="w-4 h-4 rounded-full bg-white/10 flex items-center justify-center cursor-help">
                                  <HelpCircle className="w-3 h-3 text-gray-400" />
                                </div>
                                <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 w-80 opacity-0 group-hover/tooltip:opacity-100 transition-opacity duration-200 pointer-events-none">
                                  <div className="relative">
                                    <div className="absolute -inset-1 bg-gradient-to-r from-indigo-500/20 to-purple-500/20 rounded-lg blur-sm" />
                                    <div className="relative bg-black/90 backdrop-blur-sm rounded-lg p-4 border border-white/10 text-xs text-gray-300">
                                      <div className="font-medium text-indigo-400 mb-2">How we calculate this:</div>
                                      <ol className="space-y-2">
                                        <li>1. Take your average brand deal rate</li>
                                        <li>2. Multiply by estimated annual uploads</li>
                                        <li>3. Assume 90% of videos have integrations</li>
                                        <li>4. Project total yearly revenue</li>
                                      </ol>
                                      <div className="mt-2 text-gray-400">
                                        This is a potential estimate assuming consistent performance and regular brand integrations.
                                      </div>
                                    </div>
                                    <div className="absolute left-1/2 -bottom-1 w-2 h-2 -translate-x-1/2 rotate-45 bg-black/90 border-r border-b border-white/10" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="text-lg font-medium text-white">
                              {channelData?.recentVideos ? 
                                formatCurrency(
                                  calculateBrandDealRate(calculateV60(channelData.recentVideos)) * 
                                  calculateAnnualUploadVolume(channelData.recentVideos) * 0.9
                                ) : 'N/A'}
                              <span className="text-xs text-gray-500 ml-1">per year</span>
                            </div>
                            <div className="text-xs text-gray-500 mt-1">Assuming 90% of videos have integrations</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Application Form */}
                    <form onSubmit={handleSubmit} className="space-y-6 sm:space-y-8 mt-8 sm:mt-12">
                      {/* Progress Steps */}
                      <div className="mb-12">
                        <div className="flex flex-col sm:flex-row justify-between items-start">
                          {steps.map((step, index) => (
                            <div key={index} className="flex-1 relative mb-4 sm:mb-0">
                              {index < steps.length - 1 && (
                                <div className={`hidden sm:block absolute top-5 left-1/2 w-full h-[2px] transition-colors ${
                                  index < currentStep ? 'bg-indigo-500' : 'bg-gray-700'
                                }`} />
                              )}
                              <div className="flex sm:flex-col items-center relative">
                                <div className={`w-8 h-8 sm:w-10 sm:h-10 rounded-full border-2 flex items-center justify-center mb-0 sm:mb-2 mr-4 sm:mr-0 transition-colors bg-black ${
                                  index === currentStep
                                    ? 'border-indigo-500 bg-indigo-500/20 text-white'
                                    : index < currentStep
                                    ? 'border-indigo-500 bg-indigo-500 text-white'
                                    : 'border-gray-700 text-gray-500'
                                }`}>
                                  {index < currentStep ? (
                                    <CheckCircle2 className="w-4 h-4 sm:w-5 sm:h-5" />
                                  ) : (
                                    <span className="text-xs sm:text-sm">{index + 1}</span>
                                  )}
                                </div>
                                <div className="text-left sm:text-center h-auto sm:h-14">
                                  <div className={`text-sm font-medium mb-0 sm:mb-1 ${
                                    index === currentStep ? 'text-white' : 'text-gray-500'
                                  }`}>
                                    {step.title}
                                  </div>
                                  <div className="text-xs text-gray-500 hidden md:block">
                                    {step.description}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      {/* Step Content */}
                      <div className="space-y-6">
                        {/* Step 1: Channel Analysis */}
                        {currentStep === 0 && (
                          <div className="space-y-4 sm:space-y-6">
                            <div>
                              <label htmlFor="contentCategory" className="block text-sm font-medium mb-2">
                                Primary Content Category
                              </label>
                              <Select
                                value={formData.contentCategory}
                                onValueChange={(value: string) => setFormData(prev => ({ ...prev, contentCategory: value }))}
                              >
                                <SelectTrigger className="w-full py-2.5 sm:py-3" icon={!formData.contentCategory ? <Film className="w-4 h-4" /> : null}>
                                  <SelectValue placeholder="Select a category" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="tech" icon={<Laptop className="w-4 h-4" />}>Technology & Gadgets</SelectItem>
                                  <SelectItem value="gaming" icon={<Gamepad className="w-4 h-4" />}>Gaming & Esports</SelectItem>
                                  <SelectItem value="lifestyle" icon={<Heart className="w-4 h-4" />}>Lifestyle & Vlogs</SelectItem>
                                  <SelectItem value="education" icon={<GraduationCap className="w-4 h-4" />}>Education & How-To</SelectItem>
                                  <SelectItem value="entertainment" icon={<Film className="w-4 h-4" />}>Entertainment & Comedy</SelectItem>
                                  <SelectItem value="business" icon={<Briefcase className="w-4 h-4" />}>Business & Finance</SelectItem>
                                  <SelectItem value="food" icon={<Utensils className="w-4 h-4" />}>Food & Cooking</SelectItem>
                                  <SelectItem value="fitness" icon={<Dumbbell className="w-4 h-4" />}>Fitness & Health</SelectItem>
                                  <SelectItem value="beauty" icon={<Sparkles className="w-4 h-4" />}>Beauty & Fashion</SelectItem>
                                  <SelectItem value="music" icon={<Music className="w-4 h-4" />}>Music & Performance</SelectItem>
                                  <SelectItem value="art" icon={<Palette className="w-4 h-4" />}>Art & Design</SelectItem>
                                  <SelectItem value="travel" icon={<Plane className="w-4 h-4" />}>Travel & Adventure</SelectItem>
                                  <SelectItem value="automotive" icon={<Car className="w-4 h-4" />}>Automotive & Transportation</SelectItem>
                                  <SelectItem value="sports" icon={<Trophy className="w-4 h-4" />}>Sports & Athletics</SelectItem>
                                  <SelectItem value="science" icon={<FlaskConical className="w-4 h-4" />}>Science & Technology</SelectItem>
                                  <SelectItem value="news" icon={<Newspaper className="w-4 h-4" />}>News & Commentary</SelectItem>
                                  <SelectItem value="family" icon={<Users className="w-4 h-4" />}>Family & Parenting</SelectItem>
                                  <SelectItem value="diy" icon={<Hammer className="w-4 h-4" />}>DIY & Crafts</SelectItem>
                                  <SelectItem value="other" icon={<HelpCircle className="w-4 h-4" />}>Other</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>

                            <div>
                              <label htmlFor="uploadFrequency" className="block text-sm font-medium mb-2">
                                Upload Frequency
                              </label>
                              <Select
                                value={formData.uploadFrequency}
                                onValueChange={(value: string) => setFormData(prev => ({ ...prev, uploadFrequency: value }))}
                              >
                                <SelectTrigger icon={<Clock className="w-4 h-4" />}>
                                  <SelectValue placeholder="Select frequency" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="daily">Daily</SelectItem>
                                  <SelectItem value="2-3-times-week">2-3 Times per Week</SelectItem>
                                  <SelectItem value="weekly">Weekly</SelectItem>
                                  <SelectItem value="biweekly">Biweekly</SelectItem>
                                  <SelectItem value="monthly">Monthly</SelectItem>
                                  <SelectItem value="less-than-monthly">Less than Monthly</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>
                          </div>
                        )}

                        {/* Step 2: Monetization Strategy */}
                        {currentStep === 1 && (
                          <div className="space-y-4 sm:space-y-6">
                            <div>
                              <label className="block text-sm font-medium mb-3 sm:mb-4">
                                Current Revenue Streams
                              </label>
                              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4">
                                {[
                                  { name: 'Ad Revenue', icon: <DollarSign className="w-4 h-4" /> },
                                  { name: 'Brand Deals', icon: <Briefcase className="w-4 h-4" /> },
                                  { name: 'Merchandise', icon: <TrendingUp className="w-4 h-4" /> },
                                  { name: 'Channel Memberships', icon: <Users className="w-4 h-4" /> },
                                  { name: 'Super Chat', icon: <Globe className="w-4 h-4" /> },
                                  { name: 'Affiliate Marketing', icon: <Target className="w-4 h-4" /> },
                                  { name: 'Course Sales', icon: <GraduationCap className="w-4 h-4" /> },
                                  { name: 'Consulting', icon: <Film className="w-4 h-4" /> }
                                ].map(({ name, icon }) => (
                                  <label
                                    key={name}
                                    className={`flex items-center gap-3 p-4 rounded-lg border transition-colors ${
                                      formData.revenueStreams.includes(name)
                                        ? 'border-indigo-500 bg-indigo-500/10'
                                        : 'border-white/10 hover:border-white/20'
                                    }`}
                                  >
                                    <input
                                      type="checkbox"
                                      name="revenueStreams"
                                      value={name}
                                      checked={formData.revenueStreams.includes(name)}
                                      onChange={handleCheckboxChange}
                                      className="hidden"
                                    />
                                    <div className={`flex items-center justify-center w-8 h-8 rounded-full transition-colors ${
                                      formData.revenueStreams.includes(name)
                                        ? 'bg-indigo-500 text-white'
                                        : 'bg-black/50 text-gray-400'
                                    }`}>
                                      {icon}
                                    </div>
                                    <span className="text-sm font-medium">{name}</span>
                                  </label>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}

                        {/* Step 3: Business Goals */}
                        {currentStep === 2 && (
                          <div className="space-y-4 sm:space-y-6">
                            <div>
                              <label className="block text-sm font-medium mb-3 sm:mb-4">
                                Areas of Interest for Growth
                              </label>
                              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4">
                                {[
                                  { name: 'Increase Revenue', icon: <DollarSign className="w-4 h-4" /> },
                                  { name: 'Grow Audience', icon: <Users className="w-4 h-4" /> },
                                  { name: 'Launch Products', icon: <Target className="w-4 h-4" /> },
                                  { name: 'Build Team', icon: <Users className="w-4 h-4" /> },
                                  { name: 'Improve Production Quality', icon: <Film className="w-4 h-4" /> },
                                  { name: 'Expand to New Platforms', icon: <Globe className="w-4 h-4" /> },
                                  { name: 'Get More Brand Deals', icon: <Briefcase className="w-4 h-4" /> },
                                  { name: 'Create Multiple Channels', icon: <Youtube className="w-4 h-4" /> }
                                ].map(({ name, icon }) => (
                                  <label
                                    key={name}
                                    className={`flex items-center gap-3 p-4 rounded-lg border transition-colors ${
                                      formData.goals.includes(name)
                                        ? 'border-indigo-500 bg-indigo-500/10'
                                        : 'border-white/10 hover:border-white/20'
                                    }`}
                                  >
                                    <input
                                      type="checkbox"
                                      name="goals"
                                      value={name}
                                      checked={formData.goals.includes(name)}
                                      onChange={handleCheckboxChange}
                                      className="hidden"
                                    />
                                    <div className={`flex items-center justify-center w-8 h-8 rounded-full transition-colors ${
                                      formData.goals.includes(name)
                                        ? 'bg-indigo-500 text-white'
                                        : 'bg-black/50 text-gray-400'
                                    }`}>
                                      {icon}
                                    </div>
                                    <span className="text-sm font-medium">{name}</span>
                                  </label>
                                ))}
                              </div>
                            </div>

                            <div>
                              <label htmlFor="additionalGoals" className="block text-sm font-medium mb-2">
                                Additional Business Goals <span className="text-gray-500">(Optional)</span>
                              </label>
                              <textarea
                                id="additionalGoals"
                                name="additionalGoals"
                                value={formData.additionalGoals || ''}
                                onChange={handleInputChange}
                                rows={4}
                                className="w-full px-3 sm:px-4 py-2.5 sm:py-3 bg-black/50 border border-white/10 rounded-lg focus:outline-none focus:border-indigo-500 transition-colors text-sm sm:text-base"
                                placeholder="Share any other business goals or aspirations you have for your channel..."
                              />
                            </div>
                          </div>
                        )}

                        {/* Step 4: Additional Info */}
                        {currentStep === 3 && (
                          <div className="space-y-4 sm:space-y-6">
                            <div>
                              <label htmlFor="about" className="block text-sm font-medium mb-2">
                                Tell us about yourself and your channel
                              </label>
                              <textarea
                                id="about"
                                name="about"
                                value={formData.about}
                                required
                                onChange={handleInputChange}
                                rows={4}
                                className="w-full px-3 sm:px-4 py-2.5 sm:py-3 bg-black/50 border border-white/10 rounded-lg focus:outline-none focus:border-indigo-500 transition-colors text-sm sm:text-base"
                                placeholder="Share your story, achievements, and vision..."
                              />
                            </div>

                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
                              <div>
                                <label htmlFor="name" className="block text-sm font-medium mb-2">
                                  Full Name
                                </label>
                                <input
                                  type="text"
                                  id="name"
                                  name="name"
                                  required
                                  value={formData.name}
                                  onChange={handleInputChange}
                                  className="w-full px-3 sm:px-4 py-2.5 sm:py-3 bg-black/50 border border-white/10 rounded-lg focus:outline-none focus:border-indigo-500 transition-colors text-sm sm:text-base"
                                />
                              </div>
                              <div>
                                <label htmlFor="email" className="block text-sm font-medium mb-2">
                                  Email
                                </label>
                                <input
                                  type="email"
                                  id="email"
                                  name="email"
                                  required
                                  value={formData.email}
                                  onChange={handleInputChange}
                                  className="w-full px-3 sm:px-4 py-2.5 sm:py-3 bg-black/50 border border-white/10 rounded-lg focus:outline-none focus:border-indigo-500 transition-colors text-sm sm:text-base"
                                />
                              </div>
                            </div>

                            <div>
                              <label htmlFor="phone" className="block text-sm font-medium mb-2">
                                Phone Number <span className="text-gray-500">(Optional)</span>
                              </label>
                              <input
                                type="tel"
                                id="phone"
                                name="phone"
                                value={formData.phone}
                                onChange={handleInputChange}
                                className="w-full px-3 sm:px-4 py-2.5 sm:py-3 bg-black/50 border border-white/10 rounded-lg focus:outline-none focus:border-indigo-500 transition-colors text-sm sm:text-base"
                                placeholder="Enter your phone number (optional)"
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      {/* Navigation Buttons */}
                      <div className="flex justify-between items-center pt-8">
                        {currentStep > 0 ? (
                          <button
                            type="button"
                            onClick={handlePrevious}
                            className="flex items-center gap-2 px-6 py-3 rounded-xl bg-white/5 border border-white/10 hover:bg-white/10 transition-colors"
                          >
                            <ArrowRight className="w-4 h-4 rotate-180" />
                            <span>Previous</span>
                          </button>
                        ) : (
                          <div>{/* Empty div for spacing */}</div>
                        )}

                        {currentStep < steps.length - 1 ? (
                          <button
                            type="button"
                            onClick={handleNext}
                            className="flex items-center gap-2 px-6 py-3 rounded-xl bg-gradient-to-r from-indigo-500/10 to-purple-500/10 border border-white/10 hover:border-white/20 transition-colors"
                          >
                            <span>Next</span>
                            <ArrowRight className="w-4 h-4" />
                          </button>
                        ) : (
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className={`flex items-center gap-2 px-8 py-4 rounded-xl font-medium transition-all ${
                              isSubmitting
                                ? 'bg-indigo-500/50 cursor-not-allowed'
                                : 'bg-gradient-to-r from-indigo-500 to-purple-500 hover:from-indigo-600 hover:to-purple-600'
                            }`}
                          >
                            {isSubmitting ? (
                              <>
                                <Loader2 className="w-5 h-5 animate-spin" />
                                Submitting...
                              </>
                            ) : (
                              <>
                                Submit Application
                                <ArrowRight className="w-5 h-5" />
                              </>
                            )}
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Success Modal */}
      {showSuccessModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 sm:p-6">
          <div 
            className="absolute inset-0 bg-black/80 backdrop-blur-sm"
            onClick={() => setShowSuccessModal(false)}
          />
          <div className="relative w-full max-w-lg mx-4 sm:mx-auto">
            <div className="absolute -inset-px bg-gradient-to-r from-indigo-500 to-purple-500 rounded-2xl blur-lg opacity-50" />
            <div className="relative bg-black/80 backdrop-blur-xl rounded-2xl p-6 sm:p-8 border border-white/10">
              <div className="flex flex-col items-center text-center">
                {/* Success Icon */}
                <div className="relative mb-4 sm:mb-6">
                  <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-full blur-lg opacity-50" />
                  <div className="relative w-16 h-16 sm:w-20 sm:h-20 rounded-full bg-gradient-to-r from-indigo-500/10 to-purple-500/10 border border-white/10 flex items-center justify-center">
                    <CheckCircle2 className="w-8 h-8 sm:w-10 sm:h-10 text-indigo-400" />
                  </div>
                </div>

                {/* Title */}
                <h2 className="text-2xl sm:text-3xl font-bold mb-3 sm:mb-4">
                  <span className="bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
                    Application Submitted!
                  </span>
                </h2>

                {/* Message */}
                <p className="text-gray-400 mb-6 sm:mb-8 leading-relaxed text-sm sm:text-base">
                  Thank you for applying to join the Galactic Creator Program. Our team will carefully review your application and get back to you within 5 business days.
                </p>

                {/* Close Button */}
                <button
                  onClick={() => setShowSuccessModal(false)}
                  className="group relative inline-flex items-center gap-2"
                >
                  <div className="absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-xl blur-xl opacity-20 group-hover:opacity-40 transition-opacity duration-500" />
                  <div className="relative inline-flex items-center gap-2 px-6 sm:px-8 py-3 sm:py-4 bg-gradient-to-r from-indigo-500/10 to-purple-500/10 rounded-xl border border-white/10 font-medium text-white hover:border-white/20 transition-colors duration-500">
                    <span>Close</span>
                    <X className="w-4 h-4" />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}