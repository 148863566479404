import { Stars, Rocket, Video, Camera, Pen, Infinity, ChevronRight, Play, ArrowRight, Sparkles, Zap, Palette, Award, Trophy, Lightbulb, FileText, Film, Package, CheckCircle2 } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Cal, { getCalApi } from "@calcom/embed-react";

const scrollToSection = (e: React.MouseEvent<HTMLAnchorElement>, id: string) => {
  e.preventDefault();
  const element = document.getElementById(id);
  if (element) {
    const navHeight = 80;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - navHeight;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }
};

export default function StudioPage() {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    (async function () {
      try {
        const cal = await getCalApi();
        cal("ui", {
          theme: "dark",
          styles: { 
            branding: { brandColor: "#4f46e5" },
            body: { background: "transparent" }
          }
        });
        cal("inline", {
          elementOrSelector: "#cal-booking-place",
          calLink: "team/galactic-media/discovery-call",
          config: {
            theme: "dark",
            layout: "month_view",
            hideEventTypeDetails: "false",
            hideBranding: "true"
          }
        });
      } catch (error) {
        console.error("Error initializing Cal:", error);
      }
    })();
  }, []);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    // Validate email format
    if (!validateEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }

    setIsLoading(true);
    
    try {
      await fetch('https://script.google.com/macros/s/AKfycbyIw9Q_wYI0mxbzNLk5vZ0wHfByr0ZA0E0zskL5hy6DZEPATn9fc96i14V59y2mCmYy3w/exec', {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
        },
        body: JSON.stringify({ 
          email
        }),
        mode: 'no-cors'
      });

      setIsSubmitted(true);
      setEmail('');
    } catch (error: any) {
      console.error('Error submitting form:', error);
      setError('Failed to submit. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="relative overflow-hidden">
      {/* Main Background - Now contained within the content area */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-black/90">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_60%_-20%,#4338ca,transparent_50%)]" />
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_150%,#6366f1,transparent_50%)]" />
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_80%_60%,#4f46e5,transparent_40%)]" />
        </div>
        <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-10" />
        {/* Floating Orbs - Now with more subtle movement */}
        <div className="absolute -top-48 left-1/4 w-96 h-96 bg-indigo-500/20 rounded-full blur-3xl animate-float" />
        <div className="absolute -bottom-48 right-1/4 w-96 h-96 bg-purple-500/20 rounded-full blur-3xl animate-float-delayed" />
      </div>

      {/* Content */}
      <div className="relative">
        {/* Hero Section */}
        <section className="relative min-h-[80vh] flex flex-col items-center justify-center py-16 sm:py-24">
          {/* Hero Gradient Overlay */}
          <div className="absolute inset-0 pointer-events-none">
            <div className="absolute inset-0 bg-gradient-to-b from-black/30 via-black/60 to-black/80" />
          </div>

          <div className="relative container mx-auto px-4 sm:px-6 py-12 sm:py-24">
            {/* Badge */}
            <div className="mb-8 sm:mb-12 flex justify-center">
              <div className="relative inline-block">
                <div className="absolute -inset-1 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-full blur opacity-30" />
                <div className="relative px-4 sm:px-6 py-2 bg-black/50 backdrop-blur-xl rounded-full border border-white/10 flex items-center gap-2">
                  <Video className="w-4 h-4 text-indigo-400" />
                  <span className="text-sm font-medium bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
                    Content Production
                  </span>
                </div>
              </div>
            </div>

            {/* Hero Content */}
            <div className="max-w-2xl sm:max-w-4xl text-center mx-auto">
              <h1 className="relative text-4xl sm:text-5xl md:text-7xl font-bold mb-6 sm:mb-8">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
                  Full-Stack  Content Production for{' '}
                </span>
                <span className="relative inline-block">
                  <span className="absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 blur-2xl opacity-20" />
                  <span className="relative bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
                    YouTubers
                  </span>
                </span>
              </h1>
              
              <p className="text-lg sm:text-xl text-gray-400 max-w-xl sm:max-w-2xl mx-auto mb-8 sm:mb-12 leading-relaxed">
                Scale your YouTube channel with our end-to-end content production services.
              </p>

              <div className="flex flex-col sm:flex-row gap-4 justify-center items-center">
                <Link
                  to="#how-it-works"
                  onClick={(e) => scrollToSection(e, 'how-it-works')}
                  className="group relative inline-flex items-center gap-2"
                >
                  <div className="absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-xl blur-xl opacity-0 group-hover:opacity-20 transition-opacity duration-500" />
                  <div className="relative inline-flex items-center gap-2 px-6 sm:px-8 py-3 sm:py-4 bg-black/20 backdrop-blur-sm rounded-xl border border-white/10 font-medium text-white hover:border-white/20 transition-colors duration-500">
                    <span>How It Works</span>
                    <div className="w-8 h-8 rounded-full bg-white/5 flex items-center justify-center group-hover:bg-white/10 transition-colors duration-500">
                      <ArrowRight className="w-4 h-4" />
                    </div>
                  </div>
                </Link>
                <Link
                  to="#book"
                  onClick={(e) => scrollToSection(e, 'book')}
                  className="group relative inline-flex items-center gap-2"
                >
                  <div className="absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-xl blur-xl opacity-20 group-hover:opacity-40 transition-opacity duration-500" />
                  <div className="relative inline-flex items-center gap-2 px-6 sm:px-8 py-3 sm:py-4 bg-gradient-to-r from-indigo-500/10 to-purple-500/10 rounded-xl border border-white/10 font-medium text-white hover:border-white/20 transition-colors duration-500">
                    <span>Book a Call</span>
                    <div className="w-8 h-8 rounded-full bg-white/5 flex items-center justify-center group-hover:bg-white/10 transition-colors duration-500">
                      <ArrowRight className="w-4 h-4" />
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* How It Works Section */}
        <section id="how-it-works" className="relative py-24 sm:py-32">
          <div className="absolute inset-0 bg-gradient-to-b from-black/80 via-indigo-900/10 to-black/80" />
          <div className="relative container mx-auto px-4 sm:px-6">
            <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold text-center mb-12 sm:mb-16">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
                How It Works
              </span>
            </h2>
            
            <div className="relative">
              {/* Timeline Line */}
              <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-px bg-gradient-to-b from-indigo-500/50 via-purple-500/50 to-transparent" />
              
              {[
                {
                  icon: <Lightbulb className="w-8 h-8" />,
                  title: "Ideation",
                  description: "Shape your brand's story into engaging YouTube content",
                  details: [
                    { text: "Topic ideation & research", icon: <FileText className="w-4 h-4" /> },
                    { text: "Content strategy", icon: <Trophy className="w-4 h-4" /> },
                    { text: "Custom thumbnails", icon: <Palette className="w-4 h-4" /> }
                  ]
                },
                {
                  icon: <FileText className="w-8 h-8" />,
                  title: "Research and Scriptwriting",
                  description: "Craft compelling narratives that educate and engage",
                  details: [
                    { text: "In-depth research", icon: <Infinity className="w-4 h-4" /> },
                    { text: "Professional scriptwriting", icon: <FileText className="w-4 h-4" /> },
                    { text: "Fact-checking & source validation", icon: <Pen className="w-4 h-4" /> }
                  ]
                },
                {
                  icon: <Film className="w-8 h-8" />,
                  title: "Audiovisual Production",
                  description: "Create professional animated, documentary and personal brand content",
                  details: [
                    { text: "Visual storyboarding", icon: <Camera className="w-4 h-4" /> },
                    { text: "Motion design", icon: <Video className="w-4 h-4" /> },
                    { text: "Video/audio editing", icon: <Play className="w-4 h-4" /> }
                  ]
                },
                {
                  icon: <Package className="w-8 h-8" />,
                  title: "Packaging and Delivery",
                  description: "Optimize your content for YouTube success",
                  details: [
                    { text: "Final Delivery", icon: <Rocket className="w-4 h-4" /> },
                    { text: "Quality Assurance", icon: <CheckCircle2 className="w-4 h-4" /> },
                    { text: "Revisions", icon: <Zap className="w-4 h-4" /> }
                  ]
                }
              ].map((step, index) => (
                <div key={index} className={`relative flex flex-col sm:flex-row items-center gap-8 mb-12 sm:mb-16 ${
                  index % 2 === 0 ? 'sm:flex-row' : 'sm:flex-row-reverse'
                }`}>
                  {/* Timeline Node */}
                  <div className="absolute left-1/2 transform -translate-x-1/2 w-12 h-12 sm:w-14 sm:h-14 rounded-full bg-gradient-to-r from-indigo-500 to-purple-500 flex items-center justify-center shadow-xl">
                    <div className="w-8 h-8 sm:w-10 sm:h-10 rounded-full bg-black/50 backdrop-blur-xl flex items-center justify-center text-white font-bold">
                      {index + 1}
                    </div>
                  </div>
                  
                  {/* Content Card */}
                  <div className={`w-full sm:w-1/2 group ${
                    index % 2 === 0 ? 'text-right sm:pr-16' : 'text-left sm:pl-16'
                  }`}>
                    <div className="relative transition-transform transform group-hover:scale-105 duration-500">
                      <div className="absolute -inset-4 rounded-3xl bg-gradient-to-b from-indigo-500/50 via-indigo-500/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500 blur-xl" />
                      <div className="relative h-full bg-gradient-to-br from-black/50 to-black/70 backdrop-blur-xl rounded-3xl p-6 sm:p-8 md:p-10 border border-white/10 shadow-lg">
                        <div className="relative flex-shrink-0 mb-4 sm:mb-6 md:mb-10">
                          <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-xl blur-xl opacity-0 group-hover:opacity-20 transition-opacity duration-500" />
                          <div className={`relative flex items-center ${
                            index % 2 === 0 ? 'justify-end' : 'justify-start'
                          }`}>
                            <div className="flex items-center justify-center w-14 h-14 sm:w-16 sm:h-16 md:w-20 md:h-20 rounded-xl bg-gradient-to-br from-indigo-500/10 to-purple-500/10 border border-white/10 shadow-xl">
                              <div className="text-indigo-400 group-hover:text-indigo-300 transition-colors duration-500">
                                {step.icon}
                              </div>
                            </div>
                          </div>
                        </div>
                        <h3 className="text-xl sm:text-2xl md:text-3xl font-bold text-white mb-2 sm:mb-4 md:mb-6">{step.title}</h3>
                        <p className="text-gray-300 mb-4 sm:mb-6 md:mb-8 leading-relaxed">{step.description}</p>
                        <ul className={`space-y-1 sm:space-y-2 md:space-y-3 ${
                          index % 2 === 0 ? 'text-right' : 'text-left'
                        }`}>
                          {step.details.map((detail, i) => (
                            <li key={i} className="text-gray-400 flex items-center gap-2">
                              <div className={`w-5 h-5 rounded-full bg-indigo-500/10 flex items-center justify-center flex-shrink-0 ${
                                index % 2 === 0 ? 'order-last' : 'order-first'
                              }`}>
                                <div className="text-indigo-400">
                                  {detail.icon}
                                </div>
                              </div>
                              <div className={`flex-1 ${
                                index % 2 === 0 ? 'text-right' : 'text-left'
                              }`}>
                                {detail.text}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  
                  {/* Spacer for opposite side */}
                  <div className="w-full sm:w-1/2" />
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Booking Section */}
        <section id="book" className="relative py-24 sm:py-32">
          <div className="absolute inset-0 bg-gradient-to-b from-black/80 via-indigo-900/10 to-black" />
          <div className="container mx-auto px-4 sm:px-6">
            <div className="relative max-w-4xl mx-auto">
              <div className="absolute -inset-px rounded-3xl bg-gradient-to-b from-indigo-500/50 via-indigo-500/5 to-transparent" />
              <div className="relative bg-black/40 backdrop-blur-xl rounded-3xl border border-white/10">
                <div className="max-w-3xl mx-auto text-center p-6 sm:p-8 pb-4">
                  <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4">
                    <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
                      Schedule a{' '}
                    </span>
                    <span className="relative inline-block">
                      <span className="absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 blur-2xl opacity-20" />
                      <span className="relative bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
                        Discovery Call
                      </span>
                    </span>
                  </h2>
                  <p className="text-gray-300 text-lg sm:text-xl max-w-2xl mx-auto mb-4">
                  Book a call to discuss how our team can help you streamline production, enhance content quality, and scale efficiently.
                  </p>
                </div>
                
                <div className="relative px-2 sm:px-4 pb-4">
                  <div className="absolute inset-0 bg-gradient-to-b from-black/30 to-black/10 pointer-events-none" />
                  <div className="relative mx-auto pt-6" style={{ maxWidth: "860px" }}>
                    <div id="cal-booking-place" className="mx-auto" style={{ 
                      minHeight: "600px",
                      width: "100%",
                      borderRadius: "0.75rem",
                      overflow: "hidden"
                    }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}