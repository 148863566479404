import FireCrawlApp from '@mendable/firecrawl-js';

interface SocialBladeResponse {
  monthly_views?: number;
  error?: string;
}

const app = new FireCrawlApp({ apiKey: import.meta.env.VITE_FIRECRAWL_API_KEY });

export async function getMonthlyViews(username: string): Promise<SocialBladeResponse> {
  try {
    // Try different URL formats
    const urlFormats = [
      `https://socialblade.com/youtube/c/${username.toLowerCase()}`,
      `https://socialblade.com/youtube/@${username.toLowerCase()}`,
      `https://socialblade.com/youtube/user/${username.toLowerCase()}`
    ];

    let lastError = '';
    for (const url of urlFormats) {
      console.log('Trying SocialBlade URL format:', url);
      
      try {
        const extractResult = await app.extract([url], {
          prompt: "Extract the monthly views statistic from the specified YouTube user page.",
          schema: {
            type: "object",
            properties: {
              monthly_views: {
                type: "number",
                description: "The average monthly views number from the statistics section"
              }
            },
            required: ["monthly_views"]
          }
        });

        console.log('Extract result:', extractResult);

        // If we have a successful result with monthly views, return it immediately
        if (extractResult.success && typeof extractResult.data?.monthly_views === 'number') {
          console.log('Successfully extracted monthly views:', extractResult.data.monthly_views);
          return {
            monthly_views: extractResult.data.monthly_views
          };
        }

        // If we get here, the extraction didn't find monthly views
        lastError = 'No monthly views found in response';
        console.log(`No monthly views found for URL ${url}`);
      } catch (urlError) {
        lastError = urlError instanceof Error ? urlError.message : 'Unknown error';
        console.log(`Failed with URL ${url}:`, lastError);
      }
    }

    // Only reach here if no URL succeeded
    console.error('All URL formats failed. Last error:', lastError);
    return {
      error: 'Failed to fetch monthly views from any URL format'
    };
  } catch (error) {
    console.error('Error in getMonthlyViews:', error);
    return {
      error: error instanceof Error ? error.message : 'Failed to fetch monthly views data'
    };
  }
} 