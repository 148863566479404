import { useRef, useState, useMemo, Suspense } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, Sphere, useTexture } from '@react-three/drei';
import * as THREE from 'three';
import { a as animated, useSpring } from '@react-spring/three';

const OFFICE_LOCATIONS = [
  {
    city: "Atlanta",
    country: "United States",
    coordinates: [-84.3880, 33.7490],
    role: "Global Headquarters",
    color: "#ef4444" // Bright Red
  },
  {
    city: "Tokyo",
    country: "Japan",
    coordinates: [139.6917, 35.6895],
    role: "APAC Office",
    color: "#22c55e" // Vibrant Green
  },
  {
    city: "London",
    country: "United Kingdom",
    coordinates: [-0.1276, 51.5074],
    role: "European Office",
    color: "#06b6d4" // Bright Blue
  }
];

function GlobeObject() {
  const globeRef = useRef<THREE.Group>(null);
  const markersRef = useRef<THREE.Group>(null);
  const [hovered, setHovered] = useState<number | null>(null);
  const [activeLocation, setActiveLocation] = useState<number | null>(null);

  // Load all textures
  const textures = useTexture({
    map: '/textures/earth-dark.png',
    bumpMap: '/textures/earth-bump.png',
    normalMap: '/textures/earth-normal.png',
    roughnessMap: '/textures/earth-spec.png',
  });

  // Configure texture settings
  Object.values(textures).forEach(texture => {
    texture.minFilter = THREE.LinearFilter;
  });

  // Add pulse animation
  const { scale } = useSpring({
    from: { scale: 1 },
    to: { scale: 1.5 },
    loop: true,
    config: { duration: 1500 }
  });

  useFrame((state) => {
    if (globeRef.current) {
      globeRef.current.rotation.y += 0.001;
    }
  });

  // Convert lat/long to 3D coordinates
  const latLongToVector3 = (lat: number, long: number, radius: number) => {
    const phi = (90 - lat) * (Math.PI / 180);
    const theta = (long + 180) * (Math.PI / 180);
    const x = -(radius * Math.sin(phi) * Math.cos(theta));
    const z = radius * Math.sin(phi) * Math.sin(theta);
    const y = radius * Math.cos(phi);
    return new THREE.Vector3(x, y, z);
  };

  return (
    <>
      <ambientLight intensity={0.4} />
      <pointLight position={[100, 100, 100]} intensity={1.0} />
      <pointLight position={[-100, -100, -100]} intensity={0.5} />

      {/* Globe Group */}
      <group ref={globeRef}>
        {/* Main Globe */}
        <Sphere args={[1, 128, 128]}>
          <meshStandardMaterial
            map={textures.map}
            bumpMap={textures.bumpMap}
            bumpScale={0.005}
            normalMap={textures.normalMap}
            normalScale={new THREE.Vector2(0.1, 0.1)}
            roughnessMap={textures.roughnessMap}
            roughness={0.7}
            metalness={0.3}
          />
        </Sphere>

        {/* Atmosphere */}
        <Sphere args={[1.02, 128, 128]}>
          <meshBasicMaterial
            color="#4338ca"
            transparent
            opacity={0.1}
            side={THREE.BackSide}
          />
        </Sphere>

        {/* Location Markers */}
        <group ref={markersRef}>
          {OFFICE_LOCATIONS.map((location, index) => {
            const position = latLongToVector3(
              location.coordinates[1],
              location.coordinates[0],
              1.02
            );

            return (
              <group key={index} position={position}>
                <mesh
                  onPointerOver={() => setHovered(index)}
                  onPointerOut={() => setHovered(null)}
                  onClick={() => setActiveLocation(index === activeLocation ? null : index)}
                >
                  <sphereGeometry args={[0.015, 16, 16]} />
                  <meshStandardMaterial
                    color={hovered === index ? location.color : location.color}
                    emissive={location.color}
                    emissiveIntensity={hovered === index ? 1 : 0.5}
                  />
                </mesh>

                {/* Pulse Effect */}
                <animated.mesh scale={scale}>
                  <sphereGeometry args={[0.015, 16, 16]} />
                  <meshBasicMaterial
                    color={location.color}
                    transparent
                    opacity={0.3}
                  />
                </animated.mesh>

                {/* Connection Line */}
                <mesh>
                  <cylinderGeometry args={[0.001, 0.001, 0.2]} />
                  <meshBasicMaterial
                    color={location.color}
                    transparent
                    opacity={0.3}
                  />
                </mesh>
              </group>
            );
          })}
        </group>
      </group>

      <OrbitControls
        enableZoom={false}
        enablePan={false}
        minPolarAngle={Math.PI / 3}
        maxPolarAngle={Math.PI / 1.5}
        rotateSpeed={0.5}
        autoRotate
        autoRotateSpeed={0.5}
      />
    </>
  );
}

function LoadingSpinner() {
  return (
    <div className="absolute inset-0 flex items-center justify-center">
      <div className="w-8 h-8 border-4 border-indigo-500 rounded-full border-t-transparent animate-spin" />
    </div>
  );
}

export default function Globe() {
  return (
    <div className="relative w-full h-full">
      <Canvas
        camera={{ position: [0, 0, 3], fov: 45 }}
        style={{ background: 'transparent' }}
        gl={{ antialias: true }}
      >
        <Suspense fallback={null}>
          <GlobeObject />
        </Suspense>
      </Canvas>

      {/* Location Info Overlay */}
      <div className="absolute top-4 left-4 space-y-2">
        {OFFICE_LOCATIONS.map((location, index) => (
          <div
            key={index}
            className="group bg-black/40 backdrop-blur-xl p-3 rounded-xl border border-white/10 transition-all duration-300 hover:bg-black/60 hover:border-white/20 flex items-center gap-3"
          >
            <div 
              className="w-1.5 h-8 rounded-full transition-all duration-300 group-hover:h-12"
              style={{ backgroundColor: location.color }}
            />
            <div>
              <div className="font-medium text-white/90">{location.city}</div>
              <div className="text-sm text-white/50">{location.role}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
} 