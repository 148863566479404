import { ProjectDashboard, ProjectTimeline, AnalyticsDashboard } from '../components/StudioAppMockups';

export default function MockupPage() {
  return (
    <div className="min-h-screen bg-black p-8">
      <div className="space-y-8">
        <ProjectDashboard />
        <ProjectTimeline />
        <AnalyticsDashboard />
      </div>
    </div>
  );
} 