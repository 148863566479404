"use client";

import { Mail, MapPin, Radio, Waves, ArrowRight, CheckCircle2 } from 'lucide-react';
import { useEffect, useState, useRef } from 'react';
import { useForm, ValidationError } from '@formspree/react';

const AnalysisConsole = ({ logs, isComplete }: { logs: Array<{ type: 'info' | 'success' | 'warning' | 'error' | 'data', message: string }>, isComplete: boolean }) => {
  const consoleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (consoleRef.current) {
      consoleRef.current.scrollTop = consoleRef.current.scrollHeight;
    }
  }, [logs]);

  return (
    <div className="relative">
      {/* Terminal Window Header */}
      <div className="bg-gray-900 rounded-t-lg border-b border-white/10 p-2 flex items-center gap-2">
        <div className="w-3 h-3 rounded-full bg-red-500/50" />
        <div className="w-3 h-3 rounded-full bg-yellow-500/50" />
        <div className="w-3 h-3 rounded-full bg-green-500/50" />
        <span className="text-xs text-gray-400 ml-2">system-console</span>
      </div>
      
      {/* Terminal Content */}
      <div 
        ref={consoleRef}
        className="font-mono text-sm bg-black/90 rounded-b-lg p-4 h-[240px] overflow-y-auto border-x border-b border-white/10"
      >
        {!isComplete ? (
          logs.map((log, index) => (
            <div
              key={index}
              className={`flex items-start gap-2 mb-2 ${
                log.type === 'success' ? 'text-green-400' :
                log.type === 'warning' ? 'text-yellow-400' :
                log.type === 'error' ? 'text-red-400' :
                log.type === 'data' ? 'text-blue-400' :
                'text-gray-400'
              }`}
            >
              <span className="font-bold">&gt;</span>
              <span>{log.message}</span>
            </div>
          ))
        ) : (
          <div className="space-y-4">
            <div className="flex items-center gap-2 text-green-400">
              <span className="font-bold">&gt;</span>
              <div className="flex items-center gap-2">
                <CheckCircle2 className="w-4 h-4" />
                <span className="font-medium">Connection Established</span>
              </div>
            </div>
            
            <div className="flex items-start gap-2">
              <span className="font-bold text-gray-400">&gt;</span>
              <div className="space-y-1">
                <div className="text-gray-400">Transmission Frequency:</div>
                <a href="mailto:hello@galacticmedia.tv" className="text-white hover:text-indigo-400 transition-colors">
                  hello@galacticmedia.tv
                </a>
              </div>
            </div>
            
            <div className="flex items-start gap-2">
              <span className="font-bold text-gray-400">&gt;</span>
              <div className="space-y-1">
                <div className="text-gray-400">Command Center:</div>
                <div className="text-white">Earth Base, Quadrant 3, Milky Way</div>
                <div className="text-sm text-gray-400">33.7490° N, 84.3880° W</div>
                <div className="text-xs text-gray-500">Headquarters located in Atlanta, GA</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default function ContactPage() {
  const [locationText, setLocationText] = useState('');
  const [showCoordinates, setShowCoordinates] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(true);
  const [logs, setLogs] = useState<Array<{ type: 'info' | 'success' | 'warning' | 'error' | 'data', message: string }>>([]);
  const [state, handleFormspreeSubmit] = useForm("xanqzvpv");

  const addLog = (type: 'info' | 'success' | 'warning' | 'error' | 'data', message: string) => {
    setLogs(prev => [...prev, { type, message }]);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    // Add submission attempt log
    addLog('info', 'Initiating transmission...');
    
    try {
      await handleFormspreeSubmit(e);
      
      if (state.errors) {
        addLog('error', 'Transmission failed: Please check your input and try again.');
        return;
      }
      
      if (state.succeeded) {
        addLog('success', 'Transmission sent successfully!');
        (e.target as HTMLFormElement).reset();
        
        // Reset form state after delay
        setTimeout(() => {
          setLogs([]);
          setIsComplete(false);
          
          // Restart the console animation sequence
          addLog('info', 'Establishing secure connection...');
          setTimeout(() => {
            addLog('success', 'Connection established');
            addLog('info', 'Initializing location protocols...');
          }, 1000);
          
          setTimeout(() => {
            addLog('info', 'Scanning quantum networks...');
          }, 2000);
          
          setTimeout(() => {
            setIsComplete(true);
          }, 3000);
        }, 3000);
      }
    } catch (error) {
      addLog('error', 'Transmission failed. Please try again or contact us directly at hello@galacticmedia.tv');
    }
  };

  useEffect(() => {
    // Form loading effect
    setTimeout(() => {
      setIsFormLoading(false);
    }, 2000);

    const finalText = 'Earth Base, Quadrant 3, Milky Way';
    const coordinates = '33.7490° N, 84.3880° W';
    let index = 0;

    // Clear previous logs
    setLogs([]);
    setIsComplete(false);

    // Initial connection message
    addLog('info', 'Establishing secure connection...');
    
    setTimeout(() => {
      addLog('success', 'Connection established');
      addLog('info', 'Initializing location protocols...');
    }, 1000);

    setTimeout(() => {
      addLog('info', 'Scanning quantum networks...');
    }, 2000);

    setTimeout(() => {
      addLog('success', 'Location identified');
      addLog('data', `Command Center: ${finalText}`);
    }, 3000);

    setTimeout(() => {
      addLog('info', 'Calculating precise coordinates...');
    }, 4000);

    setTimeout(() => {
      addLog('data', `Coordinates: ${coordinates}`);
      addLog('success', 'Location mapping complete');
      setShowCoordinates(true);
    }, 5000);

    setTimeout(() => {
      setIsComplete(true);
    }, 6000);

    return () => {
      setLogs([]);
      setIsComplete(false);
      setIsFormLoading(true);
    };
  }, []);

  return (
    <div className="min-h-screen flex flex-col justify-between bg-black text-white">
      <section className="relative overflow-hidden flex-grow">
        {/* Background Effects */}
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-b from-indigo-950/40 via-gray-950/90 to-gray-950" />
          <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top,rgba(67,56,202,0.2),transparent_50%)]" />
        </div>

        {/* Animated Stars */}
        {/* <div className="absolute inset-0">
          {[...Array(100)].map((_, i) => (
            <div
              key={i}
              className="star"
              style={{
                width: Math.random() * 2 + 1 + 'px',
                height: Math.random() * 2 + 1 + 'px',
                left: Math.random() * 100 + '%',
                top: Math.random() * 100 + '%',
                animationDelay: Math.random() * 3 + 's'
              }}
            />
          ))}
        </div> */}

        {/* Radio Wave Animation */}
        <div className="absolute inset-0 overflow-hidden">
          {[...Array(3)].map((_, i) => (
            <div
              key={i}
              className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 border border-indigo-500/10 rounded-full"
              style={{
                width: `${(i + 1) * 200}px`,
                height: `${(i + 1) * 200}px`,
                animation: `radioPulse ${2 + i}s infinite ease-out ${i * 0.5}s`
              }}
            />
          ))}
        </div>

        <div className="relative z-10 container mx-auto px-6 py-24">
          <div className="text-center mb-16">
            <div className="flex items-center justify-center gap-4 mb-8">

            </div>
            <h1 className="text-4xl md:text-5xl font-mono text-white mb-4">
              Send a Transmission
            </h1>
            <p className="text-lg font-mono text-gray-400">
              Have a question or want to work together? Send us a message.
            </p>
          </div>

          <div className="grid lg:grid-cols-3 gap-12 max-w-7xl mx-auto">
            <div className="lg:col-span-1">
              <AnalysisConsole logs={logs} isComplete={isComplete} />
            </div>

            <div className="lg:col-span-2">
              <div className="relative">
                {/* Terminal Window Header */}
                <div className="bg-gray-900 rounded-t-lg border-b border-white/10 p-2 flex items-center gap-2">
                  <div className="w-3 h-3 rounded-full bg-red-500/50" />
                  <div className="w-3 h-3 rounded-full bg-yellow-500/50" />
                  <div className="w-3 h-3 rounded-full bg-green-500/50" />
                  <span className="text-xs text-gray-400 ml-2">message-transmission</span>
                </div>

                {/* Terminal Content */}
                <div className="font-mono text-sm bg-black/90 rounded-b-lg p-6 border-x border-b border-white/10 min-h-[400px]">
                  {isFormLoading ? (
                    <div className="flex flex-col items-center justify-center h-full">
                      <div className="w-48 h-0.5 bg-black/50 rounded-full overflow-hidden">
                        <div className="w-full h-full bg-green-500 animate-[loading_2s_ease-in-out]" />
                      </div>
                      <p className="text-green-400 mt-4">Initializing transmission interface...</p>
                    </div>
                  ) : (
                    <form 
                      onSubmit={handleSubmit}
                      className="space-y-6 animate-fadeIn"
                    >
                      <div className="space-y-2">
                        <div className="flex items-center gap-2 text-green-400">
                          <span className="font-bold">&gt;</span>
                          <label htmlFor="name" className="font-medium">
                            Enter your identification:
                          </label>
                        </div>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          required
                          disabled={state.submitting}
                          className="w-full bg-transparent border-none outline-none text-white font-mono placeholder-gray-600 focus:ring-0 disabled:opacity-50"
                          placeholder="Your name"
                        />
                        <ValidationError 
                          prefix="Name" 
                          field="name" 
                          errors={state.errors} 
                          className="text-red-400 mt-1 font-mono flex items-center gap-2 before:content-['>'] before:font-bold" 
                        />
                      </div>
                      
                      <div className="space-y-2">
                        <div className="flex items-center gap-2 text-green-400">
                          <span className="font-bold">&gt;</span>
                          <label htmlFor="email" className="font-medium">
                            Specify transmission frequency (email):
                          </label>
                        </div>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          required
                          disabled={state.submitting}
                          className="w-full bg-transparent border-none outline-none text-white font-mono placeholder-gray-600 focus:ring-0 disabled:opacity-50"
                          placeholder="your@email.com"
                        />
                        <ValidationError 
                          prefix="Email" 
                          field="email" 
                          errors={state.errors} 
                          className="text-red-400 mt-1 font-mono flex items-center gap-2 before:content-['>'] before:font-bold" 
                        />
                      </div>
                      
                      <div className="space-y-2">
                        <div className="flex items-center gap-2 text-green-400">
                          <span className="font-bold">&gt;</span>
                          <label htmlFor="message" className="font-medium">
                            Compose transmission message:
                          </label>
                        </div>
                        <textarea
                          id="message"
                          name="message"
                          required
                          disabled={state.submitting}
                          rows={4}
                          className="w-full bg-transparent border-none outline-none text-white font-mono placeholder-gray-600 focus:ring-0 resize-none disabled:opacity-50"
                          placeholder="How can we help?"
                        />
                        <ValidationError 
                          prefix="Message" 
                          field="message" 
                          errors={state.errors} 
                          className="text-red-400 mt-1 font-mono flex items-center gap-2 before:content-['>'] before:font-bold" 
                        />
                      </div>

                      <div className="pt-4">
                        <button
                          type="submit"
                          disabled={state.submitting}
                          className="w-full bg-green-500/10 border border-green-500/20 hover:bg-green-500/20 px-8 py-4 rounded-lg text-green-400 font-mono transition-all flex items-center justify-center group disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                          <span className="font-bold mr-2">&gt;</span>
                          {state.submitting ? (
                            <>
                              <div className="w-4 h-4 border-2 border-green-400/30 border-t-green-400 rounded-full animate-spin mr-2" />
                              TRANSMITTING...
                            </>
                          ) : (
                            <>
                              INITIATE TRANSMISSION
                              <ArrowRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
                            </>
                          )}
                        </button>
                      </div>

                      {state.errors && (
                        <div className="flex items-start gap-2 text-red-400 mt-4 font-mono">
                          <span className="font-bold">&gt;</span>
                          <div className="flex flex-col gap-1">
                            <ValidationError errors={state.errors} className="text-red-400" />
                          </div>
                        </div>
                      )}

                      {state.succeeded && (
                        <div className="flex items-center gap-2 text-green-400 mt-4">
                          <span className="font-bold">&gt;</span>
                          <div className="flex items-center gap-2">
                            <CheckCircle2 className="w-4 h-4" />
                            <span>Transmission sent successfully!</span>
                          </div>
                        </div>
                      )}
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}