import { 
  ArrowRight, 
  Youtube, 
  BarChart2, 
  Calendar, 
  MessageSquare, 
  Zap,
  CheckCircle2,
  PlayCircle,
  Users,
  TrendingUp,
  Mail,
  Briefcase,
  FileText,
  DollarSign,
  Shield,
  Brain,
  HandshakeIcon,
  Heart,
  ChevronRight,
  Layers,
  Bot,
  UserCog,
  Building2,
  Scale,
  UsersRound
} from 'lucide-react';
import { useState } from 'react';

export default function TechnologyPage() {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    // Validate email format
    if (!validateEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }

    setIsLoading(true);
    
    try {
      await fetch('https://script.google.com/macros/s/AKfycbwCH_6IaCV-ao5W7BrNnn_nuetVyeMUpsrDlveDh4bxS_vrwcBGNOc2HV3VwJcO6ROm/exec', {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
        },
        body: JSON.stringify({ 
          email
        }),
        mode: 'no-cors'
      });

      setIsSubmitted(true);
      setEmail('');
    } catch (error: any) {
      console.error('Error submitting form:', error);
      setError('Failed to submit. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const featureCategories = [
    {
      icon: <Building2 className="w-8 h-8" />,
      title: "Channel & Business Management",
      description: "Comprehensive tools to manage your YouTube business like a CEO",
      features: [
        "Multi-Channel Dashboard with key metrics",
        "Revenue & expense tracking",
        "Automated P&L reports",
        "Channel valuation tools"
      ]
    },
    {
      icon: <Layers className="w-8 h-8" />,
      title: "Content Production Workflow",
      description: "Streamline your content creation process from idea to publish",
      features: [
        "Kanban-style video pipeline",
        "AI-powered content suggestions",
        "Team collaboration tools",
        "Asset management"
      ]
    },
    {
      icon: <Brain className="w-8 h-8" />,
      title: "AI & Automation Features",
      description: "Let AI handle the heavy lifting",
      features: [
        "AI script generation",
        "Thumbnail CTR prediction",
        "Automated reporting",
        "Smart comment moderation"
      ]
    },
    {
      icon: <HandshakeIcon className="w-8 h-8" />,
      title: "Sponsorship & Growth",
      description: "Maximize revenue and grow your audience",
      features: [
        "Brand deal CRM",
        "Analytics & insights",
        "Community management",
        "A/B testing tools"
      ]
    }
  ];

  return (
    <div className="relative min-h-screen">
      {/* Background with same aesthetic */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-black">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_60%_-20%,#4338ca,transparent_50%)]" />
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_150%,#6366f1,transparent_50%)]" />
        </div>
        <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-10" />
      </div>

      {/* Hero Section */}
      <section className="relative pt-20 sm:pt-32 pb-16 sm:pb-20">
        <div className="container mx-auto px-4 sm:px-6">
          <div className="flex justify-center mb-6 sm:mb-8">
            <div className="relative">
              <div className="absolute -inset-1 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-full blur opacity-30 animate-pulse" />
              <div className="relative px-4 sm:px-6 py-2 bg-black/50 backdrop-blur-xl rounded-full border border-white/10 flex items-center gap-2">
                <Youtube className="w-4 h-4 text-indigo-400" />
                <span className="text-xs sm:text-sm font-medium bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
                  The Ultimate YouTube Creator Suite
                </span>
              </div>
            </div>
          </div>

          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-4xl sm:text-5xl md:text-7xl font-bold mb-6 sm:mb-8">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
                The All-In-One
              </span>
              <br />
              <span className="relative inline-block mt-2">
                <span className="absolute -inset-2 bg-gradient-to-r from-indigo-500 to-purple-500 blur-2xl opacity-20" />
                <span className="relative bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
                  Creator Productivity Suite
                </span>
              </span>
            </h1>

            <p className="text-lg sm:text-xl text-gray-400 mb-8 sm:mb-12 px-4 sm:px-0">
              One powerful platform to manage content, analyze performance, and grow your YouTube presence. Built for creators who are serious about success.
            </p>

            <form onSubmit={handleSubmit} className="max-w-md mx-auto px-4 sm:px-0">
              {!isSubmitted ? (
                <>
                  <div className="relative">
                    <div className="absolute -inset-px bg-gradient-to-r from-indigo-500 to-purple-500 rounded-xl opacity-50" />
                    <div className="relative flex flex-col sm:flex-row">
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setError(null);
                        }}
                        placeholder="Enter your email"
                        className={`w-full px-4 sm:px-6 py-3 sm:py-4 bg-black/50 backdrop-blur-xl rounded-xl sm:rounded-l-xl sm:rounded-r-none border ${
                          error ? 'border-red-500 ring-1 ring-red-500' : 'border-white/10'
                        } text-white placeholder-gray-400 focus:outline-none focus:ring-2 ${
                          error ? 'focus:ring-red-500' : 'focus:ring-indigo-500'
                        } text-sm sm:text-base`}
                        required
                        disabled={isLoading}
                      />
                      <button
                        type="submit"
                        disabled={isLoading}
                        className={`w-full sm:w-[200px] mt-2 sm:mt-0 px-4 sm:px-6 py-3 sm:py-4 bg-gradient-to-r from-indigo-600 to-purple-600 text-white font-semibold rounded-xl sm:rounded-l-none sm:rounded-r-xl hover:from-indigo-500 hover:to-purple-500 transition-all duration-200 flex items-center justify-center whitespace-nowrap ${isLoading ? 'opacity-75 cursor-not-allowed' : ''} text-sm sm:text-base`}
                      >
                        {isLoading ? (
                          <>
                            <div className="w-4 sm:w-5 h-4 sm:h-5 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                            <span>Joining...</span>
                          </>
                        ) : (
                          <>
                            <span>Join Waitlist</span>
                            <ArrowRight className="w-4 sm:w-5 h-4 sm:h-5" />
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                  {error && (
                    <div className="mt-2 text-red-400 text-sm flex items-center gap-2">
                      <div className="w-4 h-4 rounded-full bg-red-500/10 flex items-center justify-center">
                        <span className="text-red-400">!</span>
                      </div>
                      {error}
                    </div>
                  )}
                </>
              ) : (
                <div className="relative">
                  <div className="absolute -inset-px bg-gradient-to-r from-indigo-500 to-purple-500 rounded-xl opacity-50" />
                  <div className="relative bg-black/50 backdrop-blur-xl rounded-xl p-6 border border-white/10">
                    <div className="flex items-center justify-center mb-4">
                      <div className="w-12 h-12 rounded-full bg-indigo-500/10 flex items-center justify-center">
                        <CheckCircle2 className="w-6 h-6 text-indigo-400" />
                      </div>
                    </div>
                    <div className="text-center">
                      <p className="text-xl font-medium text-white mb-2">
                        You're on the list!
                      </p>
                      <p className="text-gray-400">
                        Thanks for joining! We'll notify you when we launch.
                      </p>
                      <p className="text-gray-400 text-sm mt-2">
                        Keep an eye on your inbox for updates about Galactic Media.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </section>

      {/* First Screenshot Section - Dashboard Overview */}
      <section className="relative py-16 sm:py-20 overflow-hidden">
        <div className="container mx-auto px-4 sm:px-6">
          <div className="max-w-3xl mx-auto text-center mb-12 sm:mb-16">
            <h2 className="text-3xl sm:text-4xl font-bold mb-4 sm:mb-6">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
                Beautiful & Intuitive Interface
              </span>
            </h2>
            <p className="text-lg sm:text-xl text-gray-400 px-4 sm:px-0">
              A powerful dashboard that puts everything you need at your fingertips.
            </p>
          </div>

          <div className="relative">
            <div className="absolute -inset-4 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-3xl blur-2xl opacity-20" />
            <div className="relative rounded-3xl overflow-hidden border border-white/10">
              <div className="relative bg-black/40 backdrop-blur-xl p-2">
                <div className="flex items-center gap-2 p-3 border-b border-white/10">
                  <div className="flex gap-2">
                    <div className="w-3 h-3 rounded-full bg-red-500/20" />
                    <div className="w-3 h-3 rounded-full bg-yellow-500/20" />
                    <div className="w-3 h-3 rounded-full bg-green-500/20" />
                  </div>
                </div>
                <img 
                  src="/images/quibly01.png" 
                  alt="Quibly Dashboard" 
                  className="w-full rounded-b-2xl shadow-2xl"
                />
              </div>
            </div>
            <div className="absolute -right-20 -bottom-20 w-72 h-72 bg-indigo-500 rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob" />
            <div className="absolute -left-20 -bottom-20 w-72 h-72 bg-purple-500 rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob animation-delay-2000" />
          </div>
        </div>
      </section>

      {/* Feature Categories Overview */}
      <section className="relative py-16 sm:py-20">
        <div className="container mx-auto px-4 sm:px-6">
          <div className="max-w-3xl mx-auto text-center mb-16 sm:mb-24">
            <h2 className="text-3xl sm:text-4xl font-bold mb-4 sm:mb-6">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
                Everything You Need to Scale
              </span>
            </h2>
            <p className="text-lg sm:text-xl text-gray-400 px-4 sm:px-0">
              A complete suite of tools designed for modern content creators and their teams.
            </p>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 sm:gap-8">
            {featureCategories.map((category, index) => (
              <div key={index} className="group relative">
                <div className="absolute -inset-px rounded-2xl sm:rounded-3xl bg-gradient-to-b from-indigo-500/50 via-indigo-500/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
                <div className="relative h-full bg-black/40 backdrop-blur-xl rounded-2xl sm:rounded-3xl p-6 sm:p-8 border border-white/10">
                  <div className="relative mb-4 sm:mb-6">
                    <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-xl sm:rounded-2xl blur-xl opacity-0 group-hover:opacity-20 transition-opacity duration-500" />
                    <div className="relative flex items-center justify-center w-12 sm:w-16 h-12 sm:h-16 rounded-xl sm:rounded-2xl bg-gradient-to-br from-indigo-500/10 to-purple-500/10 border border-white/10">
                      <div className="text-indigo-400 group-hover:text-indigo-300 transition-colors duration-500">
                        {category.icon}
                      </div>
                    </div>
                  </div>

                  <h3 className="text-lg sm:text-xl font-bold mb-3 sm:mb-4 bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
                    {category.title}
                  </h3>
                  <p className="text-sm sm:text-base text-gray-400 mb-4 sm:mb-6 group-hover:text-gray-300 transition-colors duration-500">
                    {category.description}
                  </p>

                  <ul className="space-y-2 sm:space-y-3">
                    {category.features.map((feature, featureIndex) => (
                      <li key={featureIndex} className="flex items-start gap-2 text-sm sm:text-base text-gray-400 group-hover:text-gray-300 transition-colors duration-500">
                        <ChevronRight className="w-4 sm:w-5 h-4 sm:h-5 flex-shrink-0 text-indigo-400 mt-0.5" />
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Second Screenshot - Content Pipeline */}
      <section className="relative py-16 sm:py-20 overflow-hidden bg-black/20">
        <div className="container mx-auto px-4 sm:px-6">
          <div className="flex flex-col lg:flex-row items-center gap-8 sm:gap-12">
            <div className="lg:w-1/2">
              <h2 className="text-3xl sm:text-4xl font-bold mb-4 sm:mb-6">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
                  Streamlined Content Pipeline
                </span>
              </h2>
              <p className="text-lg sm:text-xl text-gray-400 mb-6 sm:mb-8">
                Manage your entire content workflow from ideation to publication with our intuitive Kanban system.
              </p>
              <ul className="space-y-3 sm:space-y-4">
                <li className="flex items-center gap-3">
                  <CheckCircle2 className="w-6 h-6 text-indigo-400" />
                  <span className="text-gray-300">Drag-and-drop video management</span>
                </li>
                <li className="flex items-center gap-3">
                  <CheckCircle2 className="w-6 h-6 text-indigo-400" />
                  <span className="text-gray-300">Automated status updates</span>
                </li>
                <li className="flex items-center gap-3">
                  <CheckCircle2 className="w-6 h-6 text-indigo-400" />
                  <span className="text-gray-300">Team collaboration tools</span>
                </li>
              </ul>
            </div>
            <div className="lg:w-1/2">
              <div className="relative">
                <div className="absolute -inset-4 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-3xl blur-2xl opacity-20" />
                <div className="relative rounded-3xl overflow-hidden border border-white/10">
                  <div className="relative bg-black/40 backdrop-blur-xl p-2">
                    <div className="flex items-center gap-2 p-3 border-b border-white/10">
                      <div className="flex gap-2">
                        <div className="w-3 h-3 rounded-full bg-red-500/20" />
                        <div className="w-3 h-3 rounded-full bg-yellow-500/20" />
                        <div className="w-3 h-3 rounded-full bg-green-500/20" />
                      </div>
                    </div>
                    <img 
                      src="/images/quibly02.png" 
                      alt="Content Pipeline" 
                      className="w-full rounded-b-2xl shadow-2xl"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Third Screenshot - Analytics */}
      <section className="relative py-16 sm:py-20 overflow-hidden">
        <div className="container mx-auto px-4 sm:px-6">
          <div className="flex flex-col lg:flex-row-reverse items-center gap-8 sm:gap-12">
            <div className="lg:w-1/2">
              <h2 className="text-3xl sm:text-4xl font-bold mb-4 sm:mb-6">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
                  Powerful Analytics
                </span>
              </h2>
              <p className="text-lg sm:text-xl text-gray-400 mb-6 sm:mb-8">
                Make data-driven decisions with comprehensive analytics and insights.
              </p>
              <ul className="space-y-3 sm:space-y-4">
                <li className="flex items-center gap-3">
                  <CheckCircle2 className="w-6 h-6 text-indigo-400" />
                  <span className="text-gray-300">Real-time performance tracking</span>
                </li>
                <li className="flex items-center gap-3">
                  <CheckCircle2 className="w-6 h-6 text-indigo-400" />
                  <span className="text-gray-300">Revenue forecasting</span>
                </li>
                <li className="flex items-center gap-3">
                  <CheckCircle2 className="w-6 h-6 text-indigo-400" />
                  <span className="text-gray-300">Audience insights</span>
                </li>
              </ul>
            </div>
            <div className="lg:w-1/2">
              <div className="relative">
                <div className="absolute -inset-4 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-3xl blur-2xl opacity-20" />
                <div className="relative rounded-3xl overflow-hidden border border-white/10">
                  <div className="relative bg-black/40 backdrop-blur-xl p-2">
                    <div className="flex items-center gap-2 p-3 border-b border-white/10">
                      <div className="flex gap-2">
                        <div className="w-3 h-3 rounded-full bg-red-500/20" />
                        <div className="w-3 h-3 rounded-full bg-yellow-500/20" />
                        <div className="w-3 h-3 rounded-full bg-green-500/20" />
                      </div>
                    </div>
                    <img 
                      src="/images/quibly03.png" 
                      alt="Analytics Dashboard" 
                      className="w-full rounded-b-2xl shadow-2xl"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="relative py-16 sm:py-20">
        <div className="container mx-auto px-4 sm:px-6">
          <div className="max-w-3xl mx-auto text-center mb-12 sm:mb-16">
            <h2 className="text-3xl sm:text-4xl font-bold mb-4 sm:mb-6">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/70">
                Built for Serious Content Creators
              </span>
            </h2>
            <p className="text-lg sm:text-xl text-gray-400 px-4 sm:px-0">
              Transform your YouTube channel from a hobby into a thriving business with our comprehensive suite of tools.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 sm:gap-12">
            {[
              {
                title: "Save 20+ Hours Weekly",
                description: "Automate repetitive tasks and streamline your content workflow."
              },
              {
                title: "Grow 3x Faster",
                description: "Make data-driven decisions with our advanced analytics and insights."
              },
              {
                title: "Boost Engagement",
                description: "Connect with your audience more effectively using AI-powered tools."
              },
              {
                title: "Maximize Revenue",
                description: "Optimize your content strategy for better monetization."
              }
            ].map((benefit, index) => (
              <div key={index} className="flex items-start gap-4">
                <div className="flex-shrink-0">
                  <CheckCircle2 className="w-6 h-6 text-indigo-400" />
                </div>
                <div>
                  <h3 className="text-xl font-bold text-white mb-2">{benefit.title}</h3>
                  <p className="text-gray-400">{benefit.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="relative py-16 sm:py-20">
        <div className="container mx-auto px-4 sm:px-6">
          <div className="relative overflow-hidden rounded-2xl sm:rounded-3xl">
            <div className="absolute inset-0 bg-gradient-to-r from-indigo-600 to-purple-600 opacity-10" />
            <div className="relative bg-black/40 backdrop-blur-xl p-8 sm:p-12 border border-white/10">
              <div className="max-w-3xl mx-auto text-center">
                <h2 className="text-3xl sm:text-4xl font-bold mb-4 sm:mb-6 text-white">
                  Sign up for Early Access
                </h2>

                <form onSubmit={handleSubmit} className="max-w-md mx-auto">
                  {!isSubmitted ? (
                    <>
                      <div className="relative">
                        <div className="absolute -inset-px bg-gradient-to-r from-indigo-500 to-purple-500 rounded-xl opacity-50" />
                        <div className="relative flex flex-col sm:flex-row">
                          <input
                            type="email"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              setError(null);
                            }}
                            placeholder="Enter your email"
                            className={`w-full px-4 sm:px-6 py-3 sm:py-4 bg-black/50 backdrop-blur-xl rounded-xl sm:rounded-l-xl sm:rounded-r-none border ${
                              error ? 'border-red-500 ring-1 ring-red-500' : 'border-white/10'
                            } text-white placeholder-gray-400 focus:outline-none focus:ring-2 ${
                              error ? 'focus:ring-red-500' : 'focus:ring-indigo-500'
                            } text-sm sm:text-base`}
                            required
                            disabled={isLoading}
                          />
                          <button
                            type="submit"
                            disabled={isLoading}
                            className={`w-full sm:w-[200px] mt-2 sm:mt-0 px-4 sm:px-6 py-3 sm:py-4 bg-gradient-to-r from-indigo-600 to-purple-600 text-white font-semibold rounded-xl sm:rounded-l-none sm:rounded-r-xl hover:from-indigo-500 hover:to-purple-500 transition-all duration-200 flex items-center justify-center whitespace-nowrap ${isLoading ? 'opacity-75 cursor-not-allowed' : ''} text-sm sm:text-base`}
                          >
                            {isLoading ? (
                              <>
                                <div className="w-4 sm:w-5 h-4 sm:h-5 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                                <span>Joining...</span>
                              </>
                            ) : (
                              <>
                                <span>Join Waitlist</span>
                                <ArrowRight className="w-4 sm:w-5 h-4 sm:h-5" />
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                      {error && (
                        <div className="mt-2 text-red-400 text-sm flex items-center gap-2">
                          <div className="w-4 h-4 rounded-full bg-red-500/10 flex items-center justify-center">
                            <span className="text-red-400">!</span>
                          </div>
                          {error}
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="relative">
                      <div className="absolute -inset-px bg-gradient-to-r from-indigo-500 to-purple-500 rounded-xl opacity-50" />
                      <div className="relative bg-black/50 backdrop-blur-xl rounded-xl p-6 border border-white/10">
                        <div className="flex items-center justify-center mb-4">
                          <div className="w-12 h-12 rounded-full bg-indigo-500/10 flex items-center justify-center">
                            <CheckCircle2 className="w-6 h-6 text-indigo-400" />
                          </div>
                        </div>
                        <div className="text-center">
                          <p className="text-xl font-medium text-white mb-2">
                            You're on the list!
                          </p>
                          <p className="text-gray-400">
                            Thanks for joining! We'll notify you when we launch.
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
} 